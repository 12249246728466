import { CountryInfo, RegionInfo, SettingInfo, TagValueInfo } from "@pull/pull-groupm-csp-api"
import { createContext, FC, ReactNode, useCallback, useContext, useEffect, useState } from "react"
import { WithChildren } from "../../../../_metronic/helpers"
import { PageLink } from "../../../../_metronic/layout/core"
import { Filter } from "../../../helpers/types/Filter"


type EditModalContextProps = {
    selectedValue: any|null,
    updateSelectedValue: (value:any|null) => void,
    showEditModal: boolean
}


const initModalContextPropsState = {
    selectedValue:null,
    updateSelectedValue:(value:any|null) => {},
    showEditModal:false,
    children:<></>
}

const EditModalContext = createContext<EditModalContextProps>(initModalContextPropsState)

const useEditModalContext = () =>{
    return useContext(EditModalContext)
}

type ProviderProps ={
    children: ReactNode
}

const EditModalProvider:FC<ProviderProps> = ({children}) =>{
    const [selectedValue, setValue] = useState<any>(null)
    const [showEditModal, setShowEditModal] = useState(false)

    const updateSelectedValue = useCallback((value:any) =>{
        setValue(value)
    },[])

    useEffect(()=>{
        setShowEditModal(selectedValue !== null)
    },[selectedValue])

    return ( 
        <EditModalContext.Provider value={{selectedValue,updateSelectedValue,showEditModal}} >
            {children}
        </EditModalContext.Provider>
    )
}
export {EditModalProvider,useEditModalContext}