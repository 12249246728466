/* eslint-disable jsx-a11y/anchor-is-valid */
import { UserInfo } from '@pull/pull-groupm-csp-api'
import clsx from 'clsx'
import { FC } from 'react'


type Props = {
  user: string
}

const AuthorCell: FC<Props> = ({ user }) => {
  return (
    <div className='d-flex align-items-center'>
      <div className='d-flex flex-column'>
        <span className='text-gray-800 text-hover-primary mb-1'>
          {user}
        </span>
      </div>
    </div>
  )
}

export { AuthorCell }
