import { AwardsApi, useAuthContext } from "@pull/pull-groupm-csp-api"
import { useQuery } from "react-query"
import { Link, useNavigate, useParams } from "react-router-dom"
import { KTCard, KTCardBody } from "../../../_metronic/helpers"
import { PageLink, PageTitle } from "../../../_metronic/layout/core"
import { ToolbarContainer } from "../../../_metronic/layout/core/ToolbarContainer"
import { QUERIES } from "../../Constants/Queries"
import PageContainer from "../../helpers/components/listing/PageContainer"
import { EditModalProvider } from "../../modules/core/functional/EditModalContext"
import { LoadingComponent } from "../../modules/core/LoadingComponent"
import { AwardSettingsButton } from "./buttons/AwardSettingsButton"
import { closedColumns } from "./columns/_columns"
import { AwardModalWrapper } from "./modals/AwardModalWrapper"
import { AwardTabTable } from "./tabs/AwardTabTable"
import { DownloadSubmissionsModalProvider } from "./modals/DownloadSubmissionsModalContext"
import { DownloadSubmissionsModal } from "./modals/DownloadSubmissionsModal"
import { AwardSubmissionWinnerModalProvider } from "./modals/AwardSubmissionWinnerModalContext"
import { AwardSubmissionWinnerModal } from "./modals/AwardSubmissionWinnerModal"
import { AwardSubmissionWinnerModalWrapper } from "./modals/AwardSubmissionWinnerModalWrapper"

const AwardDetailPage = () => {
    const { id, awardId } = useParams()
    const navigate = useNavigate()
    if (!Number(id) || !Number(awardId)) {
        navigate("/404")
    }
    const { getApiConfiguration } = useAuthContext()
    const awardsApi = new AwardsApi(getApiConfiguration())

    const { isLoading, data: award } = useQuery([QUERIES.AWARDS.GET + id], () => {
        console.log("Fetching:" + QUERIES.AWARDS.GET + id);
        return awardsApi.apiAwardsAwardsIdGet(Number(awardId))
    }, {})

    if (isLoading)
        return <LoadingComponent />

    const awardBreadcrumbs: Array<PageLink> = [
        {
            title: '',
            path: '',
            isSeparator: true,
            isActive: false,
        },
        {
            title: award?.categoryName ?? "New award category",
            path: award ? `/awards/${id}` : "/",
            isSeparator: false,
            isActive: true,
        },
        {
            title: '',
            path: '',
            isSeparator: true,
            isActive: false,
        },
        {
            title: award?.name ?? "Award Page",
            path: `/awards/${id}/${awardId}`,
            isSeparator: false,
            isActive: false,
        },
    ]

    return (
        <EditModalProvider>
            <DownloadSubmissionsModalProvider>
                <AwardSubmissionWinnerModalProvider>
                    {award &&
                        <>
                            <AwardSubmissionWinnerModalWrapper />
                            <DownloadSubmissionsModal />
                            <AwardModalWrapper />
                            <ToolbarContainer>
                                <PageTitle title={award.name} breadcrumbs={awardBreadcrumbs}>
                                    <div className="mt-6">
                                        <Link to={`/awards/${id}`} className="btn btn-sm btn-dark fw-bold">
                                            <span className="svg-icon svg-icon-2">
                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <rect opacity="0.5" x="6" y="11" width="13" height="2" rx="1" fill="currentColor"></rect>
                                                    <path d="M8.56569 11.4343L12.75 7.25C13.1642 6.83579 13.1642 6.16421 12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75L5.70711 11.2929C5.31658 11.6834 5.31658 12.3166 5.70711 12.7071L11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25C13.1642 17.8358 13.1642 17.1642 12.75 16.75L8.56569 12.5657C8.25327 12.2533 8.25327 11.7467 8.56569 11.4343Z" fill="currentColor"></path>
                                                </svg>
                                            </span>
                                            {award.categoryName}
                                        </Link>
                                    </div>
                                </PageTitle>
                                <div className="d-flex align-items-center py-3 py-md-1">
                                    <AwardSettingsButton award={award} awardCatId={Number(id)} className="btn btn-dark fw-bold">
                                        <span className="svg-icon svg-icon-2">
                                            <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                                <path opacity="0.3" fill-rule="evenodd" clip-rule="evenodd" d="M2 4.63158C2 3.1782 3.1782 2 4.63158 2H13.47C14.0155 2 14.278 2.66919 13.8778 3.04006L12.4556 4.35821C11.9009 4.87228 11.1726 5.15789 10.4163 5.15789H7.1579C6.05333 5.15789 5.15789 6.05333 5.15789 7.1579V16.8421C5.15789 17.9467 6.05333 18.8421 7.1579 18.8421H16.8421C17.9467 18.8421 18.8421 17.9467 18.8421 16.8421V13.7518C18.8421 12.927 19.1817 12.1387 19.7809 11.572L20.9878 10.4308C21.3703 10.0691 22 10.3403 22 10.8668V19.3684C22 20.8218 20.8218 22 19.3684 22H4.63158C3.1782 22 2 20.8218 2 19.3684V4.63158Z" fill="currentColor"></path>
                                                <path d="M10.9256 11.1882C10.5351 10.7977 10.5351 10.1645 10.9256 9.77397L18.0669 2.6327C18.8479 1.85165 20.1143 1.85165 20.8953 2.6327L21.3665 3.10391C22.1476 3.88496 22.1476 5.15129 21.3665 5.93234L14.2252 13.0736C13.8347 13.4641 13.2016 13.4641 12.811 13.0736L10.9256 11.1882Z" fill="currentColor"></path>
                                                <path d="M8.82343 12.0064L8.08852 14.3348C7.8655 15.0414 8.46151 15.7366 9.19388 15.6242L11.8974 15.2092C12.4642 15.1222 12.6916 14.4278 12.2861 14.0223L9.98595 11.7221C9.61452 11.3507 8.98154 11.5055 8.82343 12.0064Z" fill="currentColor"></path>
                                            </svg>
                                        </span>
                                        Award Settings
                                    </AwardSettingsButton>
                                </div>
                            </ToolbarContainer>
                            <PageContainer>
                                <KTCard stretch="xl-stretch" className="mb-5 mb-xl-8">
                                    <KTCardBody className="tab-content pb-0">
                                        <AwardTabTable title="Submissions" award={award} awardCat={Number(id)} tableCols={closedColumns} />
                                    </KTCardBody>
                                </KTCard>
                            </PageContainer>
                        </>
                    }
                </AwardSubmissionWinnerModalProvider>
            </DownloadSubmissionsModalProvider>
        </EditModalProvider>

    )
}


export { AwardDetailPage }