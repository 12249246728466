//TODO: detail page much like the designed award category page with its content

import { NewsApi, useAuthContext } from "@pull/pull-groupm-csp-api";
import { FC, useState } from "react";
import { useQuery } from "react-query";
import { Link, useNavigate, useParams } from "react-router-dom";
import { KTCard } from "../../../../_metronic/helpers";
import { PageLink, PageTitle } from "../../../../_metronic/layout/core";
import { ToolbarContainer } from "../../../../_metronic/layout/core/ToolbarContainer";
import { QUERIES } from "../../../Constants/Queries";
import PageContainer from "../../../helpers/components/listing/PageContainer";
import { LoadingComponent } from "../../../modules/core/LoadingComponent";
import { NewsForm } from "./form/NewsForm";


const NewsDetailPage:FC = () =>{
    const { id } = useParams()
    const navigate = useNavigate()
    const validId = id === "new" || Number(id)
    if (!validId) {
        navigate("/404")
    }
    const queryId = id ==="new"? 0: Number(id)
    const { getApiConfiguration } = useAuthContext()
    const newsApi = new NewsApi(getApiConfiguration())
    const { isLoading, data:article} = useQuery([QUERIES.NEWS.GET + id], () => newsApi.apiNewsIdIdGet(queryId), {})

    if (isLoading) {
        return (<LoadingComponent></LoadingComponent>)
    }

    const newsItemBreadcrumbs: Array<PageLink> = [
        {
          title: '',
          path: '',
          isSeparator: true,
          isActive: false,
        },
        {
          title: 'All News',
          path: '/news',
          isSeparator: false,
          isActive: true,
        },
        {
            title: '',
            path: '',
            isSeparator: true,
            isActive: false,
          },
        {
            title:article?.title?? "New Article",
            path:'',
            isSeparator: false,
            isActive: false
        }
      
      ]

    return (
         <>
         <ToolbarContainer>
         <PageTitle breadcrumbs={newsItemBreadcrumbs} title={article?.title ?? "New Article"}>
                    <div className="mt-6">
                        <Link to="/news" className="btn btn-sm btn-dark fw-bold">
                            <span className="svg-icon svg-icon-2">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect opacity="0.5" x="6" y="11" width="13" height="2" rx="1" fill="currentColor" />
                                    <path d="M8.56569 11.4343L12.75 7.25C13.1642 6.83579 13.1642 6.16421 12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75L5.70711 11.2929C5.31658 11.6834 5.31658 12.3166 5.70711 12.7071L11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25C13.1642 17.8358 13.1642 17.1642 12.75 16.75L8.56569 12.5657C8.25327 12.2533 8.25327 11.7467 8.56569 11.4343Z" fill="currentColor" />
                                </svg>
                            </span>
                            Back to News
                        </Link>
                    </div>
                </PageTitle>
                </ToolbarContainer>
                <PageContainer>
							<KTCard className="mb-5 mb-xl-10">
								<div className="card-header border-0">
									<div className="card-title m-0">
										<h3 className="fw-bold m-0">News Article Content (copy that appears on the main site)</h3>
									</div>
								</div>
                                {article ?
                                <NewsForm article={article} />
                                :
                                <NewsForm article={{id:0}} />
                                }
								
                                </KTCard>
                    </PageContainer>

         </>
        )
    
    
}

export {NewsDetailPage}