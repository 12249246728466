import { CaseStudyInfo, CaseStudiesApi, useAuthContext } from '@pull/pull-groupm-csp-api'
import { FC, useEffect } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { Link } from 'react-router-dom'
import { MenuComponent } from '../../../../../_metronic/assets/ts/components'
import { QUERIES } from '../../../../Constants/Queries'
import { Delete } from '../../../../helpers/functions/SwalHelpers'

type Props = {
    caseStudy: CaseStudyInfo
}

const CaseStudyActionCell: FC<Props> = ({caseStudy}) => {
    const { getApiConfiguration } = useAuthContext();

    const apiConfig = getApiConfiguration();
    const caseStudyApi = new CaseStudiesApi(apiConfig);

    const queryClient = useQueryClient();

    useEffect(() => {
        MenuComponent.reinitialization()
      }, [])

      const deleteOnClick = () =>{
        Delete("case study")
        .then(async (result) =>{
          if(result.isConfirmed){
            await deleteItem.mutateAsync()
          }
        })
      }
    
    const deleteItem = useMutation(() => caseStudyApi.apiCaseStudiesIdDelete(caseStudy.id??0), {
        // 💡 response of the mutation is passed to onSuccess
        onSuccess: () => {
          // ✅ update detail view directly
          queryClient.invalidateQueries([QUERIES.CASE_STUDIES.SEARCH])
        },
      })

    return (
        <div className="text-end">
            <Link to={`/case-studies/${caseStudy.id}`} className="btn btn-bg-light btn-color-gray-700 btn-active-color-info btn-sm px-4 me-2">Details</Link>
            <button type="button" 
            className="btn btn-bg-light btn-color-gray-700 btn-active-color-danger btn-sm px-4" 
            data-delete-case-study=""
            onClick={deleteOnClick}>Delete</button>
        </div>
    )
}

export default CaseStudyActionCell