import moment from "moment";

export function getDateRange(value: string) {

    if (value === undefined)
        return null;

    if (value.indexOf(" to ") === -1){

        var d = moment(value);

        return  {
            startDate: value,
            endDate: d.format("yyyy-MM-DD")
        };
    }

    var result = value.split(" to ");

    var rTuple: [string, string] = [result[0], result[1]];
    return {
        startDate: rTuple[0],
        endDate: rTuple[1]
    };
}