
import { PageTitle } from '../../../../_metronic/layout/core'
import ListingLayout from '../../../helpers/components/listing/PageContainer'
import SearchCol from '../../../helpers/components/listing/searchCard/SearchCol'
import TableCard from '../../../helpers/components/listing/tableCard/TableCard'
import { usersColumns } from '../../user-management/users-list/table/columns/_columns'
import AnchorButton from '../../../helpers/components/generic/AnchorButton'
import { FC, useContext, useEffect, useState } from 'react'
import { SearchCountriesRequest, SearchRegionsRequest, SearchUsersRequest, SettingsApi, useAuthContext, UserInfo, UsersApi } from '@pull/pull-groupm-csp-api'
import { update } from 'lodash'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { QUERIES } from '../../../Constants/Queries'
import { PagedResultInterface } from '../../../modules/core/_models'
import { clear } from 'console'
import { CRContextType, CRProvider, useCRContext } from '../../../modules/country-region/CountryRegionContext'
import { countryRegionColumns } from '../columns/_columns'
import { CREditModalFormWrapper } from './edit-modal/CountryRegionModalWrapper'
import { EditModalProvider, useEditModalContext } from '../../../modules/core/functional/EditModalContext'
import { TableProvider, useTableContext } from '../../../modules/core/functional/TableContext'
import { ToolbarContainer } from '../../../../_metronic/layout/core/ToolbarContainer'

const CRPage = () => {
  const { updatePage, updateFilters } = useTableContext()
  const { getApiConfiguration } = useAuthContext()
  const { filters, type, breadcrumbs } = useCRContext()
  const { updateSelectedValue, showEditModal } = useEditModalContext()
  const settingsApi = new SettingsApi(getApiConfiguration())

  const getData = async (pageParam = 1, filters = {}) => {
    let response: PagedResultInterface = {}
    if (type == CRContextType.Country) {
      response = await settingsApi.apiSettingsCountriesSearchPost({ ...filters, ...{ page: pageParam, pageSize: 10 } } as SearchCountriesRequest)
    }
    else {
      response = await settingsApi.apiSettingsRegionsSearchPost({ ...filters, ...{ page: pageParam, pageSize: 10 } } as SearchRegionsRequest)
    }

    return response as PagedResultInterface;
  }
  const submitSearchHandler = (searchFields: any) => {
    updateFilters({ name: searchFields.name, includeDisabled: searchFields.includeDisabled ? true : false })//JSON.parse(searchFields)))
  }

  const openUserModal = () => {
    updateSelectedValue({ id: 0 })
  }

  return (
    <>
    <ToolbarContainer>
      <PageTitle breadcrumbs={breadcrumbs} title={type}></PageTitle>
      </ToolbarContainer>
      <CREditModalFormWrapper showModal={showEditModal} />
      <ListingLayout >
      <div className="d-flex flex-column flex-lg-row">
        <SearchCol filters={filters} submitSearch={submitSearchHandler} searchName="name" />
        <TableCard tableCols={countryRegionColumns} getData={getData}  query={QUERIES.GLOBAL.SEARCH + type} >
          <AnchorButton className="btn-info" action={openUserModal} >
            <>
              <span className="svg-icon svg-icon-2">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect opacity="0.5" x="11.364" y="20.364" width="16" height="2" rx="1" transform="rotate(-90 11.364 20.364)" fill="currentColor"></rect>
                  <rect x="4.36396" y="11.364" width="16" height="2" rx="1" fill="currentColor"></rect>
                </svg>
              </span>
              New
            </>
          </AnchorButton>
        </TableCard>
        </div>
      </ListingLayout>
    </>
  )
}

const CountryPageWrapper = () => {
  return (
    <CRProvider type={CRContextType.Country}>
      <TableProvider>
        <EditModalProvider>
          <CRPage />
        </EditModalProvider>
      </TableProvider>
    </CRProvider>
  )
}
const RegionPageWrapper = () => {
  return (
    <CRProvider type={CRContextType.Region}>
      <TableProvider>
        <EditModalProvider>
          <CRPage />
        </EditModalProvider>
      </TableProvider>
    </CRProvider>
  )
}

export { CRPage, CountryPageWrapper, RegionPageWrapper } 
