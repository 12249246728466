import { CaseStudyInfo } from "@pull/pull-groupm-csp-api";
import { Column } from "react-table";
import { CustomHeader } from "../../../../helpers/components/listing/tableCard/column/CustomHeader";
import CaseStudyActionCell from "./CaseStudyActionCell";
import { CaseStudyInfoCell } from "./CaseStudyInfoCell";
import { CaseStudyStatusCell } from "./CaseStudyStatusCell";

const caseStudyColumns: ReadonlyArray<Column<CaseStudyInfo>> =[
    {
        Header: (props) => <CustomHeader tableProps={props} title='Name' className='min-w-125px' />,
        id:'name',
        Cell: ({...props}) => <CaseStudyInfoCell caseStudy={props.data[props.row.index]} />
    },
    {
        Header: (props) => <CustomHeader tableProps={props} title='Created' className='min-w-125px' />,
        id:'created',
        Cell: ({...props}) => <span className="text-dark fw-bold d-block fs-6">{new Date(props.data[props.row.index].metadata?.created).toLocaleDateString()}</span>
    },
    {
        Header: (props) => <CustomHeader tableProps={props} title='Views' className='min-w-125px' />,
        id:'views',
        Cell: ({...props}) => <span className="text-dark fw-bold d-block fs-6">{props.data[props.row.index].viewCount ?? 0}</span>
    },
    {
        Header: (props) => <CustomHeader tableProps={props} title='Status' className='min-w-125px' />,
        id:'status',
        Cell: ({...props}) => <CaseStudyStatusCell published={props.data[props.row.index].published} />
    },
    {
        Header: (props) => <CustomHeader tableProps={props} title='Actions' className='min-w-200px text-end' />,
        id:'actions',
        Cell: ({...props}) => <CaseStudyActionCell caseStudy={props.data[props.row.index]} />
    }
]

export default caseStudyColumns


