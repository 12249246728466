import swal from 'sweetalert2'

const Warning = swal.mixin({
    icon:"warning",
    allowEnterKey:false,
    confirmButtonColor:'#9A3EFF'
})

const Delete = (target:string, additionalMessage?:string) =>{ 

    var message = `Are you sure you want to delete this ${target}?`

    if(additionalMessage)
        message += `\n\n${additionalMessage}`

    return Warning.fire({
    title: message,
    confirmButtonText:`Yes, delete ${target}`,
})
}
const Confirmation = (question:string, confirmText:string) =>{
    return Warning.fire({
        title:question,
        confirmButtonText:confirmText,
        showCancelButton:true
    })
}

const Success = (successMessage:string ) => {
    swal.fire({
    icon:"success",
    title:successMessage,
    focusConfirm:true,
    confirmButtonColor:'#9A3EFF',
})
}


export {Delete,Success,Confirmation}