import { CaseStudyInfo, EmailTemplateInfo, NewsInfo, PageInfo } from "@pull/pull-groupm-csp-api";
import { Column } from "react-table";
import { AuthorCell } from "../../../helpers/components/listing/tableCard/column/AuthorCell";
import { CustomHeader } from "../../../helpers/components/listing/tableCard/column/CustomHeader";
import NewsActionCell from "./NewsActionCell";
import PagesActionCell from "./PagesActionCell";
import EmailTemplatesActionCell from "./EmailTemplatesActionCell";

const baseContentColumns: ReadonlyArray<Column<NewsInfo|PageInfo>> =[
    {
        Header: (props) => <CustomHeader tableProps={props} title='Title' className='min-w-100px' />,
        id:'title',
        Cell: ({...props}) => <span className="text-dark fw-bold d-block fs-6">{props.data[props.row.index].title}</span>
    }, //change the below publish from and to, instead to show author - maybe url slug
    {
        Header: (props) => <CustomHeader tableProps={props} title='Author' className='min-w-100px' />,
        id:'author',
        Cell: ({...props}) => <AuthorCell user={props.data[props.row.index].metadata.createdBy} />
    }
]

const newsColumns: ReadonlyArray<Column<NewsInfo>> = [
    ...baseContentColumns,
    {
        Header: (props) => <CustomHeader tableProps={props} title='Actions' className='min-w-200px text-end' />,
        id:'actions',
        Cell: ({...props}) => <NewsActionCell item={props.data[props.row.index]} />
    }
]

const emailTemplateColumns: ReadonlyArray<Column<EmailTemplateInfo>> = [
    {
        Header: (props) => <CustomHeader tableProps={props} title='Name' className='min-w-100px' />,
        id:'title',
        Cell: ({...props}) => <span className="text-dark fw-bold d-block fs-6">{props.data[props.row.index].displayName}</span>
    }, //change the below publish from and to, instead to show author - maybe url slug
    {
        Header: (props) => <CustomHeader tableProps={props} title='Modified By' className='min-w-100px' />,
        id:'author',
        Cell: ({...props}) => <AuthorCell user={props.data[props.row.index].metadata.modifiedBy} />
    },
    {
        Header: (props) => <CustomHeader tableProps={props} title='Modified By' className='min-w-100px' />,
        id:'modified',
        Cell: ({...props}) => <span className="text-gray-800 text-hover-primary mb-1">{new Date(props.data[props.row.index].metadata?.modified).toLocaleDateString()}</span>
    },
    {
        Header: (props) => <CustomHeader tableProps={props} title='Actions' className='min-w-200px text-end' />,
        id:'actions',
        Cell: ({...props}) => <EmailTemplatesActionCell item={props.data[props.row.index]} />
    }
]

const pagesColumns: ReadonlyArray<Column<PageInfo>> = [
    ...baseContentColumns,
    {
        Header: (props) => <CustomHeader tableProps={props} title='Actions' className='min-w-200px text-end' />,
        id:'actions',
        Cell: ({...props}) => <PagesActionCell item={props.data[props.row.index]} />
    }
]

export {newsColumns, pagesColumns, emailTemplateColumns}


