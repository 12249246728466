import { FC, useEffect, useRef, useState } from "react"
import ReactDOM from "react-dom";
import { useDownloadSubmissionsModalContext } from "./DownloadSubmissionsModalContext";
import { ModalContainer } from "../../../helpers/components/generic/ModalContainer";
import { AwardSubmissionCsvColumns, AwardsApi, useAuthContext } from "@pull/pull-groupm-csp-api";
import { AwardDownloadSubmissionsModal } from "../model/_models";
import { getCountryRegionTagify, getTagsTagify } from '../../../helpers/functions/TagifyInstance'
import { useGlobalContext } from "../../../modules/core/GlobalContext";

const DownloadSubmissionsModal: FC = () => {
    const allowedColumns = [{ name: "Title", displayName: "Title", selected: true, disabled: true },
    { name: "Url", displayName: "URL", selected: true, disabled: true },
    { name: AwardSubmissionCsvColumns.Advertiser, displayName: "Advertiser", selected: true, disabled: false },
    { name: AwardSubmissionCsvColumns.Brand, displayName: "Brand", selected: true, disabled: false },
    { name: AwardSubmissionCsvColumns.Regions, displayName: "Region", selected: true, disabled: false },
    { name: AwardSubmissionCsvColumns.Countries, displayName: "Country", selected: true, disabled: false },
    { name: AwardSubmissionCsvColumns.AwardName, displayName: "Award", selected: true, disabled: false },
    { name: AwardSubmissionCsvColumns.Scores, displayName: "Scores", selected: true, disabled: false },
    { name: AwardSubmissionCsvColumns.AverageScore, displayName: "Average score", selected: true, disabled: false },
    { name: AwardSubmissionCsvColumns.Shortlisted, displayName: "Shortlisted", selected: true, disabled: false },
    { name: AwardSubmissionCsvColumns.Winner, displayName: "Winner", selected: true, disabled: false },
    { name: AwardSubmissionCsvColumns.Comments, displayName: "Comments", selected: true, disabled: false }];

    const { selectedValue, updateSelectedValue, showDownloadSubmissionsModal } = useDownloadSubmissionsModalContext()
    const { tags, countries, regions } = useGlobalContext()
    const [fileUrl, setFileUrl] = useState("#")
    const [fileName, setFileName] = useState("")
    const [noRowsWarning, setNoRowsWarning] = useState(false)
    const [loading, setLoading] = useState(false)
    const [columns, setColumns] = useState(allowedColumns)
    const { getApiConfiguration } = useAuthContext()
    const ref = useRef<HTMLAnchorElement | null>(null)
    var advertisers = useRef<HTMLInputElement | null>(null)
    var countriesElem = useRef<HTMLInputElement | null>(null)
    var regionsElem = useRef<HTMLInputElement | null>(null)


    useEffect(() => {
        if (advertisers?.current)
            getTagsTagify(advertisers.current, tags?.advertisers?.values)

        if (countriesElem?.current)
            getCountryRegionTagify(countriesElem.current, countries)

        if (regionsElem?.current)
            getCountryRegionTagify(regionsElem.current, regions)

        setColumns(allowedColumns)

    }, [showDownloadSubmissionsModal])

    if (!showDownloadSubmissionsModal)
        return <></>

    const cancel = () => {
        updateSelectedValue(null)
    }

    const toggleColumn = (columnName: string) => {
        const newState = columns.map(item => item.name === columnName ? { ...item, selected: !item.selected } : item)
        setColumns(newState)
    }

    const toggleAll = (enabled: boolean) => {
        const newState = columns.map(item => !item.disabled ? { ...item, selected: enabled } : item)
        setColumns(newState)
    }

    const downloadCSV = async (e: any) => {

        e.preventDefault()

        if (loading)
            return;

        setNoRowsWarning(false);
        setLoading(true);
        var props = selectedValue as AwardDownloadSubmissionsModal;
        setFileName(props.fileName)

        var selectedColumns = columns.filter(c => c.selected && !c.disabled).map(c => c.name as AwardSubmissionCsvColumns);

        const awardsApi = new AwardsApi(getApiConfiguration());
        var request = {
            selectedAwardCategories: [props.awardCategoryID],
            awardID: props.awardID,
            year: props.year,
            csvColumns: selectedColumns,
            selectedAdvertisers: advertisers.current?.value.split(',').filter(e => e).map(e => parseInt(e)),
            selectedCountries: countriesElem.current?.value.split(',').filter(e => e).map(e => parseInt(e)),
            selectedRegions: regionsElem.current?.value.split(',').filter(e => e).map(e => parseInt(e))
        };
        var data = await awardsApi.apiAwardsAwardSubmissionsSearchCsvPost(request)?.then((result) => { return result.blob() })

        if (!data)
            cancel();

        if (data.size == 0) {
            setNoRowsWarning(true);
            setLoading(false);
        }
        else {
            const url = URL.createObjectURL(new Blob([data]))
            setFileUrl(url);
            setTimeout(() => {
                ref.current?.click();
                cancel();
            }, 300)
            setLoading(false);
        }
    }

    return (
        <ModalContainer id="kt_modal_download_form" innerClassName="mw-950px">
            <div className="modal-header" id="kt_modal_download_header">
                <h2>Download Submissions</h2>
                <button onClick={cancel} className="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">
                    <span className="svg-icon svg-icon-1">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1" transform="rotate(-45 6 17.3137)" fill="currentColor" />
                            <rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)" fill="currentColor" />
                        </svg>
                    </span>
                </button>
            </div>
            <div className="modal-body py-10 px-lg-17">
                <div>
                    <h3 className="d-flex align-items-start flex-column mb-3">
                        <span className="card-label fw-bold fs-2 mb-1">Filter options</span>
                        <span className="text-gray-650 mt-1 fw-semibold fs-6">Choose the content you want to export</span>
                    </h3>

                    <div className="border rounded px-5 pt-5 pb-0 mb-8">
                        <div className="row">
                            <div className="col-lg-4 mb-5">
                                <label className='fs-6 fw-semibold mb-2'>Advertisers</label>
                                <input ref={advertisers} id="submissions_advertisers" className="form-control form-control-solid" />
                            </div>
                            <div className="col-lg-4 mb-5">
                                <label className='fs-6 fw-semibold mb-2'>Countries</label>
                                <input ref={countriesElem} id="submissions_countries" className="form-control form-control-solid" />
                            </div>
                            <div className="col-lg-4 mb-5">
                                <label className='fs-6 fw-semibold mb-2'>Regions</label>
                                <input ref={regionsElem} id="submissions_regions" className="form-control form-control-solid" />
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <h3 className="d-flex align-items-start flex-column mb-3">
                        <span className="card-label fw-bold fs-2 mb-1">Spreadsheet columns</span>
                        <span className="text-gray-650 mt-1 fw-semibold fs-6">Choose which fields are shown in exported file</span>
                    </h3>

                    <div className="border rounded px-5 pt-6 pb-0">
                        <div className="row">
                            {columns.map((component, index) => {
                                return (<div className="col-lg-4 mb-5">
                                    <div className="form-check form-check-custom form-check-solid">
                                        <input onChange={(e) => toggleColumn(component.name.toString())} className="form-check-input" type="checkbox" value="" id={'export-' + component.name} checked={component.selected} disabled={component.disabled} />
                                        <label className="form-check-label" htmlFor={'export-' + component.name}>
                                            {component.displayName}
                                        </label>
                                    </div>
                                </div>)
                            })}
                        </div>
                        <div className="d-flex pb-4">
                            <button onClick={() => toggleAll(true)} type="button" className="btn btn-link text-info me-5">Select All</button>
                            <button onClick={() => toggleAll(false)} type="button" className="btn btn-link text-gray-650">Deselect All</button>
                        </div>
                    </div>
                </div>
                {noRowsWarning ?
                    <div className="notice d-flex bg-light-warning rounded border-warning border mt-9 p-6">
                    <span className="svg-icon svg-icon-2tx svg-icon-warning me-4">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="currentColor"></rect>
                            <rect x="11" y="14" width="7" height="2" rx="1" transform="rotate(-90 11 14)" fill="currentColor"></rect>
                            <rect x="11" y="17" width="2" height="2" rx="1" transform="rotate(-90 11 17)" fill="currentColor"></rect>
                        </svg>
                    </span>
                    <div className="d-flex flex-stack flex-grow-1">
                        <div className="fw-semibold">
                            <div className="fs-6 text-gray-800">No award submissions matched your filters. Please refine your filters and try again.
                            </div>
                        </div>
                    </div>
                </div>: ""}
            </div>
            <div className="modal-footer justify-content-end">
                <div>
                    <button onClick={cancel} type="button" id="kt_modal_download-cancel" data-bs-dismiss="modal" className="btn btn-light btn-color-gray-700 btn-active-color-info me-3">Cancel</button>
                    <button onClick={downloadCSV} data-kt-indicator={loading ? "on" : "off"} type="submit" id="kt_modal_download_submit" className="btn btn-info">
                        <span className="svg-icon svg-icon-2">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M21 22H3C2.4 22 2 21.6 2 21C2 20.4 2.4 20 3 20H21C21.6 20 22 20.4 22 21C22 21.6 21.6 22 21 22ZM13 13.4V3C13 2.4 12.6 2 12 2C11.4 2 11 2.4 11 3V13.4H13Z" fill="currentColor" />
                                <path opacity="0.3" d="M7 13.4H17L12.7 17.7C12.3 18.1 11.7 18.1 11.3 17.7L7 13.4Z" fill="currentColor" />
                            </svg>
                        </span>
                        <span className="indicator-label">Download</span>
                        <span className="indicator-progress">Please wait...
                            <span className="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                    </button>
                </div>
            </div>
            <a href={fileUrl} download={fileName} className="hidden" ref={ref} ></a>
        </ModalContainer>
    )

}

export { DownloadSubmissionsModal }

