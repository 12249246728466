/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { SwapperComponent } from '../../../_metronic/assets/ts/components'
import { checkIsActive, KTSVG } from '../../../_metronic/helpers'
import { HeaderUserMenu, ThemeModeSwitcher } from '../../../_metronic/partials'
import clsx from 'clsx'
import logo from '../../assets/logos/mindshare-logo.svg'
import { AwardsMenuDropDown } from './AwardsMenuDropDown'

const Header: FC = () => {
	const { pathname } = useLocation()
	useEffect(() => {
		SwapperComponent.reinitialization()
	}, [pathname])
	return (
		<div id="kt_header" className="header align-items-stretch">
			<div className="container-xxl d-flex align-items-center">
				<div className="d-flex topbar align-items-center d-lg-none ms-n2 me-3" title="Show aside menu">
					<div className="btn btn-icon btn-active-light-primary btn-custom w-30px h-30px w-md-40px h-md-40px" id="kt_header_menu_mobile_toggle">
						<KTSVG path="media/icons/duotune/abstract/abs015.svg" className="svg-icon-1"></KTSVG>
					</div>
				</div>
				<div className="header-logo me-5 me-md-10 flex-grow-1 flex-lg-grow-0">
					<Link to="/">
						<img alt="Logo" src={logo} className="h-16px logo-default" />
					</Link>
				</div>
				<div className="d-flex align-items-stretch justify-content-between flex-lg-grow-1">
					<div className="d-flex align-items-stretch" id="kt_header_nav">
						<div className="header-menu align-items-stretch" data-kt-drawer="true" data-kt-drawer-name="header-menu" data-kt-drawer-activate="{default: true, lg: false}" data-kt-drawer-overlay="true" data-kt-drawer-width="{default:'200px', '300px': '250px'}" data-kt-drawer-direction="start" data-kt-drawer-toggle="#kt_header_menu_mobile_toggle" data-kt-swapper="true" data-kt-swapper-mode="prepend" data-kt-swapper-parent="{default: '#kt_body', lg: '#kt_header_nav'}">
							<div className="menu menu-rounded menu-column menu-lg-row menu-title-gray-900 menu-state-primary menu-arrow-gray-400 fw-semibold my-5 my-lg-0 align-items-stretch px-2 px-lg-0" id="#kt_header_menu" data-kt-menu="true">
								<div className={clsx("menu-item me-0 me-lg-2", {
									here: pathname === "/",
								})}>
									<Link className={clsx('menu-link py-3', {
										active: pathname === "/",
									})} to="/">
										<KTSVG path="/media/icons/duotune/general/gen001.svg" className="svg-icon-light svg-icon-2" />
									</Link>
								</div>
								<div data-kt-menu-trigger="{default: 'click', lg: 'hover'}" data-kt-menu-placement="bottom-start"
									className={clsx("menu-item menu-lg-down-accordion menu-sub-lg-down-indention me-0 me-lg-2", {
										here: checkIsActive(pathname, "/case-studies") || checkIsActive(pathname, "/tags"),
									})}>
									<span className="menu-link py-3">
										<span className="menu-title">Case Studies</span>
										<span className="menu-arrow"></span>
									</span>
									<div className="menu-sub menu-sub-lg-down-accordion menu-sub-lg-dropdown px-lg-5 py-lg-4 w-lg-200px">
										<div className="menu-item">
											<Link className={clsx('menu-link py-3', {
												active: checkIsActive(pathname, "/case-studies"),
											})} to="/case-studies">
												<span className="menu-title">All Case Studies</span>
											</Link>
										</div>
										<div className="menu-item">
											<Link className={clsx('menu-link py-3', {
												active: checkIsActive(pathname, "/tags"),
											})} to="/tags">
												<span className="menu-title">Tags</span>
											</Link>
										</div>
									</div>
								</div>
								<div data-kt-menu-trigger="{default: 'click', lg: 'hover'}" data-kt-menu-placement="bottom-start"
									className={clsx("menu-item menu-lg-down-accordion menu-sub-lg-down-indention me-0 me-lg-2", {
										here: checkIsActive(pathname, "/awards"),
									})}>
									<span className="menu-link py-3">
										<span className="menu-title">Awards</span>
										<span className="menu-arrow"></span>
									</span>
									<AwardsMenuDropDown pathname={pathname} />
								</div>
								<div className={clsx("menu-item me-0 me-lg-2", {
									here: checkIsActive(pathname, "/news"),
								})}>
									<Link className={clsx('menu-link py-3', {
										active: checkIsActive(pathname, "/news"),
									})} to="/news">
										<span className="menu-title">News</span>
									</Link>
								</div>
								<div className="menu-item me-0 me-lg-2">
									<Link className={clsx('menu-link py-3', {
										active: checkIsActive(pathname, "/pages"),
									})} to="/pages">
										<span className="menu-title">Pages</span>
									</Link>
								</div>
								<div className="menu-item me-0 me-lg-2">
									<Link className={clsx('menu-link py-3', {
										active: checkIsActive(pathname, "/analytics"),
									})} to="/analytics">
										<span className="menu-title">Analytics</span>
									</Link>
								</div>
								<div className={clsx("menu-item me-0 me-lg-2", {
									here: checkIsActive(pathname, "/users"),
								})}>
									<Link className={clsx('menu-link py-3', {
										active: checkIsActive(pathname, "/users"),
									})} to="/users">
										<span className="menu-title">Users</span>
									</Link>
								</div>
								<div data-kt-menu-trigger="{default: 'click', lg: 'hover'}" data-kt-menu-placement="bottom-start"
									className={clsx("menu-item menu-lg-down-accordion menu-sub-lg-down-indention me-0 me-lg-2", {
										here: checkIsActive(pathname, "/countries") || checkIsActive(pathname, "/regions") || checkIsActive(pathname, "/settings")
									})}>
									<span className="menu-link py-3">
										<span className="menu-title">System</span>
										<span className="menu-arrow"></span>
									</span>
									<div className="menu-sub menu-sub-lg-down-accordion menu-sub-lg-dropdown px-lg-5 py-lg-4 w-lg-200px">
										<div className="menu-item">
											<Link className={clsx('menu-link py-3', {
												active: checkIsActive(pathname, "/countries"),
											})} to="/countries">
												<span className="menu-title">Countries</span>
											</Link>
										</div>
										<div className="menu-item">
											<Link className={clsx('menu-link py-3', {
												active: checkIsActive(pathname, "/regions"),
											})} to="/regions">
												<span className="menu-title">Regions</span>
											</Link>
										</div>
										<div className="menu-item">
											<Link className={clsx('menu-link py-3', {
												active: checkIsActive(pathname, "/settings"),
											})} to="/settings">
												<span className="menu-title">Settings</span>
											</Link>
										</div>
										<div className="menu-item">
											<Link className={clsx('menu-link py-3', {
												active: checkIsActive(pathname, "/email-templates"),
											})} to="/email-templates">
												<span className="menu-title">Email templates</span>
											</Link>
										</div>
										<div className="menu-item">
											<Link className={clsx('menu-link py-3', {
												active: checkIsActive(pathname, "/email-queue"),
											})} to="/email-queue">
												<span className="menu-title">Email queue</span>
											</Link>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="topbar d-flex align-items-stretch flex-shrink-0">
						<div className="d-flex align-items-center ms-1 ms-lg-3">
							<ThemeModeSwitcher
								toggleBtnClass='btn-active-light-primary btn-custom w-30px h-30px w-md-40px h-md-40px'
								toggleBtnIconClass='svg-icon-1'
							/>
						</div>
						<HeaderUserMenu />
					</div>
				</div>
			</div>
		</div>
	)
}

export { Header }
