import { AddUpdateAwardCategoryRequest, AwardCategoryInfo, AwardsApi, useAuthContext, } from '@pull/pull-groupm-csp-api'
import clsx from 'clsx'
import { useFormik } from 'formik'
import { FC, useState } from 'react'
import { Typeahead } from 'react-bootstrap-typeahead'
import { useQuery, useQueryClient } from 'react-query'
import { KTCard, KTCardBody } from '../../../../_metronic/helpers'
import { QUERIES } from '../../../Constants/Queries'
import { RichTextEditor } from '../../../helpers/components/form/RichTextEditor'
import { ErrorBanner, NotificationBanner } from '../../../helpers/components/generic/NotificationBanner'
import { getErrorFromResponse } from '../../../helpers/functions/getErrorFromResponse'
import { Success } from '../../../helpers/functions/SwalHelpers'

type Props = {
    awardCat: AwardCategoryInfo
}


const AwardCategoryPageCopy: FC<Props> = ({ awardCat }) => {
    const [settings] = useState({ id: awardCat.id, description: awardCat.description})
    const [error, setError] = useState<string | null>(null)
    const queryClient = useQueryClient()
    const { getApiConfiguration } = useAuthContext()
    const formik = useFormik({
        initialValues: settings,
        onSubmit: async (values, { setSubmitting }) => {
            setSubmitting(true)
            
            const awardApi = new AwardsApi(getApiConfiguration())
            const updateAwardCat: AddUpdateAwardCategoryRequest = { id: values.id, description:values.description,automaticWinnerAnnouncement: awardCat.automaticWinnerAnnouncement ?? false };
            try {
                const result = await awardApi.apiAwardsAwardCategoriesPost(updateAwardCat)
                if (result?.id) {
                    queryClient.invalidateQueries([QUERIES.AWARDS.GET_CATEGORY, awardCat.id])
                    queryClient.invalidateQueries([QUERIES.AWARDS.GET_ALL_CATEGORIES])
                    Success("The award category's content was successfully updated!")
                }
            } catch (ex) {
                if (ex as Response) {
                    const errorMessage = await getErrorFromResponse(ex as Response)
                    setError(errorMessage)
                }
                else {
                    console.log(ex)
                }
            }
        }
    })

    return (
        <KTCard className="mb-5 mb-xl-10">
            <div className="card-header border-0">
                <div className="card-title m-0">
                    <h3 className="fw-bold m-0">CMS Content (copy that appears on the main site)</h3>
                </div>
            </div>
            <div id="case-study-settings">
                <form id="case-study-settings_form" className="form" onSubmit={formik.handleSubmit} noValidate>
                    <KTCardBody className="border-top p-9">
                    {error &&
                            <ErrorBanner message={error} />
                        }
                       <div className="row mb-0 fv-row">
                        <label className="col-lg-4 col-form-label fw-semibold fs-6" htmlFor="category_page_copy">Page copy</label>
                        <div className="col-lg-8 d-flex align-items-center">
                            <RichTextEditor 
                            changeHandler={(content) => {
                                formik.setFieldValue("description",content)
                            }} 
                            content={formik.values.description?? ""}
                            className={clsx(
                                'form-control form-control-solid',
                                { 'is-invalid': formik.touched.description && formik.errors.description },
                                {
                                    'is-valid': formik.touched.description && !formik.errors.description,
                                }
                            )}
                             />
                            {formik.touched.description && formik.errors.description && (
                                <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block'>
                                        <span role='alert'>{formik.errors.description}</span>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                    </KTCardBody>
                    <div className="card-footer sticky bottom-0 d-flex justify-content-end py-6 px-9">
                        <button type="submit" className="btn btn-info" id="case-study-settings_submit">Save Changes</button>
                    </div>
                </form>
            </div>
        </KTCard>
    )
}

export default AwardCategoryPageCopy