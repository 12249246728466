import { FC } from "react";


type Props = {
    name: string,
    startDate: Date,
    endDate: Date
    className?: string,
    onChange?: (startDate:Date, endDate: Date) => Promise<void>;
}


const FlatpickrRangeItem: FC<Props> = ({ name, startDate, endDate, onChange, className, }) => {

    function selected(){
        if(onChange)
            onChange(startDate, endDate);
    }

    return (
        <>
            <div className="menu-item px-3">
                <a onClick={selected} className="menu-link px-3">{name}</a>
            </div>
        </>
    )

}



export { FlatpickrRangeItem }