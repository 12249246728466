import {
  FC,
  useState,
  useEffect,
  createContext,
  useContext
} from 'react'
import { useAuthContext, SettingInfo, SettingsApi, TagCollectionInfo, TagsApi, CountryInfo, RegionInfo } from '@pull/pull-groupm-csp-api';
import { WithChildren } from '../../helpers/components/react18MigrationHelpers';
import { LoadingComponent } from './LoadingComponent';
import { useQuery } from 'react-query';
import PageContainer from '../../helpers/components/listing/PageContainer';
import { QUERIES } from '../../Constants/Queries';

type GlobalContextProps = {
  getSetting: (key: string) => any;
  tags: TagCollectionInfo | null;
  countries: CountryInfo[],
  regions: RegionInfo[]
}

const initGlobalContextPropsState = {
  getSetting: () => null,
  tags: null,
  countries: [],
  regions: []
}

const GlobalContext = createContext<GlobalContextProps>(initGlobalContextPropsState)

const useGlobalContext = () => {
  return useContext(GlobalContext)
}

const GlobalProvider: FC<WithChildren> = ({ children }) => {

  const [appSettings, setAppSettings] = useState<SettingInfo[] | null>(null)
  const [tags, setTags] = useState<TagCollectionInfo>({})
  const [countries, setCountries] = useState<CountryInfo[]>([])
  const [regions, setRegions] = useState<RegionInfo[]>([])
  const { getApiConfiguration } = useAuthContext()

  const getSettingsInternal = async () => {
    var settingsApi = new SettingsApi(getApiConfiguration())
    var settings = await settingsApi.apiSettingsSystemGet(false)
    setAppSettings(settings)
  }

  const getTagsInternal = async () => {
    var tagsApi = new TagsApi(getApiConfiguration())
    var tags = await tagsApi.apiTagsGet()
    setTags(tags)
  }

  const getCountriesInternal = async () => {
    var settingsApi = new SettingsApi(getApiConfiguration())
    var countries = await settingsApi.apiSettingsCountriesGet()
    setCountries(countries)
  }

  const getRegionsInternal = async () => {
    var settingsApi = new SettingsApi(getApiConfiguration())
    var regions = await settingsApi.apiSettingsRegionsGet()
    setRegions(regions)
  }

  const getSetting = (key: string) => {
    var items = appSettings?.filter(as => as.name == key);
    if (items && items?.length > 0)
      return items[0].value;
    return null;
  }

  async function getInitialSetup(): Promise<void> {
    await getSettingsInternal()
    await getTagsInternal()
    await getCountriesInternal()
    await getRegionsInternal()
  }

  const { status } = useQuery(QUERIES.GLOBAL.INITIAL, getInitialSetup, { refetchOnWindowFocus: false });

  return (
    <GlobalContext.Provider value={{ getSetting, tags, countries, regions }}>
      {status === "error" && <p>Error fetching data</p>}
      {status === "loading" && <LoadingComponent />}
      {status === "success" && (
        <div>
          {children}
        </div>
      )}
    </GlobalContext.Provider>
  )
}

export { GlobalProvider, useGlobalContext }
