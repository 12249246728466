import { PageLink, PageTitle } from '../../../_metronic/layout/core'
import ListingLayout from '../../helpers/components/listing/PageContainer'
import SearchCol from '../../helpers/components/listing/searchCard/SearchCol'
import TableCard from '../../helpers/components/listing/tableCard/TableCard'
import { usersColumns } from '../user-management/users-list/table/columns/_columns'
import AnchorButton from '../../helpers/components/generic/AnchorButton'
import { SearchUsersRequest, useAuthContext, UsersApi } from '@pull/pull-groupm-csp-api'
import { QUERIES } from '../../Constants/Queries'
import { PagedResultInterface } from '../../modules/core/_models'
import { UserEditModalFormWrapper } from './users-list/user-edit-modal/UserEditModalFormWrapper'
import { UserProvider, useUserContext } from '../../modules/user/UserContext'
import { EditModalProvider, useEditModalContext } from '../../modules/core/functional/EditModalContext'
import { TableProvider, useTableContext } from '../../modules/core/functional/TableContext'
import { ToolbarContainer } from '../../../_metronic/layout/core/ToolbarContainer'


const usersBreadcrumbs: Array<PageLink> = [
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
  {
    title: 'Users',
    path: '/users',
    isSeparator: false,
    isActive: false,
  },

]

const UsersPage = () => {
  const { updateFilters } = useTableContext()
  const { getApiConfiguration } = useAuthContext()
  const { userFilters } = useUserContext()
  const { updateSelectedValue, showEditModal } = useEditModalContext()
  const usersApi = new UsersApi(getApiConfiguration())

  const getUsers = async (pageParam = 1, filters = {}) => {

    let response = await usersApi.apiUsersSearchPost({ ...filters, ...{ page: pageParam, pageSize: 10 } } as SearchUsersRequest);
    return response as PagedResultInterface;
  }
  const submitSearchHandler = (searchFields: any) => {
    updateFilters(searchFields)
  }

  const openUserModal = () => {
    updateSelectedValue("")
  }

  return (
    <>
    <ToolbarContainer>
      <PageTitle breadcrumbs={usersBreadcrumbs} title="Users"></PageTitle>
      </ToolbarContainer>
      <UserEditModalFormWrapper showModal={showEditModal} />
      <ListingLayout >
      <div className="d-flex flex-column flex-lg-row">
        <SearchCol filters={userFilters} submitSearch={submitSearchHandler} searchName="email" searchPlaceholder='Search by email' />
        <TableCard tableCols={usersColumns} getData={getUsers} query={QUERIES.USER.SEARCH}>
          <AnchorButton className="btn-info" action={openUserModal} >
            <>
              <span className="svg-icon svg-icon-2">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect opacity="0.5" x="11.364" y="20.364" width="16" height="2" rx="1" transform="rotate(-90 11.364 20.364)" fill="currentColor"></rect>
                  <rect x="4.36396" y="11.364" width="16" height="2" rx="1" fill="currentColor"></rect>
                </svg>
              </span>
              New User
            </>
          </AnchorButton>
        </TableCard>
        </div>
      </ListingLayout>

    </>
  )
}

const UserPageWrapper = () => {
  return (
    <UserProvider>
      <TableProvider>
        <EditModalProvider>
          <UsersPage />
        </EditModalProvider>
      </TableProvider>
    </UserProvider>
  )
}

export { UsersPage, UserPageWrapper } 
