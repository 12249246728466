/* eslint-disable jsx-a11y/anchor-is-valid */
import { UserInfo } from '@pull/pull-groupm-csp-api'
import clsx from 'clsx'
import { FC } from 'react'
import { getInitials } from '../../../modules/user/_models'
import { Link } from 'react-router-dom'


type Props = {
  user: UserInfo
  symbolClass?: string
}

const JudgeInfoCell: FC<Props> = ({ user, symbolClass = "symbol symbol-circle symbol-45px me-5" }) => {
  let initials = getInitials(user.firstName, user.lastName)
  return (
    <div className="d-flex align-items-center position-relative">
      <div className="symbol symbol-circle symbol-45px me-5">
        <span className={clsx(
            'symbol-label fs-3',
            `bg-light-info`,
            `text-info`,
            'fw-bold'
          )}>{initials}</span>
      </div>
      <div className="d-flex justify-content-start flex-column">
        <Link to={"/awards/judge/" + user.id} className="text-dark fw-bold fs-6 text-hover-info cover-link"> {user.displayName}</Link>
        <span className="text-muted fw-semibold text-gray-650 d-block fs-7">{user.email}</span>
      </div>
    </div>
  )
}

export { JudgeInfoCell }
