import { AwardSubmissionScoreInfo } from "@pull/pull-groupm-csp-api"
import clsx from "clsx"
import { FC } from "react"
import { ModalContainer } from "../../../helpers/components/generic/ModalContainer"
import { useEditModalContext } from "../../../modules/core/functional/EditModalContext"
import { getInitialsRegEx } from "../../../modules/user/_models"

type Props = {
	scores: AwardSubmissionScoreInfo[],
	caseStudyName: string,
	averageScore: number
}
const JudgeScores: FC<Props> = ({ scores, averageScore, caseStudyName }) => {
	const { updateSelectedValue } = useEditModalContext()
	console.log(scores)

	return (
		<ModalContainer id="kt_modal_user_scores">
			<div className="modal-header pb-0 border-0 justify-content-end">
				<div className="btn btn-sm btn-icon btn-active-color-primary" onClick={() => updateSelectedValue(null)} data-bs-dismiss="modal">
					<span className="svg-icon svg-icon-1">
						<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
							<rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1" transform="rotate(-45 6 17.3137)" fill="currentColor"></rect>
							<rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)" fill="currentColor"></rect>
						</svg>
					</span>
				</div>
			</div>
			<div className="modal-body scroll-y mx-5 mx-xl-18 pt-0 pb-15">
				<div className="text-center mb-13">
					<h1 className="mb-3">{caseStudyName}</h1>
					<div className="d-flex align-items-center justify-content-center">
						<div className="symbol symbol-35px symbol-circle me-4">
							<span className="symbol-label bg-info text-white fw-semibold">{averageScore}</span>
						</div>
						<div className="text-gray-650 mt-1 fw-semibold fs-4">Average score from {scores.length} judges</div>
					</div>
				</div>
				<div className="mb-10">
					<div className="mh-450px scroll-y me-n7 pe-7">
						{scores.map((judgeScore) => {
							const currentScore = judgeScore.score ? judgeScore.score : 0
							const initials = getInitialsRegEx(judgeScore.judgeName)
							const progress = {
								width: `${currentScore * 10}%`
							}
							return (
								<div key={judgeScore.id} className="row py-5 border-bottom border-gray-300 border-bottom-dashed">
									<div className="col-md-5 col-lg-4">
										<div className="d-flex align-items-center">
											<div className="symbol symbol-35px symbol-circle">
												<div
													className={clsx(
														'symbol-label fs-3',
														`bg-light-info`,
														`text-info`,
														'fw-bold'
													)}
												>
													{initials}
												</div>
											</div>
											<div className="ms-6">
												<a href="#" className="fs-5 fw-bold text-dark text-hover-info">{judgeScore.judgeName}</a>
												<div className="d-block text-muted fw-semibold text-gray-650">
													<span className="fs-7">{currentScore}/10</span>
													<div className="progress h-7px bg-brand-pink bg-opacity-50 mt-1">
														<div className="progress-bar bg-brand-pink" role={"progressbar"} style={progress} aria-valuenow={50} aria-valuemin={0} aria-valuemax={100}></div>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div className="col-md-7 col-lg-8">
										<div className="fs-6 pt-3 pt-md-0">
											<p className="mb-0">
												{judgeScore.comment}
											</p>
										</div>
									</div>
								</div>
							)
						}
						)
						}

					</div>
				</div>
			</div>
		</ModalContainer>
	)
}

export { JudgeScores }