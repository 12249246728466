export const getErrorFromResponse = async (response:Response):Promise<string|null> =>{
    if (response && response.status == 500 && response.headers) {

        var header = response.headers.get("content-type")

        if (header == "application/problem+json") {

            var r = await response.json();

            if (r.title)

                return r.title

        }
    }

    return null
}