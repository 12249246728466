import { AwardSubmissionInfo } from "@pull/pull-groupm-csp-api"

enum Tabs {
    Submissions,
    Judging,
    Deliberations,
    Upcoming
}

 enum AwardModalTypes {
    Submission,
    AwardSettings,
    JudgeScores
}

export type AwardEditModal = {
    type:AwardModalTypes,
    info:any
}

export type AwardDownloadSubmissionsModal = {
    awardID?: number,
    awardCategoryID: number,
    year?: number,
    fileName: string
}

export type AwardSubmissionWinnerModal = {
    awardSubmission?: AwardSubmissionInfo,
}

export {Tabs, AwardModalTypes}