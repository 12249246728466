export function formDataToFilterObject(formData: FormData) {
    let object: any = {};
    formData.forEach((value, key) => {
        //check if there is a valid value - if not don't bother adding it to the object.
        if (!value || value === "")
            return;
        //check if value is an integer if so make it an integer type
        var numVal = Number(value);
        if (!isNaN(numVal)) {
            //we don't want any non-matching IDs
            if (numVal === 0)
                return;
            
            addValue(object, key, numVal);
            //object[key] = numVal;
            return;
        }

        addValue(object, key, value);
    });
    return object;
}

function addValue(object: any, key: any, value: any) {
    // Reflect.has in favor of: object.hasOwnProperty(key)
    if (!Reflect.has(object, key)) {
        object[key] = value;
        return;
    }
    if (!Array.isArray(object[key])) {
        object[key] = [object[key]];
    }
    object[key].push(value);
}