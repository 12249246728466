import { CaseStudyInfo, AwardsApi, useAuthContext, AwardSubmissionInfo, AwardSubmissionScoreInfo } from '@pull/pull-groupm-csp-api'
import { FC, useEffect } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { MenuComponent } from '../../../../_metronic/assets/ts/components'
import { QUERIES } from '../../../Constants/Queries'
import { useEditModalContext } from '../../../modules/core/functional/EditModalContext'
import { AwardEditModal, AwardModalTypes } from '../model/_models'


type Props = {
    allScores: Array<AwardSubmissionScoreInfo>
    averageScore: number
    caseStudyName: string
}

const AverageScoreCell: FC<Props> = ({ averageScore, allScores, caseStudyName }) => {
    const { updateSelectedValue } = useEditModalContext();
    averageScore = averageScore ?? 0
    const progress = {
        width: `${averageScore * 10}%`
    }
    const showJudgeScores = () => {
        const modalObj: AwardEditModal = { type: AwardModalTypes.JudgeScores, info: { averageScore: averageScore, scores: allScores, caseStudyName: caseStudyName } }
        updateSelectedValue(modalObj)
    }

    useEffect(() => {
        MenuComponent.reinitialization()
    }, [])

    const getProgressBarClass = (score:number) => {
        if (score <= 4)
            return "bg-danger";
        else if (score >= 8)
            return "bg-info";
        else
            return "bg-brand-pink";
    }

    return (
        <a href="#" onClick={showJudgeScores} className="d-block text-muted fw-semibold text-gray-650 text-hover-info">
            <span className="fs-7">{averageScore}/10</span>
            <div className={'progress h-7px bg-opacity-50 mt-1 ' + getProgressBarClass(averageScore)}>
                <div className={'progress-bar ' + getProgressBarClass(averageScore)} role="progressbar" style={progress} aria-valuenow={50} aria-valuemin={0} aria-valuemax={100}></div>
            </div>
        </a>
    )
}

export { AverageScoreCell }