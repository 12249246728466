import { EmailApi, EmailTemplateInfo, useAuthContext } from "@pull/pull-groupm-csp-api"
import { useFormik } from "formik"
import { FC, useState } from "react"
import { useQueryClient } from "react-query"
import { QUERIES } from "../../../../Constants/Queries"
import * as Yup from 'yup'
import clsx from "clsx"
import { useNavigate } from "react-router-dom"
import { LoadingComponent } from "../../../../modules/core/LoadingComponent"
import { Success } from "../../../../helpers/functions/SwalHelpers"
import { getErrorFromResponse } from "../../../../helpers/functions/getErrorFromResponse"
import { ErrorBanner } from "../../../../helpers/components/generic/NotificationBanner"

type Props = {
    emailTemplate: EmailTemplateInfo
}

const editEmailTemplateSchema = Yup.object().shape({
    template: Yup.string()
        .required('Template is required')
})

const EmailTemplateForm: FC<Props> = ({ emailTemplate }) => {
    const { getApiConfiguration } = useAuthContext()
    const queryClient = useQueryClient()
    const [error, setError] = useState<string | null>(null)
    const navigate = useNavigate()

    const [emailTemplateForm] = useState({
        id: emailTemplate.id || 0,
        name: emailTemplate.name,
        displayName: emailTemplate.displayName,
        subject: emailTemplate.subject,
        template: emailTemplate.template || "",
    })
    const formik = useFormik({
        initialValues: emailTemplateForm,
        validationSchema: editEmailTemplateSchema,
        onSubmit: async (values, { setSubmitting }) => {
            setSubmitting(true)
            const emailApi = new EmailApi(getApiConfiguration())

            try {
                const response = await emailApi.apiEmailsTemplatesPost(values)
                setSubmitting(false)
                if (response?.id) {
                    queryClient.invalidateQueries([QUERIES.EMAIL_TEMPLATES.GET + emailTemplate.id])
                    queryClient.invalidateQueries([QUERIES.EMAIL_TEMPLATES.SEARCH])
                    Success(`The email template has been ${emailTemplate.id === 0 ? "created" : "updated"}`)
                    if(values.id === 0)
                         navigate(`/email-template/${response.id}`)
                }

            } catch (ex) {
                if (ex as Response) {
                    const errorMessage = await getErrorFromResponse(ex as Response)
                    setError(errorMessage)
                }
                else {
                    console.log(ex)
                }
            }
        },
    })

    const richTextChangeHandler = (copy: string) => {
        formik.setFieldValue("content", copy)
    }

    return (
        <div id="news-content">
            {formik.isSubmitting && <LoadingComponent />}
            <form id="news_content_form" className="form" onSubmit={formik.handleSubmit} noValidate>
                <div className="card-body border-top p-9">
                    {error &&
                        <ErrorBanner message={error} />
                    }
                    <div className="row mb-7">
                        <label className="col-lg-4 col-form-label fw-semibold fs-6" htmlFor="title">Title</label>
                        <div className="col-lg-8">
                            <input readOnly disabled {...formik.getFieldProps("displayName")}
                                type="text" id="displayName"
                                className='form-control form-control-solid' />
                        </div>
                    </div>
                    <div className="row mb-7 fv-row">
                        <label className="col-lg-4 col-form-label fw-semibold fs-6 required" htmlFor="subject">Subject</label>
                        <div className="col-lg-8">
                            <input
                                {...formik.getFieldProps("subject")}
                                className={clsx(
                                    'form-control form-control-solid',
                                    { 'is-invalid': formik.touched.subject && formik.errors.subject },
                                    {
                                        'is-valid': formik.touched.subject && !formik.errors.subject,
                                    }
                                )}
                            />
                            {formik.touched.subject && formik.errors.subject && (
                                <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block'>
                                        <span role='alert'>{formik.errors.subject}</span>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                   
                    <div className="row mb-0 fv-row">
                        <label className="col-lg-4 col-form-label fw-semibold fs-6 required" htmlFor="category_page_copy">Template</label>
                        <div className="col-lg-8">
                            <textarea
                                rows={40}
                                {...formik.getFieldProps("template")}
                                className={clsx(
                                    'form-control form-control-solid',
                                    { 'is-invalid': formik.touched.template && formik.errors.template },
                                    {
                                        'is-valid': formik.touched.template && !formik.errors.template,
                                    }
                                )}
                            />
                            {formik.touched.template && formik.errors.template && (
                                <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block'>
                                        <span role='alert'>{formik.errors.template}</span>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                    <br />
                    <div className="card-footer sticky bottom-0 d-flex justify-content-end py-6 px-9">
                        <button type="submit"
                            className="btn btn-info"
                            id="news_submit"
                            disabled={formik.isSubmitting || !formik.isValid || !formik.touched}
                        >
                            <span className='indicator-label'>Save Changes</span>
                            {formik.isSubmitting && (
                                <span className='indicator-progress'>
                                    Please wait...{' '}
                                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                </span>
                            )}
                        </button>
                    </div>
                </div>
            </form>

        </div>
    )
}


export { EmailTemplateForm }






