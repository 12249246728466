import { useAuthContext, NewsApi, NewsInfo, EmailTemplateInfo } from '@pull/pull-groupm-csp-api'
import { FC, useEffect } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { Link } from 'react-router-dom'
import { MenuComponent } from '../../../../_metronic/assets/ts/components'
import { QUERIES } from '../../../Constants/Queries'
import { Delete } from '../../../helpers/functions/SwalHelpers'

type Props = {
  item: EmailTemplateInfo
}

const EmailTemplatesActionCell: FC<Props> = ({ item }) => {

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])


  return (
    <div className="text-end">
      <Link to={`/email-templates/${item.id}`} className="btn btn-bg-light btn-color-gray-700 btn-active-color-info btn-sm px-4 me-2">Details</Link>
    </div>
  )
}

export default EmailTemplatesActionCell