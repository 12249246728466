import {Column} from 'react-table'
import { CountryInfo, RegionInfo, ScheduledEmailInfo, SettingInfo} from '@pull/pull-groupm-csp-api'
import { CustomHeader } from '../../../helpers/components/listing/tableCard/column/CustomHeader'
import CRActionCell from './CountryRegionActionCell'
import SettingActionCell from './SettingsActionCell'


const countryRegionColumns: ReadonlyArray<Column<CountryInfo|RegionInfo>> = [
    {
        Header: (props) => <CustomHeader tableProps={props} title='Name'  />,
        id: 'name',
        Cell: ({...props}) => <span className="text-dark fw-bold fs-6">{props.data[props.row.index].name}</span>,
      },
      {
        Header: (props) => (
          <CustomHeader tableProps={props} title='Created'  />
        ),
        id: 'created',
        Cell: ({...props}) => <span className="text-dark fw-bold d-block fs-6">{new Date(props.data[props.row.index].created).toLocaleDateString()}</span>,
      },
     { 
        Header: (props) => (
          <CustomHeader tableProps={props} title='Actions' className='text-end min-w-200px' />
        ),
         id: 'actions',
        Cell: ({...props}) => <CRActionCell row={props.data[props.row.index]} />,
       },
]

const settingsColumns: ReadonlyArray<Column<SettingInfo>> = [
  {
      Header: (props) => <CustomHeader tableProps={props} title='Name'  />,
      id: 'name',
      Cell: ({...props}) => <span className="text-dark fw-bold fs-6">{props.data[props.row.index].name}</span>,
    },
    {
      Header: (props) => <CustomHeader tableProps={props} title='Value'  />,
      id: 'value',
      Cell: ({...props}) => <span className="text-dark fw-bold fs-6">{props.data[props.row.index].value}</span>,
    },
   { 
      Header: (props) => (
        <CustomHeader tableProps={props} title='Actions' className='text-end min-w-200px' />
      ),
       id: 'actions',
      Cell: ({...props}) => <SettingActionCell row={props.data[props.row.index]} />,
     },
]


const emailQueueColumns: ReadonlyArray<Column<ScheduledEmailInfo>> = [
  {
      Header: (props) => <CustomHeader tableProps={props} title='To'  />,
      id: 'to',
      Cell: ({...props}) => <span className="text-dark fw-bold fs-6">{props.data[props.row.index].toAddresses}</span>,
    },
    {
      Header: (props) => <CustomHeader tableProps={props} title='Subject'  />,
      id: 'subject',
      Cell: ({...props}) => <span className="text-dark fw-bold fs-6">{props.data[props.row.index].subject}</span>,
    },
    {
      Header: (props) => <CustomHeader tableProps={props} title='Next Attempt Date'  />,
      id: 'nextAttemptDate',
      Cell: ({...props}) => <span className="text-dark fw-bold fs-6">{props.data[props.row.index].nextAttemptDate ? new Date(props.data[props.row.index].nextAttemptDate).toLocaleString() : "" }</span>,
    },
    {
      Header: (props) => <CustomHeader tableProps={props} title='Last Attempt Date'  />,
      id: 'lastAttemptDate',
      Cell: ({...props}) => <span className="text-dark fw-bold fs-6">{props.data[props.row.index].lastAttemptDate ? new Date(props.data[props.row.index].lastAttemptDate).toLocaleString() : "" }</span>,
    },
    // {
    //   Header: (props) => <CustomHeader tableProps={props} title='Last Attempt Error' className='w-200'  />,
    //   id: 'lastAttemptError',
    //   Cell: ({...props}) => <span className="text-dark fw-bold fs-6 w-200">{props.data[props.row.index].lastAttemptError}</span>,
    // },
    {
      Header: (props) => <CustomHeader tableProps={props} title='Attempt count' className='w-200'  />,
      id: 'attemptCount',
      Cell: ({...props}) => <div>
        <span className="text-dark fw-bold fs-6 w-200">{props.data[props.row.index].attemptCount}</span>
        <div className='d-none'>
          {props.data[props.row.index].lastAttemptError}
        </div>
        </div>,
    }
]

export {countryRegionColumns, settingsColumns, emailQueueColumns}