import { FC, useMemo } from "react"
import { Column, ColumnInstance, useTable,Row } from "react-table"
import { CustomHeaderColumn } from "./column/CustomHeaderColumn"
import { CustomRow } from "./row/CustomRow"

type Props = {
    cols : ReadonlyArray<Column<any>>
    data: Array<any>
}

const TableComponent:FC<Props> = ({cols, data}) =>{

    const columns = useMemo(() => cols, [cols])
    
    const {getTableProps, getTableBodyProps, headers, rows, prepareRow} = useTable({
        columns,
        data,
      })
    return (
        
                    <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4" {...getTableProps()}>
                        <thead>
                            <tr className="fw-bold text-gray-650">
                            {headers.map((column: ColumnInstance<any>) => (
                                <CustomHeaderColumn key={column.id} column={column} />
                            ))}
                            </tr>
                        </thead>
                        <tbody>
                        {rows.length > 0 ? (
              rows.map((row: Row<typeof data[0]>, i) => {
                prepareRow(row)
                return <CustomRow row={row} key={`row-${i}-${row.id}`} />
              })
            ) : (
              <tr>
                <td colSpan={7}>
                  <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                    No matching records found
                  </div>
                </td>
              </tr>
            )}
                        </tbody>
                    </table>
    )
}

export default TableComponent