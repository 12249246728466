/* eslint-disable jsx-a11y/anchor-is-valid */
import { UserInfo } from '@pull/pull-groupm-csp-api'
import clsx from 'clsx'
import { FC } from 'react'
import { toAbsoluteUrl } from '../../../../../../_metronic/helpers'
import { getInitials } from '../../../../../modules/user/_models'


type Props = {
  user: UserInfo
  symbolClass?:string
}

const UserInfoCell: FC<Props> = ({ user, symbolClass = "symbol-50px overflow-hidden me-3" }) => {
  let initials = getInitials(user.firstName, user.lastName)
  return (
    <div className='d-flex align-items-center'>
      <div className={clsx('symbol',symbolClass)}>
        <div
          className={clsx(
            'symbol-label fs-3',
            `bg-light-info`,
            `text-info`,
            'fw-bold'
          )}
        >
          {initials}
        </div>
      </div>
      <div className='d-flex flex-column'>
        {user.displayName?.trim() !== user.email?.trim() &&
          <span className='text-gray-800 text-hover-primary mb-1'>
            {user.displayName}
          </span>
        }
        <span>{user.email}</span>
      </div>
    </div>
  )
}

export { UserInfoCell }
