import { FC, ReactNode, useEffect, useState, useMemo, useContext } from "react"
import { KTCard, KTCardBody } from "../../../../../_metronic/helpers"
import {useTable, Column, ColumnInstance, Row} from 'react-table'
import { CustomHeaderColumn } from "./column/CustomHeaderColumn"
import { TypeOf } from "yup"
import { CustomRow } from "./row/CustomRow"
import {useInfiniteQuery, useMutation, useQuery, useQueryClient} from 'react-query'
import {QUERIES} from '../../../../Constants/Queries'
import { memoryUsage } from "process"
import { LoadingComponent } from "../../../../modules/core/LoadingComponent"
import { useLoading } from "../../../../modules/core/LoadingProvider"
import { PagedResultInterface } from "../../../../modules/core/_models"
import { Table } from "react-bootstrap"
import TableComponent from "./TableComponent"



export type TableModel = {
    children?: ReactNode,
    tableCols: ReadonlyArray<Column<any>>
    getData: () => Promise<any[] | undefined> 
    query: string
}

//create a context for Tables, to work in the filtering and loadmore

const TableCard: FC<TableModel> = ({children, tableCols, query, getData}) => {
    const [data, setData] = useState<any[]>([])
    const {isLoading,isFetching, data:fullData,error}  = useQuery([query], () => getData(), { refetchOnWindowFocus: false })
    if(error){
        console.log(error)
    }
    useEffect(() => {
            setData(fullData ?? [])
        },[fullData])

    if(isLoading || isFetching){
        return <LoadingComponent />
    }

    return (
        <KTCard stretch="xl-stretch" className="mb-5 mb-xl-8">
            <div className="card-header border-0 pt-8">
                <h3 className="card-title align-items-start flex-column">
                    <span className="card-label fw-bold fs-2 mb-1">Results</span>
                    <span className="text-gray-650 mt-1 fw-semibold fs-6">{fullData?.length??0} results found</span>
                </h3>
                <div className="card-toolbar flex-row-fluid justify-content-end gap-5">
                    {children}
                </div>
            </div>
            <KTCardBody className="py-3">
            <div className="table-responsive">
                <TableComponent cols={tableCols} data={data} />
                </div>
            </KTCardBody>
        </KTCard>
    )
}

export default TableCard