/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import {FC} from 'react'
import {Routes, Route, BrowserRouter, Navigate} from 'react-router-dom'
import {ErrorsPage} from '../modules/errors/ErrorsPage'
import {App} from '../App'
import { DashboardWrapper } from '../pages/dashboard/DashboardPage'
import {UserPageWrapper} from '../pages/user-management/UsersPage'
import { Role, useAuthContext} from '@pull/pull-groupm-csp-api'
import { Logout } from '../modules/auth'
import CaseStudyDetailPage from '../pages/case-studies/CaseStudyDetailPage'
import {CaseStudiesPageWrapper } from '../pages/case-studies/CaseStudiesPage'
import { TagsPageWrapper } from '../pages/tags/TagsPage'
import {  TagListPageWrapper } from '../pages/tags/TagListPage'
import { CountryPageWrapper, RegionPageWrapper } from '../pages/settings/countries-regions/CountryRegionsListPage'
import { SettingsPageWrapper } from '../pages/settings/setting-list/SettingListPage'
import { NewsListPageWrapper } from '../pages/content/news/NewsListPage'
import { NewsDetailPage } from '../pages/content/news/NewsDetailPage'
import { Error404 } from '../modules/errors/components/Error404'
import { Error500 } from '../modules/errors/components/Error500'
import { AwardCategoryPageWrapper } from '../pages/awards/AwardsCategoryPage'
import { LayoutSplashScreen } from '../../_metronic/layout/core'
import { LoadingComponent } from '../modules/core/LoadingComponent'
import { AwardCatDetailPage } from '../pages/awards/AwardCategoryDetailPage'
import { AwardDetailPage } from '../pages/awards/AwardDetailPage'
import { ContentPageDetail } from '../pages/content/contentPages/ContentPageDetail'
import { ContentPageListPageWrapper } from '../pages/content/contentPages/ContentPageList'
import JudgeActivity from '../pages/awards/judgeActivity/JudgeActivityListPage'
import { AnalyticsPage } from '../pages/analytics/AnalyticsPage'
import { MaintenanceWrapper } from '../pages/general/Maintenance'
import { EmailTemplatesListPage, EmailTemplatesPageWrapper } from '../pages/content/emailTemplates/EmailTemplatesListPage'
import { EmailTemplateDetailPage } from '../pages/content/emailTemplates/EmailTemplateDetailPage'
import { EmailQueuePageWrapper } from '../pages/settings/email-queue/EmailQueueListPage'
import JudgeActivityListPage from '../pages/awards/judgeActivity/JudgeActivityListPage'
import JudgeActivityListPageWrapper from '../pages/awards/judgeActivity/JudgeActivityListPage'
import JudgeDetailPage from '../pages/awards/judgeActivity/JudgeDetailPage'
//import { useConfigContext } from '../modules/core/ConfigContext'
/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
const {PUBLIC_URL} = process.env

const AppRoutes: FC = () => {
  const {currentSession: session} = useAuthContext()
  //const {config, loading} =  useConfigContext()
  
  const cspBase = window._env_?.CSP_URL ?? 'https://localhost:3000'

   if(!session || session.role !== Role.Administrator){
    const currentUrl = window.location.href.indexOf("/logout") >= 0 ? "":`?returnUrl=${window.location.href}`
     window.location.href = `${cspBase}/auth${currentUrl}`
  }
  return (
    <>
    {session && 
    <BrowserRouter basename={PUBLIC_URL}>
      <Routes>
        <Route element={<App />}>
          <Route path='logout'  element={<Logout />}/>
            <>
              <Route index element={<DashboardWrapper />} />
              <Route path="case-studies" element={<CaseStudiesPageWrapper />} />
              <Route path="case-studies/:id" element = {<CaseStudyDetailPage />} />
              <Route path="awards/judge-activity" element={<JudgeActivityListPageWrapper />} />
              <Route path="awards/judge/:id" element={<JudgeDetailPage />} />
              <Route path="awards/:id" element={<AwardCategoryPageWrapper />} />
              <Route path="awards/:id/details" element={<AwardCatDetailPage />} />
              <Route path="awards/:id/:awardId" element={<AwardDetailPage />} />
              <Route path="tags" element={<TagsPageWrapper />} />
              <Route path="tags/:id" element={<TagListPageWrapper />} />1
              <Route path="countries" element={<CountryPageWrapper />} />
              <Route path="regions" element={<RegionPageWrapper />} />
              <Route path="news" element={<NewsListPageWrapper />} />
              <Route path="news/:id" element={<NewsDetailPage />} />
              <Route path="pages" element={<ContentPageListPageWrapper />} />
              <Route path="pages/:id" element={<ContentPageDetail />} />
              <Route path="settings" element={<SettingsPageWrapper />} />
              <Route path="email-templates" element={<EmailTemplatesPageWrapper />} />
              <Route path="email-templates/:id" element={<EmailTemplateDetailPage />} />
              <Route path="email-queue" element={<EmailQueuePageWrapper />} />
              
              <Route path="users" element={<UserPageWrapper />} />
              <Route path="analytics" element={<AnalyticsPage />} />
              <Route path='404' element={<Error404 />} />
              <Route path='500' element={<Error500 />} />
            </>
        </Route>
        <Route element={<App />}>
          <Route path='maintenance'  element={<MaintenanceWrapper />}/>
        </Route>
        
      </Routes>
    </BrowserRouter>
    }
    </>
  )
}

export {AppRoutes}
