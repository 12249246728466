import { createContext, FC, useCallback, useContext, useState } from "react"
import { WithChildren } from "../../../_metronic/helpers"
import { Filter, FilterType } from "../../helpers/types/Filter"

type TagContextProps = {
    tagParentID: number| undefined
    updateTagParentID: (id:number) => void
    tagFilters: Filter[]
}

const initTagContextPropsState = {
    tagParentID: -1,
    updateTagParentID: (id:number) => {},
    showTagValueModal:false,
    tagFilters:[],
    children:<></>
}

const TagContext = createContext<TagContextProps>(initTagContextPropsState)

const useTagContext = () =>{
    return useContext(TagContext)
}


const TagProvider:FC<WithChildren> = ({children}) =>{
    const [tagParentID, setTagParentID] = useState<number>()



    const updateTagParentID = useCallback((id:number) => {
        setTagParentID(id);
    },[])
    const tagFilters:Filter[] = [
        {
            title:"Only Active",
            name:"activeOnly",
            type: FilterType.Checkbox,
            options: [{text:"Show only Active", isSelected:false}]
          }
    ]

    return ( 
        <TagContext.Provider value={{tagParentID,updateTagParentID,tagFilters}} >
            {children}
        </TagContext.Provider>
    )
}
export {TagProvider,useTagContext}