import { AwardInfo, AwardInfoStatus } from "@pull/pull-groupm-csp-api"
import { FC, ReactNode } from "react"
import { useEditModalContext } from "../../../modules/core/functional/EditModalContext"
import { AwardEditModal, AwardModalTypes } from "../model/_models"

type Props = {
    award: AwardInfo,
    awardCatId: number
    className:string
    children:ReactNode
}


const AwardSettingsButton: FC<Props> = ({ award, awardCatId, className, children }) => {
    const { updateSelectedValue } = useEditModalContext()

    const openAwardSettings = () => {
        const awardSettings = {
            id: award.id,
            name: award.name,
            awardCategoryID: awardCatId,
            submissionOpeningDate: award.submissionOpeningDate,
            submissionClosingDate: award.submissionClosingDate,
            judgingOpeningDate: award.judgingOpeningDate,
            judgingClosingDate: award.judgingClosingDate,
            judgingNotificationsDate: award.judgingNotificationsDate,
            isInSubmissions: award.status === AwardInfoStatus.Submissions,
            isInJudging: award.status === AwardInfoStatus.Judging,
            isClosed: award.status === AwardInfoStatus.Closed
        }
        const modal: AwardEditModal = {
            type: AwardModalTypes.AwardSettings,
            info: awardSettings
        }
        updateSelectedValue(modal)
    }

    return (
        <button onClick={ openAwardSettings} type="button" className={className} data-bs-toggle="modal" data-bs-target="#kt_modal_submissions_edit_award_details">
            {children}
        </button>
    )
}

export { AwardSettingsButton}