
import { useLoading } from './LoadingProvider';
import loadingAnimation from '../../assets/images/loading.svg'

const LoadingComponent = () => {

  const { loading } = useLoading()

  return (
    (loading ?
      <div className="loading-screen">
        <div className="inner">
          <img src={loadingAnimation} />
        </div>

      </div> : <div></div>)
  )
}

export { LoadingComponent }