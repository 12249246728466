// import {useIntl} from 'react-intl'
import { AnalyticsApi, AnalyticsDataItemColumnInfo, AnalyticsDataItemInfo, Timeframe, useAuthContext } from '@pull/pull-groupm-csp-api';
import { useQuery } from 'react-query';
import { PageLink, PageTitle } from '../../../_metronic/layout/core'
import { ToolbarContainer } from '../../../_metronic/layout/core/ToolbarContainer'
import Chart from "react-apexcharts";
import PageContainer from '../../helpers/components/listing/PageContainer'
import cotmIcon from '../../assets/images/graphics/COTM-graphic-2.svg'
import pcaIcon from '../../assets/images/graphics/PCA-graphic.svg'
import { KTUtil } from '../../../_metronic/assets/ts';
import { useState } from 'react';
import { ApexOptions } from 'apexcharts';
import { getAnalyticColumnValue, getAnalyticColumnValueFromPrimaryResult, getNumberWithCommas } from '../../helpers/functions/analyticsHelper';
import { Link } from 'react-router-dom';
import { LoadingComponent } from '../../modules/core/LoadingComponent';

const MaintenanceWrapper = () => {

  const { getApiConfiguration } = useAuthContext()
 
  return (
    <>
      <ToolbarContainer>
        <PageTitle breadcrumbs={[]} title="Dashboard" hideBreadcrumbs={true}></PageTitle>
      </ToolbarContainer>
      <PageContainer>
          <h1>Nope!</h1>
      </PageContainer>
    </>
  )
}

export { MaintenanceWrapper }
