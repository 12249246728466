import { FC, useState } from 'react'

type Props =  {
    published: any
}


const CaseStudyStatusCell: FC<Props> = ({published}) =>{
    //const publishDate = new Date(published)

    return(
        <>
        {published
        ?  <span className="badge badge-light-info">Published</span>
        : <span className="badge badge-light-warning">Unpublished</span>
        }
        </>
    )
}

export {CaseStudyStatusCell}