
import { PageLink, PageTitle } from '../../../../_metronic/layout/core'
import ListingLayout from '../../../helpers/components/listing/PageContainer'
import AnchorButton from '../../../helpers/components/generic/AnchorButton'
import {QUERIES} from '../../../Constants/Queries'
import {  settingsColumns } from '../columns/_columns'
import TableList from '../../../helpers/components/listing/tableCard/TableList'
import { EditModalProvider, useEditModalContext } from '../../../modules/core/functional/EditModalContext'
import { SettingEditModalFormWrapper } from './edit-modal/SettingEditModalWrapper'
import { SettingsApi, useAuthContext } from '@pull/pull-groupm-csp-api'
import { ToolbarContainer } from '../../../../_metronic/layout/core/ToolbarContainer'

const settingsBreadcrumbs: Array<PageLink> = [
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
    {
      title: 'Settings',
      path: '/settings',
      isSeparator: false,
      isActive: false,
    },
  
  ]

const SettingListPage = () => {
  const { getApiConfiguration } = useAuthContext()
  const settingsApi = new SettingsApi(getApiConfiguration())
  const {updateSelectedValue, showEditModal} = useEditModalContext()

  const getData = async () => {
    let response = await settingsApi.apiSettingsSystemGet(true)
    return response;
  }
  const openUserModal = () => {
    updateSelectedValue({id:0})
  }
//need to add the modal and see if we can update other pages to use the dynamic editmodal context
  return (
    <>
    <ToolbarContainer>
      <PageTitle breadcrumbs={settingsBreadcrumbs} title="Settings"></PageTitle>
      </ToolbarContainer>
      <SettingEditModalFormWrapper showModal={showEditModal} />
      <ListingLayout >
      <div className="d-flex flex-column flex-lg-row">
        <TableList tableCols={settingsColumns} getData={getData} query={QUERIES.GLOBAL.GET}>
          <AnchorButton className="btn-info" action={openUserModal} >
            <>
              <span className="svg-icon svg-icon-2">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect opacity="0.5" x="11.364" y="20.364" width="16" height="2" rx="1" transform="rotate(-90 11.364 20.364)" fill="currentColor"></rect>
                  <rect x="4.36396" y="11.364" width="16" height="2" rx="1" fill="currentColor"></rect>
                </svg>
              </span>
              New
            </>
          </AnchorButton>
        </TableList>
        </div>
      </ListingLayout>

    </>
  )
}

const SettingsPageWrapper = () => {
  return (
     <EditModalProvider>
       <SettingListPage />
     </EditModalProvider>
   )
 }

export {SettingListPage, SettingsPageWrapper} 
