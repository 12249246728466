import { FC, ReactNode, useEffect, useState, useRef } from "react"
import { KTCard, KTCardBody } from "../../../../../_metronic/helpers"
import { Column } from 'react-table'
import { useQuery } from 'react-query'
import { PagedResultInterface } from "../../../../modules/core/_models"
import { useTableContext } from "../../../../modules/core/functional/TableContext"
import TableBody from "./TableBody"

export type TableModel = {
    children?: ReactNode,
    tableCols: ReadonlyArray<Column<any>>
    getData: (pageParam: number, filters: any) => Promise<PagedResultInterface | undefined>
    downloadData?: (filters: any) => Promise<Blob>
    downloadTitle?: string
    downloadName?: string
    query: string
}

const TableCard: FC<TableModel> = ({ children, tableCols, query, getData, downloadData, downloadTitle, downloadName }) => {
    const [data, setData] = useState<any[]>([])
    const [fileUrl, setFileUrl] = useState("#")
    const { currentPage, currentFilters } = useTableContext()
    const ref = useRef<HTMLAnchorElement | null>(null)

    const { isLoading, isFetching, data: fullData, error } = useQuery([query, currentPage, JSON.stringify(currentFilters)], () => getData(currentPage, currentFilters), { keepPreviousData: true, refetchOnWindowFocus: false })

    if (error) {
        console.log(error)
    }

    async function downloadTableData() {

        debugger;
        if (!downloadData)
            return;

        var data = await downloadData(currentFilters);

        if (data.size != 0) {
            const url = URL.createObjectURL(new Blob([data]))
            setFileUrl(url);
            setTimeout(() => {
                ref.current?.click();
            }, 300)
        }
    }

    useEffect(() => {
        if (currentPage > 1 && fullData?.results != undefined) {
            setData((prevState) => { return [...prevState, ...fullData?.results ?? []] });
        }
        else
            setData(fullData?.results ?? [])

    }, [fullData?.results])

    return (
        <KTCard stretch="xl-stretch" className="mb-5 mb-xl-8">
            <div className="card-header border-0 pt-8">
                <h3 className="card-title align-items-start flex-column">
                    <span className="card-label fw-bold fs-2 mb-1">Results</span>
                    <span className="text-gray-650 mt-1 fw-semibold fs-6">{fullData?.totalCount} results found</span>
                </h3>
                <div className="card-toolbar flex-row-fluid justify-content-end gap-5">
                    {children}
                </div>
            </div>
            <KTCardBody className="py-3">
                <TableBody data={data} tableCols={tableCols} showLoading={isLoading || isFetching} pageSize={fullData?.pageSize ?? 10} totalCount={fullData?.totalCount ?? 0} />
            </KTCardBody>
            {downloadTitle && downloadData !== undefined ?
                <div className="card-footer sticky bottom-0 d-flex justify-content-between gap-5">
                    <button type="button" onClick={downloadTableData} className="btn btn-sm btn-bg-light btn-color-gray-700 btn-active-color-info" data-bs-toggle="modal" data-bs-target="#kt_modal_download">
                        <span className="svg-icon svg-icon-2">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M21 22H3C2.4 22 2 21.6 2 21C2 20.4 2.4 20 3 20H21C21.6 20 22 20.4 22 21C22 21.6 21.6 22 21 22ZM13 13.4V3C13 2.4 12.6 2 12 2C11.4 2 11 2.4 11 3V13.4H13Z" fill="currentColor"></path>
                                <path opacity="0.3" d="M7 13.4H17L12.7 17.7C12.3 18.1 11.7 18.1 11.3 17.7L7 13.4Z" fill="currentColor"></path>
                            </svg>
                        </span>
                        {downloadTitle}
                    </button>
                </div> : ""}
            <a href={fileUrl} download={downloadName} className="hidden" ref={ref} ></a>
        </KTCard>
    )
}

export default TableCard