import { AddUpdateTagValueRequest, TagsApi, TagValueInfo, useAuthContext } from "@pull/pull-groupm-csp-api"
import { FC, useEffect } from "react"
import { useMutation, useQueryClient } from "react-query"
import { Value } from "sass"
import { MenuComponent } from "../../../../_metronic/assets/ts/components"
import { QUERIES } from "../../../Constants/Queries"
import { Delete } from "../../../helpers/functions/SwalHelpers"
import { useEditModalContext } from "../../../modules/core/functional/EditModalContext"
import { useTagContext } from "../../../modules/tag/TagContext"

type Props = {
  tag: TagValueInfo
}

const TagValueActionCell: FC<Props> = ({ tag }) => {
  const { getApiConfiguration } = useAuthContext();
  const { tagParentID } = useTagContext();
  const { updateSelectedValue } = useEditModalContext();

  const apiConfig = getApiConfiguration();
  const tagApi = new TagsApi(apiConfig);

  const queryClient = useQueryClient()

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  const enableTagValue = useMutation(() => tagApi.apiTagsValuePost({ id: tag.id, tagID: tagParentID, value: tag.value, enabled: !tag.enabled }), {
    onSuccess: () => {
      queryClient.invalidateQueries([QUERIES.GLOBAL.INITIAL])
      queryClient.invalidateQueries([QUERIES.TAG.SEARCH + tagParentID])
    }
  })

  const editItem = () => {
    updateSelectedValue(tag)
  }

  const deleteOnClick = () => {
      Delete("tag value", "This will also update all case studies using this tag value")
      .then(async (result) => {
        if (result.isConfirmed) {
          await deleteItem.mutateAsync()
        }
      })
  }


  const deleteItem = useMutation(() => tagApi.apiTagsValueIdDelete(tag.id ?? 0), {
    // 💡 response of the mutation is passed to onSuccess
    onSuccess: () => {
      // ✅ update detail view directly
      queryClient.invalidateQueries([QUERIES.GLOBAL.INITIAL])
      queryClient.invalidateQueries([QUERIES.TAG.SEARCH + tagParentID])
    },
  })
  return (
    <div className="d-flex ms-auto justify-content-end">
      <div className="form-check form-switch form-check-custom form-check-solid me-8">
        <input onChange={async () => await enableTagValue.mutateAsync()} className="form-check-input h-20px w-30px" type="checkbox" value="true" name="activeswitch" checked={tag.enabled} />
        <label className="form-check-label" htmlFor="activeswitch">
          Active
        </label>
      </div>
      <button onClick={editItem} type="button" className="btn btn-bg-light btn-color-gray-700 btn-active-color-info btn-sm px-4 me-2">Edit</button>
      <button onClick={deleteOnClick} type="button" className="btn btn-bg-light btn-color-gray-700 btn-active-color-danger btn-sm px-4">Delete</button>
    </div>
  )
}

export default TagValueActionCell