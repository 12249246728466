import { AddUpdateCaseStudyRequest, CaseStudiesApi, CaseStudyInfo, useAuthContext } from '@pull/pull-groupm-csp-api'
import { Field, FieldArray, useFormik } from 'formik'
import moment from 'moment'
import { FC, useState } from 'react'
import { useQueryClient } from 'react-query'
import { KTCard, KTCardBody } from '../../../../_metronic/helpers'
import { QUERIES } from '../../../Constants/Queries'
import { ErrorBanner, NotificationBanner } from '../../../helpers/components/generic/NotificationBanner'
import { getErrorFromResponse } from '../../../helpers/functions/getErrorFromResponse'
import { Success } from '../../../helpers/functions/SwalHelpers'

type Props = {
    caseStudy: CaseStudyInfo
}

const CaseStudyDetailSettings: FC<Props> = ({ caseStudy }) => {
    const [settings] = useState({ id: caseStudy.id, featured: caseStudy.featured, published: caseStudy.published ? true : false, externalAwards: caseStudy.externalAwards })
    const [error, setError] = useState<string | null>(null)
    const { getApiConfiguration } = useAuthContext()
    const queryClient = useQueryClient()
    const formik = useFormik({
        initialValues: settings,
        onSubmit: async (values, { setSubmitting }) => {
            setSubmitting(true)

            values.externalAwards?.forEach(externalAward => {
                if(!externalAward.awardDate){
                    externalAward.awardDate = undefined;
                }
            });

            const caseStudiesApi = new CaseStudiesApi(getApiConfiguration())
            const updateCaseStudy: AddUpdateCaseStudyRequest = { id: values.id, adminFields: { featured: values.featured, published: values.published}, externalAwards: values.externalAwards };
            try {
                const result = await caseStudiesApi.apiCaseStudiesPost(updateCaseStudy)
                if (result?.id) {
                    queryClient.invalidateQueries([QUERIES.CASE_STUDIES.GET, caseStudy.id])
                    queryClient.invalidateQueries([QUERIES.CASE_STUDIES.SEARCH])
                    Success("The case study was successfully updated!")
                }
            } catch (ex) {
                if (ex as Response) {
                    const errorMessage = await getErrorFromResponse(ex as Response)
                    setError(errorMessage)
                }
                else {
                    console.log(ex)
                }
            }
        }
    })

    return (
        <KTCard className="mb-5 mb-xl-10">
            <div className="card-header border-0">
                <div className="card-title m-0">
                    <h3 className="fw-bold m-0">Settings</h3>
                </div>
            </div>
            <div id="case-study-settings">
                <form id="case-study-settings_form" className="form" onSubmit={formik.handleSubmit} noValidate>
                    <KTCardBody className="border-top p-9">
                        <NotificationBanner message="Control visibility of this case study on the main web site" />
                        {error &&
                            <ErrorBanner message={error} />
                        }
                        <div className="row mb-6">
                            <label className="col-lg-4 col-form-label fw-semibold fs-6">Featured</label>
                            <div className="col-lg-8 d-flex align-items-center">
                                <div className="form-check form-check-custom form-check-solid form-switch fv-row">
                                    <input className="form-check-input w-45px h-30px"
                                        {...formik.getFieldProps('featured')}
                                        type="checkbox"
                                        id="featured"
                                        checked={formik.values.featured}
                                        disabled={formik.isSubmitting} />
                                    <label className="form-check-label" >Featured on home page</label>
                                </div>
                            </div>
                        </div>
                        <div className="row mb-6">
                            <label className="col-lg-4 col-form-label fw-semibold fs-6">Published</label>
                            <div className="col-lg-8 d-flex align-items-center">
                                <div className="form-check form-check-custom form-check-solid form-switch fv-row">
                                    <input className="form-check-input w-45px h-30px"
                                        {...formik.getFieldProps('published')}
                                        type="checkbox"
                                        id="published"
                                        checked={formik.values.published}
                                        disabled={formik.isSubmitting} />
                                    <label className="form-check-label" >Visible to users</label>
                                </div>
                            </div>
                        </div>
                        <div className="row mb-0">
                            <label className="col-lg-4 col-form-label fw-semibold fs-6">External awards</label>
                            <div className="col-lg-8">
                                {Array.from(Array(3).keys()).map(index => {
                                    return <div className="row mb-7">
                                        <div className="col-lg-6">
                                            <input {...formik.getFieldProps(`externalAwards[${index}].awardName`)} type="text" className="form-control form-control-solid" placeholder={`External award name #${index + 1}`} />
                                        </div>
                                        <div className="col-lg-3">
                                            <input type="date" className="form-control form-control-solid"
                                                    name={`externalAwards[${index}].awardDate`}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    defaultValue={formik.values?.externalAwards && (index + 1 <= formik.values.externalAwards.length) ? moment(formik.values?.externalAwards[index].awardDate).format("YYYY-MM-DD") : ""} />
                                        </div>
                                    </div>
                                })}
                            </div>
                        </div>
                    </KTCardBody>
                    <div className="card-footer sticky bottom-0 d-flex justify-content-end py-6 px-9">
                        <button type="submit" className="btn btn-info" id="case-study-settings_submit">Save Changes</button>
                    </div>
                </form>
            </div>
        </KTCard>
    )
}

export default CaseStudyDetailSettings