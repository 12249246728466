import { FC, useEffect } from 'react'
import { useAuthContext } from '@pull/pull-groupm-csp-api'
import { getInitials } from '../../../../app/modules/user/_models'
import clsx from 'clsx'
import { MenuComponent } from '../../../assets/ts/components'
import { Link } from 'react-router-dom'

const HeaderUserMenu: FC = () => {
  const { currentSession } = useAuthContext()
  const initials = getInitials(currentSession?.firstName, currentSession?.lastName)

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  return (
    <div className="d-flex align-items-center ms-1 ms-lg-3" id="kt_header_user_menu_toggle">
      <div className="btn btn-icon btn-active-light-primary btn btn-icon btn-active-light-primary btn-custom w-30px h-30px w-md-40px h-md-40px"
        data-kt-menu-trigger="click"
        data-kt-menu-attach="parent"
        data-kt-menu-placement="bottom-end">
        <div className="symbol symbol-40px">
          <div className={clsx('symbol-label fs-3', `bg-light-info`, `text-info`, 'fw-bold')}>
            {initials}
          </div></div>
      </div>
      <div className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-900 menu-state-bg menu-state-color fw-semibold py-4 w-275px" data-kt-menu="true">
        <div className="menu-item px-3">
          <div className="menu-content d-flex align-items-center px-3">
            <div className="symbol symbol-50px me-5">
              <div
                className={clsx('symbol-label fs-3', `bg-light-info`, `text-info`, 'fw-bold')}>
                {initials}
              </div>
            </div>
            <div className="d-flex flex-column">
              <div className="fw-bold d-flex align-items-center fs-5">{`${currentSession?.firstName} ${currentSession?.lastName}`}</div>
              <span className="fw-semibold text-gray-650 fs-7">{currentSession?.role}</span>
            </div>
          </div>
        </div>
        <div className="separator my-2"></div>
        <div className="menu-item px-5">
          <a href={window._env_?.CSP_URL} target="_blank" className="menu-link px-5">Case Study Portal</a>
        </div>
        <div className="menu-item px-5">
          <Link to="/logout" className="menu-link px-5">Log out</Link>
        </div>
      </div>
    </div>
  )
}

export { HeaderUserMenu }
