import { AwardsApi, useAuthContext, Picture, AwardInfo, SearchAwardSubmissionsRequest } from "@pull/pull-groupm-csp-api"
import clsx from "clsx"
import { FC, SetStateAction, useState } from "react"
import { useQuery } from "react-query"
import { Link } from "react-router-dom"
import { KTCard, KTCardBody } from "../../../../_metronic/helpers"
import { QUERIES } from "../../../Constants/Queries"
import { DownloadAnchor } from "../../../helpers/components/generic/DownloadAnchor"
import { NotificationBanner } from "../../../helpers/components/generic/NotificationBanner"
import { LoadingComponent } from "../../../modules/core/LoadingComponent"
import { DownloadSubmissionsButton } from "../buttons/DownloadSubmissionsButton"

type Props = {
    categoryId: number
}

const AwardHistoryCard: FC<Props> = ({ categoryId }) => {
    const { getApiConfiguration } = useAuthContext()
    const awardsApi = new AwardsApi(getApiConfiguration())
    const currentYear = (new Date()).getFullYear()
    const [selectedYear, setSelectedYear] = useState(currentYear)
    const yearRange = currentYear - 2013 + 1
    const yearOptions = Array.from(Array(yearRange).keys()).map(y => { return y + 2013 as number }).reverse()

    const { isLoading, data, isFetching } = useQuery([QUERIES.AWARDS.SEARCH + categoryId, "history", selectedYear],
        () => awardsApi.apiAwardsAwardsSearchPost({ selectedAwardCategories: [categoryId], year: selectedYear, page: 1, pageSize: 24 }),
        { refetchOnWindowFocus: false })

    const yearChangeHandler = (e: any) => {
        setSelectedYear(e.target.value)
    }
    const getSubmissionsCount = (awards: AwardInfo[]) => {
        let count = 0
        for (let i = 0; i < awards.length; i++) {
            count += awards[i] ? awards[i].submissions?.length ?? 0 : 0
        }
        return count
    }

    const totCount = data?.totalCount ?? 0
    return (
        <KTCard>
            <div className="card-header border-0 pt-8">
                <h3 className="card-title align-items-start flex-column">
                    <span className="card-label fw-bold fs-2 mb-1">Award History</span>
                    <span className="text-gray-650 mt-1 fw-semibold fs-6">{selectedYear} - {data?.results?.length ?? 0} awards this year - {getSubmissionsCount(data?.results ?? [])} submissions</span>
                </h3>
                <div className="card-toolbar flex-row-fluid justify-content-end gap-5">
                    <div className="d-flex align-items-center fw-bold">
                        <div className="text-gray-650 fs-7 me-5 flex-shrink-0">Showing year</div>
                        <select onChange={yearChangeHandler} defaultValue={selectedYear} className="form-select w-100px">
                            {yearOptions.map((year) => {
                                return (<option key={year} value={year}>{year}</option>)
                            })}
                        </select>
                    </div>
                </div>
            </div>
            <KTCardBody>

                <div className="row gx-9 gy-6 pb-10">

                    {data?.results?.map((award) => {
                        const winner = award.winner?.caseStudy ?? null
                        return (
                            <>
                                {winner ?

                                    <div className="col-md-6 col-xl-4">
                                        <div className="card border h-xl-100 flex-row flex-stack flex-wrap p-6">
                                            <span className="text-dark fw-bold fs-4 mb-3 w-100">{award.name}</span>
                                            <div className="d-flex align-items-center gap-5 w-100">
                                                <div className="symbol symbol-50px symbol-md-70px">
                                                    <Picture width={100} height={100} className="" url={winner.images ? winner.images[0].url : undefined} />
                                                </div>
                                                <div className="d-flex align-items-start flex-column ">
                                                    <span className="badge badge-light-info mb-2">Winner</span>
                                                    <span className="fs-6 fw-semibold text-gray-650">{winner.title}</span>
                                                </div>
                                                <div className="d-flex align-items-center py-2 ms-auto">
                                                    <Link to={`/awards/${categoryId}/${award.id}`} className="btn btn-sm btn-bg-light btn-color-gray-700 btn-active-light-info">View</Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    :
                                    <div className="col-md-6 col-xl-4">
                                        <div className="card border h-xl-100 flex-row flex-stack flex-wrap p-6">
                                            <span className="text-dark fw-bold fs-4 mb-3 w-100">{award.name}</span>
                                            <div className="d-flex align-items-center gap-5 w-100">
                                                <div className='symbol symbol-50px symbol-md-70px'>
                                                    <div className={clsx(
                                                        'symbol-label fs-3',
                                                        `bg-light-info`,
                                                        `text-info`,
                                                        'fw-bold'
                                                    )}
                                                    >
                                                        CS
                                                    </div>
                                                </div>
                                                <div className="d-flex align-items-start flex-column">
                                                    <span className="badge badge-light-warning mb-2">No Winner Yet</span>
                                                    <span className="fs-6 fw-semibold text-gray-650">N/A</span>
                                                </div>
                                                <div className="d-flex align-items-center py-2 ms-auto">
                                                    <Link to={`/awards/${categoryId}/${award.id}`} className="btn btn-sm btn-bg-light btn-color-gray-700 btn-active-light-info">View</Link>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                }
                            </>
                        )

                    })}
                </div>
                {(isLoading || isFetching) &&
                    <LoadingComponent />
                }

                {totCount == 0 && <NotificationBanner message={`No awards in ${selectedYear}'s award history.`} />}

                <div className="card-footer sticky bottom-0 d-flex justify-content-between gap-5 mx-n9">
                    {totCount > 0 &&
                        <DownloadSubmissionsButton awardCategoryID={categoryId} year={selectedYear} fileName={`award_history_${selectedYear}.csv`} className="btn btn-sm btn-bg-light btn-color-gray-700 btn-active-color-info">
                            <span className="svg-icon svg-icon-2">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M21 22H3C2.4 22 2 21.6 2 21C2 20.4 2.4 20 3 20H21C21.6 20 22 20.4 22 21C22 21.6 21.6 22 21 22ZM13 13.4V3C13 2.4 12.6 2 12 2C11.4 2 11 2.4 11 3V13.4H13Z" fill="currentColor"></path>
                                    <path opacity="0.3" d="M7 13.4H17L12.7 17.7C12.3 18.1 11.7 18.1 11.3 17.7L7 13.4Z" fill="currentColor"></path>
                                </svg>
                            </span>
                            Download {selectedYear} History
                        </DownloadSubmissionsButton>
                    }
                </div>

            </KTCardBody>

        </KTCard>
    )
}

export { AwardHistoryCard }