import {Column} from 'react-table'
import { TagValueInfo } from '@pull/pull-groupm-csp-api'
import { CustomHeader } from '../../../helpers/components/listing/tableCard/column/CustomHeader'
import TagValueActionCell from './TagValueActionCell'

const tagValueColumns: ReadonlyArray<Column<TagValueInfo>> = [
    {
        Header: (props) => <CustomHeader tableProps={props} title='Tag Name'  />,
        id: 'name',
        Cell: ({...props}) => <span className="text-dark fw-bold fs-6">{props.data[props.row.index].value}</span>,
      },
      {
        Header: (props) => (
          <CustomHeader tableProps={props} title='Created'  />
        ),
        id: 'created',
        Cell: ({...props}) => <span className="text-dark fw-bold d-block fs-6">{new Date(props.data[props.row.index].metadata.created).toLocaleDateString()}</span>,
      },
     { 
        Header: (props) => (
          <CustomHeader tableProps={props} title='Actions' className='text-end min-w-200px' />
        ),
        id: 'actions',
        Cell: ({...props}) => <TagValueActionCell tag={props.data[props.row.index]} />,
      },
]

export {tagValueColumns}