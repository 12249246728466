import {FC} from 'react'

type Props = {
  last_login?: Date
}

const UserLastLoginCell: FC<Props> = ({last_login}) => {
  const lastActive = last_login ? new Date(last_login): null
  return (
  <span className='text-dark fw-bold d-block fs-6'>{lastActive ?  lastActive.toLocaleString() : "Never"}</span>
  )
}

export {UserLastLoginCell}
