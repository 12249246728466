import { PageLink, PageTitle } from '../../../../_metronic/layout/core'
import PageContainer from '../../../helpers/components/listing/PageContainer'
import SearchCol from '../../../helpers/components/listing/searchCard/SearchCol'
import TableCard from '../../../helpers/components/listing/tableCard/TableCard'
import { Filter } from '../../../helpers/types/Filter'
import { useAuthContext, EmailApi } from '@pull/pull-groupm-csp-api'
import {QUERIES} from '../../../Constants/Queries'
import { PagedResultInterface } from '../../../modules/core/_models'
import {emailTemplateColumns} from '../columns/_columns'
import { TableProvider, useTableContext } from '../../../modules/core/functional/TableContext'
import { ToolbarContainer } from '../../../../_metronic/layout/core/ToolbarContainer'


const emailTemplatesBreadcrumbs: Array<PageLink> = [
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
  {
    title: 'Email templates',
    path: '/email-templates',
    isSeparator: false,
    isActive: false,
  },
]

const EmailTemplatesListPage = () => {
    const {updateFilters} = useTableContext()
   const { getApiConfiguration } = useAuthContext()
  const emailTemplateApi = new EmailApi(getApiConfiguration())

  const getEmailTemplates = async (pageParam = 1, filters= {}) => {
    let response =  await emailTemplateApi.apiEmailsTemplatesSearchPost({...filters, ...{page: pageParam, pageSize:10}} );
    return response as PagedResultInterface;
  }
  const submitSearchHandler = (searchFields: any) => {

    const searchRequestObj = {
      name: searchFields.name
    }
    updateFilters(searchRequestObj)
  }

  const emailTemplateFilters:Filter[] = [
  ]
  
  return (
    <>
    <ToolbarContainer>
      <PageTitle breadcrumbs={emailTemplatesBreadcrumbs} title="Email templates"></PageTitle>
      </ToolbarContainer>
      <PageContainer >
      <div className="d-flex flex-column flex-lg-row">
        <SearchCol filters={emailTemplateFilters} submitSearch={submitSearchHandler} searchName="name" />
        <TableCard tableCols={emailTemplateColumns} getData={getEmailTemplates} query={QUERIES.EMAIL_TEMPLATES.SEARCH} >
        </TableCard> 
        </div>
      </PageContainer>
    </>
  )
}

const EmailTemplatesPageWrapper = () => {
 return (
    <TableProvider>
      <EmailTemplatesListPage />
    </TableProvider>
  )
}

export {EmailTemplatesListPage, EmailTemplatesPageWrapper} 
