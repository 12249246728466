import { CountryInfo, RegionInfo, SettingInfo, SettingsApi, useAuthContext } from "@pull/pull-groupm-csp-api"
import { FC, useEffect } from "react"
import { useMutation, useQueryClient } from "react-query"
import { Value } from "sass"
import { MenuComponent } from "../../../../_metronic/assets/ts/components"
import { QUERIES } from "../../../Constants/Queries"
import { useEditModalContext } from "../../../modules/core/functional/EditModalContext"
import { CRContextType, useCRContext } from "../../../modules/country-region/CountryRegionContext"

type Props = {
    row: SettingInfo
}


const SettingActionCell:FC<Props> = ({row}) => {
    const { getApiConfiguration } = useAuthContext();
    const {updateSelectedValue} = useEditModalContext();

    const apiConfig = getApiConfiguration();
    const settingApi = new SettingsApi(apiConfig);

    const queryClient = useQueryClient()

    useEffect(() => {
        MenuComponent.reinitialization()
      }, [])
    
    const enableItem = useMutation(() => {
        return settingApi.apiSettingsSystemPost({id:row.id,name:row.name, value:row.value, isSensitive:!row.isSensitive})

    },{
        onSuccess:() =>{
            queryClient.invalidateQueries([QUERIES.GLOBAL.GET])
        }
    })

    const editItem = () =>{
        updateSelectedValue(row)
    }
   
    return (
        <div className="d-flex ms-auto justify-content-end">
            <div className="form-check form-switch form-check-custom form-check-solid me-8">
                <input onChange={async () => await enableItem.mutateAsync()} className="form-check-input h-20px w-30px" type="checkbox" value="true" name="activeswitch" checked={row.isSensitive} />
                <label className="form-check-label" htmlFor="activeswitch">
                    Sensitive
                </label>
            </div>
            <button onClick={editItem} type="button" className="btn btn-bg-light btn-color-gray-700 btn-active-color-info btn-sm px-4 me-2">Edit</button>
            
            </div>
    )
}

export default SettingActionCell