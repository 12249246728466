import {FC, useState} from 'react'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import { KTSVG, toAbsoluteUrl} from '../../../../../_metronic/helpers'
import clsx from 'clsx'
import { AddUpdateUserRequest, Role, useAuthContext, UserInfo, UsersApi } from '@pull/pull-groupm-csp-api'
import { isNotEmpty } from '../../../../../_metronic/helpers/crud-helper/helpers'
import { useGlobalContext } from '../../../../modules/core/GlobalContext'
import { LoadingComponent } from '../../../../modules/core/LoadingComponent'
import { UserProvider, useUserContext } from '../../../../modules/user/UserContext'
import { ModalContainer } from '../../../../helpers/components/generic/ModalContainer'
import { useQueryClient } from 'react-query'
import { QUERIES } from '../../../../Constants/Queries'
import { useEditModalContext } from '../../../../modules/core/functional/EditModalContext'
import { getErrorFromResponse } from '../../../../helpers/functions/getErrorFromResponse'
import { Success } from '../../../../helpers/functions/SwalHelpers'
import { ErrorBanner } from '../../../../helpers/components/generic/NotificationBanner'

type Props = {
  isUserLoading: boolean
  user: UserInfo
}

const editUserSchema = Yup.object().shape({
  emailAddress: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
  firstName: Yup.string()
    .min(3, 'Minimum 3 characters')
    .max(50, 'Maximum 50 characters')
    .required('First Name is required'),
  lastName: Yup.string()
    .min(3, 'Minimum 3 characters')
    .max(50, 'Maximum 50 characters')
    .required('Last Name is required'),
  password:Yup.string()
      .when('id',{
        is:0,
        then:Yup.string().required('Password is required for new users.')
      }),
  role:Yup.string().required('Please select a Role')
})


const UserEditModalForm: FC<Props> = ({user, isUserLoading}) => {
  const {countries,regions} = useGlobalContext()
  const { getApiConfiguration } = useAuthContext()
  const [error,setError] = useState<string|null>(null)
  const queryClient = useQueryClient()
  const {updateSelectedValue} = useEditModalContext()

  const [userForEdit] = useState<AddUpdateUserRequest>({
    id: user.id || 0,
    firstName: user.firstName || "",
    lastName: user.lastName || "",
    enabled: user.enabled ??= false,
    role: user.role || undefined,
    password:undefined,
    emailAddress: user.email || "",
    country: user.country?.id || undefined,
    region: user.region?.id || undefined
  })

  const passwordPlaceholder = userForEdit.id === 0 ? "Password": "Leave blank to keep password as is."
  const cancel = () =>{
    updateSelectedValue(null)
    queryClient.invalidateQueries([QUERIES.USER.SEARCH])
  }

  const formik = useFormik({
    initialValues: userForEdit,
    validationSchema: editUserSchema,
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true)
      const usersApi = new UsersApi(getApiConfiguration())
      try {
        const result = await usersApi.apiUsersPost(values as AddUpdateUserRequest)
        setSubmitting(false)
        if(result?.id){
          queryClient.invalidateQueries([QUERIES.USER.GET])
          Success(`The user has been ${userForEdit.id === 0 ? "created":"updated"} successfully`)
          cancel()
        }
      } catch (ex) {
        if(ex as Response){
          const errorMessage = await getErrorFromResponse(ex as Response)
          setError(errorMessage)
        }
        else{
          console.log(ex)
        }
      }
        
    },
  })
  return (
    <ModalContainer id="kt_modal_add_user">
      <div className='modal-body scroll-y mx-5 mx-xl-15 my-7'>
      <form id='kt_modal_add_user_form' className='form' onSubmit={formik.handleSubmit} noValidate>
        {/* begin::Scroll */}
        <div
          className='d-flex flex-column scroll-y me-n7 pe-7'
          id='kt_modal_add_user_scroll'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies='#kt_modal_add_user_header'
          data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
          data-kt-scroll-offset='300px'
        >
          {error && 
          <ErrorBanner message={error} />
          }

          {/* begin::Input group  first Name*/}
          <div className='fv-row mb-7'>
            {/* begin::Label */}
            <label className='required fw-semibold fs-6 mb-2'>First Name</label>
            {/* end::Label */}

            {/* begin::Input */}
            <input
              placeholder='First name'
              {...formik.getFieldProps('firstName')}
              type='text'
              name='firstName'
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0',
                {'is-invalid': formik.touched.firstName && formik.errors.firstName},
                {
                  'is-valid': formik.touched.firstName && !formik.errors.firstName,
                }
              )}
              autoComplete='off'
              disabled={formik.isSubmitting || isUserLoading}
            />
            {formik.touched.firstName && formik.errors.firstName && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.firstName}</span>
                </div>
              </div>
            )}
            {/* end::Input */}
          </div>
          {/* end::Input group */}
          
          {/* begin::Input group Last name */}
          <div className='fv-row mb-7'>
            {/* begin::Label */}
            <label className='required fw-semibold fs-6 mb-2'>Last Name</label>
            {/* end::Label */}

            {/* begin::Input */}
            <input
              placeholder='Last name'
             {...formik.getFieldProps('lastName')}
              type='text'
              name='lastName'
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0',
                {'is-invalid': formik.touched.lastName && formik.errors.lastName},
                {
                  'is-valid': formik.touched.lastName && !formik.errors.lastName,
                }
              )}
              autoComplete='off'
              disabled={formik.isSubmitting || isUserLoading}
            />
            {formik.touched.lastName && formik.errors.lastName && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.lastName}</span>
                </div>
              </div>
            )}
            {/* end::Input */}
          </div>
          {/* end::Input group */}
          {/* begin::Input group Email */}
          <div className='fv-row mb-7'>
            {/* begin::Label */}
            <label className='required fw-semibold fs-6 mb-2'>Email</label>
            {/* end::Label */}

            {/* begin::Input */}
            <input
              placeholder='Email'
              {...formik.getFieldProps('emailAddress')}
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0',
                {'is-invalid': formik.touched.emailAddress && formik.errors.emailAddress},
                {
                  'is-valid': formik.touched.emailAddress && !formik.errors.emailAddress,
                }
              )}
              type='email'
              name='emailAddress'
              autoComplete='new-password'
              disabled={formik.isSubmitting || isUserLoading}
            />
            {/* end::Input */}
            {formik.touched.emailAddress && formik.errors.emailAddress && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                <span role='alert'>{formik.errors.emailAddress}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Input group */}
{/* 
          {/* begin::Input group Password  
          - need to discuss whether this is how passwords should be updated*/}
           <div className='fv-row mb-7'>  
            {/* begin::Label */}
             <label className='required fw-semibold fs-6 mb-2'>Password</label>
            {/* end::Label */}

            {/* begin::Input */}
            <input
              placeholder={passwordPlaceholder}
              {...formik.getFieldProps('password')}
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0',
                {'is-invalid': formik.touched.password && formik.errors.password},
                {
                  'is-valid': formik.touched.password && !formik.errors.password,
                }
              )}
              type='password'
              name='password'
              autoComplete='new-password'
              disabled={formik.isSubmitting || isUserLoading}
            /> 
            {/* end::Input */}
             {formik.touched.password && formik.errors.password && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                <span role='alert'>{formik.errors.password}</span>
                </div>
              </div>
            )}
          </div> 
          {/* end::Input group */}

         {/* add password input */}
          
          {/*Input group Enabled */}
          <div className='fv-row mb-7'>
          <label className='required fw-semibold fs-6 mb-2'>Enabled</label>
            <div className="form-check form-switch form-check-custom form-check-solid">
              <input className="form-check-input h-20px w-30px" 
              {...formik.getFieldProps('enabled')}
                type="checkbox" 
                value=""
                name="enabled"
                checked={formik.values.enabled}
                disabled={formik.isSubmitting || isUserLoading} />
              {/* <label className="form-check-label" >
                Enabled
              </label> */}

            </div>
              
          </div>
          {/*end::Input group */}
          {/* begin::Input group Country */}
          <div className='fv-row mb-7'>
            {/* begin::Label */}
            <label className='fw-semibold fs-6 mb-2'>Country</label>
            {/* end::Label */}

            {/* begin::Input */}
            <select
              {...formik.getFieldProps('country')}
              name='country'
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0',
                {'is-invalid': formik.touched.country && formik.errors.country},
                {
                  'is-valid': formik.touched.country && !formik.errors.country,
                }
              )}
              value={formik.values.country??""}
              onChange={ e => {
                formik.setFieldValue('country',Number(e.currentTarget.value))
              }}
              autoComplete='off'
              disabled={formik.isSubmitting || isUserLoading}
            >
              <option disabled={true} value="" >Please select a Country</option>
              {countries.map((country) => {
                if(formik.touched.country === country.id){console.log(`user's country:${country.name}`)}
                return(
                  <option key={country.id} value={country.id} >{country.name}</option>
                )
              })}
              </select>
            {formik.touched.country && formik.errors.country && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.country}</span>
                </div>
              </div>
            )}
            {/* end::Input */}
          </div>
          {/* end::Input group */}

          {/* begin::Input group Region */}
          <div className='fv-row mb-7'>
            {/* begin::Label */}
            <label className='fw-semibold fs-6 mb-2'>Region</label>
            {/* end::Label */}

            {/* begin::Input */}
            <select
              {...formik.getFieldProps('region')}
              name='region'
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0',
                {'is-invalid': formik.touched.region && formik.errors.region},
                {
                  'is-valid': formik.touched.region && !formik.errors.region,
                }
              )}
              autoComplete='off'
              value={formik.values.region?? ""}
              onChange={ e => {
                formik.setFieldValue('region',Number(e.currentTarget.value))
              }}
              disabled={formik.isSubmitting || isUserLoading}
            >
              <option disabled={true} value="" >Please select a Region</option>
              {regions.map((region) => {
                return(
                  <option key={region.id} value={region.id}>{region.name}</option>
                )
              })}
              </select>
            {formik.touched.region && formik.errors.region && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.region}</span>
                </div>
              </div>
            )}
            {/* end::Input */}
          </div>
          {/* end::Input group */} 
          {/* begin::Input group Role */}
          <div className='mb-7'>
            {/* begin::Label */}
            <label className='required fw-semibold fs-6 mb-5'>Role</label>
            {/* end::Label */}
            {/* begin::Roles */}
            {/* begin::Input row */}
            <div className='d-flex fv-row'>
              {/* begin::Radio */}
              <div className='form-check form-check-custom form-check-solid'>
                {/* begin::Input */}
                <input
                  className='form-check-input me-3'
                  {...formik.getFieldProps('role')}
                  name='role'
                  type='radio'
                  value={Role.Administrator}
                  id='kt_modal_update_role_option_0'
                  checked={formik.values.role === Role.Administrator}
                  disabled={formik.isSubmitting || isUserLoading}
                />

                {/* end::Input */}
                {/* begin::Label */}
                <label className='form-check-label' htmlFor='kt_modal_update_role_option_0'>
                  <div className='fw-bold text-gray-800'>Administrator</div>
                  <div className='text-gray-600'>
                    Best for users who need to be able to edit everything and have full access.
                  </div>
                </label>
                {/* end::Label */}
              </div>
              {/* end::Radio */}
            </div>
            {/* end::Input row */}
            <div className='separator separator-dashed my-5'></div>
            {/* begin::Input row */}
            <div className='d-flex fv-row'>
              {/* begin::Radio */}
              <div className='form-check form-check-custom form-check-solid'>
                {/* begin::Input */}
                <input
                  className='form-check-input me-3'
                  {...formik.getFieldProps('role')}
                  name='role'
                  type='radio'
                  value={Role.Judge}
                  id='kt_modal_update_role_option_1'
                  checked={formik.values.role === Role.Judge}
                  disabled={formik.isSubmitting || isUserLoading}
                />
                {/* end::Input */}
                {/* begin::Label */}
                <label className='form-check-label' htmlFor='kt_modal_update_role_option_1'>
                  <div className='fw-bold text-gray-800'>Judge</div>
                  <div className='text-gray-600'>
                    These users will be able to Judge case studies.
                  </div>
                </label>
                {/* end::Label */}
              </div>
              {/* end::Radio */}
            </div>
            {/* end::Input row */}
            <div className='separator separator-dashed my-5'></div>
            {/* begin::Input row */}
            <div className='d-flex fv-row'>
              {/* begin::Radio */}
              <div className='form-check form-check-custom form-check-solid'>
                {/* begin::Input */}
                <input
                  className='form-check-input me-3'
                  {...formik.getFieldProps('role')}
                  name='role'
                  type='radio'
                  value={Role.User}
                  id='kt_modal_update_role_option_2'
                  checked={formik.values.role === Role.User}
                  disabled={formik.isSubmitting || isUserLoading}
                />

                {/* end::Input */}
                {/* begin::Label */}
                <label className='form-check-label' htmlFor='kt_modal_update_role_option_2'>
                  <div className='fw-bold text-gray-800'>User</div>
                  <div className='text-gray-600'>
                    Best for all generic users having basic access to the main Case Study Portal
                  </div>
                </label>
                {/* end::Label */}
              </div>
              {/* end::Radio */}
            </div>
            {/* end::Input row */}
            <div className='separator separator-dashed my-5'></div>
            {formik.touched.role && formik.errors.role && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.role}</span>
                </div>
              </div>
            )}
            {/* end::Roles */}
          </div>
          {/* end::Input group */}
        </div>
        {/* end::Scroll */}

        {/* begin::Actions */}
        <div className='text-center pt-15'>
          <button
            type='reset'
            onClick={() => cancel()}
            className='btn btn-light me-3'
            data-kt-users-modal-action='cancel'
            disabled={formik.isSubmitting || isUserLoading}
          >
            Discard
          </button>

          <button
            type='submit'
            className='btn btn-primary'
            data-kt-users-modal-action='submit'
            disabled={isUserLoading || formik.isSubmitting || !formik.isValid || !formik.touched}
          >
            <span className='indicator-label'>Submit</span>
            {(formik.isSubmitting || isUserLoading) && (
              <span className='indicator-progress'>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
        {/* end::Actions */}
      </form>
      {(formik.isSubmitting || isUserLoading) && <LoadingComponent/>}
      </div>
    </ModalContainer>
  )
}

export {UserEditModalForm}
