import { CaseStudyInfo, AwardsApi, useAuthContext } from '@pull/pull-groupm-csp-api'
import { FC, useEffect } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { Link } from 'react-router-dom'
import { MenuComponent } from '../../../../_metronic/assets/ts/components'
import { QUERIES } from '../../../Constants/Queries'
import { Delete } from '../../../helpers/functions/SwalHelpers'


type Props = {
    submissionId: number
    caseStudyId: number
    awardCatId:number
}
const SubmissionsActionCell:FC<Props> = ({caseStudyId,submissionId, awardCatId}) => {
    const { getApiConfiguration } = useAuthContext();

    const apiConfig = getApiConfiguration();
    const awardsApi = new AwardsApi(apiConfig);

    const queryClient = useQueryClient();

    useEffect(() => {
        MenuComponent.reinitialization()
      }, [])

      const deleteOnClick = () =>{
        Delete("submission")
        .then(async (result) =>{
          if(result.isConfirmed){
            await removeItem.mutateAsync()
          }
        })
      }

    const removeItem = useMutation(() => awardsApi.apiAwardsAwardSubmissionsIdDelete(submissionId), {
        // 💡 response of the mutation is passed to onSuccess
        onSuccess: () => {
          // ✅ update detail view directly
          queryClient.invalidateQueries([QUERIES.AWARDS.SEARCH+ awardCatId])
        },
      })

    return (
        <div className="text-end">
            <button type="button" 
            className="btn btn-bg-light btn-color-gray-700 btn-active-color-danger btn-sm px-4" 
            data-delete-case-study=""
            onClick={deleteOnClick}>Remove Submission</button>
        </div>
    )
}

export {SubmissionsActionCell}