
import clsx from "clsx"
import { FC, ReactNode, useEffect, useState, useMemo, useContext } from "react"
import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from 'react-query'
import { KTCard, KTCardBody } from "../../../../_metronic/helpers"
import { NotificationBanner } from "../../../helpers/components/generic/NotificationBanner"
import { useTableContext } from "../../../modules/core/functional/TableContext"
import { LoadingComponent } from "../../../modules/core/LoadingComponent"
import { PagedResultInterface } from "../../../modules/core/_models"
import { deliberationsColumns, judgingColumns, submissionColumns } from "../columns/_columns"
import { Tabs } from "../model/_models"
import { AwardTabTable } from "./AwardTabTable"
import UpcomingAwards from "./UpcomingAwards"


type Props = {
    getData: (pageParam: number, filters: any) => Promise<PagedResultInterface | undefined>
    query: string
}

const AwardTabs: FC<Props> = ({ query, getData }) => {
    const [data, setData] = useState<any[]>([])
    const { updatePage, currentPage, currentFilters, updateFilters } = useTableContext()
    const [tabInfo, setTabInfo] = useState({ tab: Tabs.Submissions, tabTitle: "open for Submissions", tabCols: submissionColumns })
    const { isLoading, isFetching, data: fullData, error } = useQuery([query, currentPage, JSON.stringify(currentFilters)], () => getData(currentPage, currentFilters), { keepPreviousData: true, refetchOnWindowFocus: false })
    const loadMoreHandler = () => {
        updatePage((currentPage + 1));
    }
    if (error) {
        console.log(error)
    }

    const tabClick = (tab: Tabs) => {
        updateFilters((prevState: any) => {
            let filter = prevState
            filter.pageSize = 15
            switch (tab) {
                case Tabs.Judging:
                    filter.openForJudging = true
                    filter.openForDeliberations = false
                    filter.openForSubmissions = false
                    filter.pending = false
                    setTabInfo({ tab: tab, tabTitle: "open for judging", tabCols: judgingColumns })
                    break;
                case Tabs.Deliberations:
                    filter.openForJudging = false
                    filter.openForDeliberations = true
                    filter.openForSubmissions = false
                    filter.pending = false
                    setTabInfo({ tab: tab, tabTitle: "open for deliberations", tabCols: deliberationsColumns })
                    break;
                case Tabs.Upcoming:
                    filter.pending = true
                    filter.openForJudging = false
                    filter.openForDeliberations = false
                    filter.openForSubmissions = false
                    setTabInfo({ tab: tab, tabTitle: "Upcoming Awards", tabCols: [] })
                    break;
                default:
                    filter.openForSubmissions = true
                    filter.pending = false
                    filter.openForJudging = false
                    filter.openForDeliberations = false
                    setTabInfo({ tab: tab, tabTitle: "open for submission", tabCols: submissionColumns })
                    break;
            }
            return filter
        }
        )

    }

    useEffect(() => {
        if (currentPage > 1 && fullData?.results != undefined) {
            setData((prevState) => { return [...prevState, ...fullData?.results ?? []] });
        }
        else
            setData(fullData?.results ?? [])

    }, [fullData?.results])




    return (
        <KTCard className="mb-5 mb-xl-10">
            <div className="card-header card-header-stretch border-0">
                <div className="card-toolbar m-0">
                    <ul className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent" role="tablist">

                        <li className="nav-item" role="presentation">
                            <a id="submissions_tab_btn"
                                onClick={() => tabClick(Tabs.Submissions)}
                                className={clsx("nav-link text-gray-650 fs-5 fw-bold",
                                    { "me-5 active": tabInfo.tab == Tabs.Submissions })}
                                data-bs-toggle="tab" role="tab"
                                href="#submissions"
                                aria-selected={tabInfo.tab == Tabs.Submissions}>Submissions stage</a>
                        </li>
                        <li className="nav-item" role="presentation">
                            <a id="judging_tab_btn"
                                onClick={() => tabClick(Tabs.Judging)}
                                className={clsx("nav-link text-gray-650 fs-5 fw-bold",
                                    { "me-5 active": tabInfo.tab == Tabs.Judging })}
                                data-bs-toggle="tab" role="tab"
                                href="#judging"
                                aria-selected={tabInfo.tab == Tabs.Judging}>Judging stage</a>
                        </li>
                        <li className="nav-item" role="presentation">
                            <a id="deliberations_tab_btn"
                                onClick={() => tabClick(Tabs.Deliberations)}
                                className={clsx("nav-link text-gray-650 fs-5 fw-bold",
                                    { "me-5 active": tabInfo.tab == Tabs.Deliberations })}
                                data-bs-toggle="tab" role="tab"
                                href="#deliberations"
                                aria-selected={tabInfo.tab == Tabs.Deliberations}>Deliberations stage</a>
                        </li>
                        <li className="nav-item" role="presentation">
                            <a id="upcoming_tab_btn"
                                onClick={() => tabClick(Tabs.Upcoming)}
                                className={clsx("nav-link text-gray-650 fs-5 fw-bold",
                                    { "me-5 active": tabInfo.tab == Tabs.Upcoming })}
                                data-bs-toggle="tab" role="tab"
                                href="#upcoming"
                                aria-selected={tabInfo.tab == Tabs.Upcoming} >Upcoming awards</a>
                        </li>
                    </ul>
                </div>

            </div>
            <KTCardBody className="tab-content pb-0">
                {tabInfo.tab == Tabs.Upcoming ?
                    <UpcomingAwards awards={data} awardCat={currentFilters.selectedAwardCategories[0]} />
                    :
                    data && data.length > 0 ?
                        data.map((award) => {
                            return (
                                <AwardTabTable key={award.id + currentFilters.selectedAwardCategories[0]} award={award} tableCols={tabInfo.tabCols} awardCat={currentFilters.selectedAwardCategories[0]} />
                            )
                        })
                        :
                        <NotificationBanner message={`No awards are currently ${tabInfo.tabTitle}`} />
                }
                {(isLoading || isFetching) &&
                    <LoadingComponent />
                }

                {data.length < (fullData?.totalCount ?? Infinity) &&
                    <div className="d-flex flex-stack flex-wrap py-5">
                        <div className="fs-6 fw-semibold text-gray-650">Showing 1 to {data.length} of {fullData?.totalCount} awards</div>
                        <a onClick={loadMoreHandler} className="btn btn-light btn-sm">Load next Award</a>
                    </div>

                }

            </KTCardBody>

        </KTCard>
    )
}

export default AwardTabs