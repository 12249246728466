import { Link, Outlet, useLocation } from 'react-router-dom'
//import '../assets/css/reset.css'
//import '../assets/css/layout-v3.css'
import { Header } from './components/Header'
import { Footer } from './components/Footer'
import { useLayout } from '../../_metronic/layout/core'
import { useEffect } from 'react'
import { MenuComponent } from '../../_metronic/assets/ts/components'
import { GlobalProvider } from '../modules/core/GlobalContext'


const MasterLayout = () => {
  const { classes } = useLayout()

  const location = useLocation()
  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization()
    }, 500)
  }, [])

  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization()
    }, 500)
  }, [location.key])
  return (
    <>
      <div className="d-flex flex-column flex-root">
        <div className="page d-flex flex-row flex-column-fluid">
          <div className="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
            <Header />
            <GlobalProvider>
              <Outlet />
            </GlobalProvider>
           
          </div>
        </div>
      </div>
      <Footer />
      <div id="modal-root">
        
      </div>
      
      </>
  )
}

export { MasterLayout }