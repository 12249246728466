export const QUERIES = {
    USER: {
        SEARCH : "users_search",
        ADD : "add_user",
        DELETE:"delete_user",
        GET: "users_get_by_id"
    },
    CASE_STUDIES: {
        GET: "case_studies_get_by_id",
        SEARCH: "case_studies_search",
    },
    NEWS:{
        SEARCH:"news_search",
        GET:"news_get_by_id"
    },
    EMAIL_QUEUE:{
        GET:"emailqueue"
    },
    EMAIL_TEMPLATES:{
        SEARCH:"emailtemplates_search",
        GET:"emailtemplates_get_by_id"
    },
    PAGES:{
        SEARCH:"pages_search",
        GET:"pages_get_by_id"
    },
    GLOBAL:{
        INITIAL:"global_lists",
        SEARCH:"search_settings_",
        GET:"get_system_settings"
    },
    TAG:{
        SEARCH:"tag_list_search"
    },
    AWARDS:{
            SEARCH:"search_awards_by_category",
            GET:"get_award_by_id",
            GET_ALL_CATEGORIES:"get_all_award_categories",
            GET_CATEGORY:"get_award_category_by_id",
            SEARCH_SUBMISSIONS:"search_award_submissions_by_award",
            JUDGES_SEARCH:"search_judges",
            GET_JUDGE:"get_judge"
    }
    
}