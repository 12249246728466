import { Column } from 'react-table'
import { UserInfoCell } from './UserInfoCell'
import { UserLastLoginCell } from './UserLastLoginCell'
import { UserActionsCell } from './UserActionsCell'
import { CustomHeader } from '../../../../../helpers/components/listing/tableCard/column/CustomHeader'
import { UserInfo } from '@pull/pull-groupm-csp-api'
import moment from 'moment'

const usersColumns: ReadonlyArray<Column<UserInfo>> = [
  {
    Header: (props) => <CustomHeader tableProps={props} title='Name' className='min-w-125px' />,
    id: 'name',
    Cell: ({ ...props }) => <UserInfoCell user={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <CustomHeader tableProps={props} title='Last Active' className='min-w-125px' />
    ),
    id: 'last_active',
    Cell: ({...props}) => <UserLastLoginCell last_login={props.data[props.row.index].lastLogIn} />,
  },
  {
    Header: (props) => <CustomHeader tableProps={props} title='Role' className='min-w-125px' />,
    id: 'role',
    Cell: ({ ...props }) => <span className='text-dark fw-bold d-block fs-6'>{props.data[props.row.index].role}</span>
  },
  {
    Header: (props) => (
      <CustomHeader tableProps={props} title='Actions' className='text-end min-w-100px' />
    ),
    id: 'actions',
    Cell: ({ ...props }) => <UserActionsCell id={props.data[props.row.index].id} email={props.data[props.row.index].email} />,
  },
]

export { usersColumns }
