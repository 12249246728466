import { FC, useState } from 'react'
import { FilterOption } from '../../../types/Filter';

export type checkBoxModel = {
    name?: string,
    checkbox: FilterOption
}


const Checkbox: FC<checkBoxModel> = ({ name, checkbox }) => {
    const [checked, setChecked] = useState(checkbox.isSelected)

    const handleChange = () => {
        setChecked(!checked)
    }

    return (
        <div className="mb-10">
            <div className="form-check form-switch form-check-custom form-check-solid">
                <input className="form-check-input h-20px w-30px" type="checkbox" onChange={handleChange} name={name} id={name} checked={checked} />
                <label className="form-check-label" htmlFor={name}>
                    {checkbox.text}
                </label>
            </div>
        </div>
    )
}

export default Checkbox