import { FC } from "react"
import { getSVG, SVGType } from "../../../helpers/functions/svgHelper";


type Props = {
    icon: SVGType,
    statistic?: string,
    heading?: string,
    type: CardType,
    className?: string 
}

enum CardType {
    Success,
    Info,
    Primary
}


const SimpleStatCard: FC<Props> = ({ icon, statistic, heading, type, className }) => {


    function getInfoClass() {
        switch (type) {
            case CardType.Info:
                return "bg-info";
            case CardType.Success:
                return "bg-success"
            case CardType.Primary:
                return "bg-primary"
        }
    }

    return (
        <>
            <div className={`card ${getInfoClass()} card-xl-stretch mb-xl-8 ${className}`}>
                <div className="card-body">
                    <span className="svg-icon svg-icon-white svg-icon-2x ms-n1">
                        {getSVG(icon)}
                    </span>
                    <div className="text-white fw-bold fs-2 mb-2 mt-3">{statistic}</div>
                    <div className="fw-semibold text-white">{heading}</div>
                </div>
            </div>
        </>
    )
}

export { SimpleStatCard, CardType }