import { AnalyticsApi, Timeframe, useAuthContext } from "@pull/pull-groupm-csp-api"
import { useQuery } from "react-query"
import { useNavigate, useParams } from "react-router-dom"
import { PageLink, PageTitle } from "../../../_metronic/layout/core"
import { ToolbarContainer } from "../../../_metronic/layout/core/ToolbarContainer"
import PageContainer from "../../helpers/components/listing/PageContainer"
import { getAnalyticColumnValueFromPrimaryResult, getNumberWithCommas } from "../../helpers/functions/analyticsHelper"
import { SVGType } from "../../helpers/functions/svgHelper"
import { CardType, SimpleStatCard } from "./cards/SimpleStatCard"
import { CaseStudyStatCard, CaseStudyStatCardMode } from "./cards/CaseStudyStatCard"
import { AwardSubmissionStatCard, AwardSubmissionStatCardMode } from "./cards/AwardSubmissionStatCard"
import { MenuComponent } from "../../../_metronic/assets/ts/components"
import moment from "moment"

const analyticsBreadcrumbs: Array<PageLink> = [
    {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
    },
    {
        title: 'Analytics',
        path: '/analytics',
        isSeparator: false,
        isActive: false,
    },
]


const AnalyticsPage = () => {
    const { getApiConfiguration } = useAuthContext()
    const analyticsApi = new AnalyticsApi(getApiConfiguration())

    function getResult(index: number) {
        if (analytics && index < analytics.length) {
            return analytics[index]?.result;
        }

        return undefined;
    }

    function getRequest() {
        return {
            items: [
                { analyticsName: "CaseStudiesCreated", timeframe: Timeframe.AllTime },
                { analyticsName: "CaseStudiesMostUsedCountry", timeframe: Timeframe.AllTime },
                { analyticsName: "CaseStudiesViews", timeframe: Timeframe.AllTime },
                { analyticsName: "AwardsJudges", timeframe: Timeframe.AllTime, parameters: [5] },
                { analyticsName: "AwardsCreated", timeframe: Timeframe.AllTime, parameters: [5] },
                { analyticsName: "AwardsSubmissionsTotal", timeframe: Timeframe.AllTime, parameters: [5] },
                { analyticsName: "AwardsJudges", timeframe: Timeframe.AllTime, parameters: [54] },
                { analyticsName: "AwardsCreated", timeframe: Timeframe.AllTime, parameters: [54] },
                { analyticsName: "AwardsSubmissionsTotal", timeframe: Timeframe.AllTime, parameters: [54] }
            ]
        }
    }

    const { isLoading, data: analytics } = useQuery("generalAnalytics", async () => {
        var results = await analyticsApi.apiAnalyticsGetPost(getRequest())
        return results;
    }, { onSettled: () => {
        setTimeout(() => {
            MenuComponent.reinitialization();
        }, 100)
    }});

    return (
        <>
            <ToolbarContainer>
                <PageTitle breadcrumbs={analyticsBreadcrumbs} title="Analytics"></PageTitle>
            </ToolbarContainer>
            <PageContainer>
                {!isLoading && (
                    <>
                        <div className="d-flex flex-column flex-lg-row">
                            <div className="flex-lg-row-fluid mb-lg-12">
                                <h2 className="d-flex text-dark fw-bold fs-2">General</h2>
                                <div className="row g-5 g-xl-8">
                                    <div className="col-xl-4">
                                        <SimpleStatCard heading="Case studies created" statistic={getNumberWithCommas(getAnalyticColumnValueFromPrimaryResult("CaseStudiesCreated", getResult(0)))} icon={SVGType.Edit} type={CardType.Success}></SimpleStatCard>
                                    </div>
                                    <div className="col-xl-4">
                                        <SimpleStatCard heading={`Most Created (${getNumberWithCommas(getAnalyticColumnValueFromPrimaryResult("MostCreatedCountry", getResult(1)))})`} statistic={getAnalyticColumnValueFromPrimaryResult("MostCreatedNumber", getResult(1))} icon={SVGType.Location} type={CardType.Success}></SimpleStatCard>
                                    </div>
                                    <div className="col-xl-4">
                                        <SimpleStatCard heading={"Total Page Views"} statistic={getNumberWithCommas(getAnalyticColumnValueFromPrimaryResult("TotalViews", getResult(2)))} icon={SVGType.Eye} type={CardType.Success}></SimpleStatCard>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <CaseStudyStatCard heading="Most viewed " startDate={ moment().startOf("year").toDate()} endDate={moment().endOf("day").toDate()} mode={CaseStudyStatCardMode.Views}></CaseStudyStatCard>
                                    </div>
                                    <div className="col-md-6">
                                        <CaseStudyStatCard heading="Most downloaded" startDate={ moment().startOf("year").toDate()} endDate={moment().endOf("day").toDate()} mode={CaseStudyStatCardMode.Downloads}></CaseStudyStatCard>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="d-flex flex-column flex-lg-row">
                            <div className="flex-lg-row-fluid mb-lg-12">
                                <h2 className="d-flex text-dark fw-bold fs-2">Campaign of the Month</h2>
                                <div className="row g-5 g-xl-8">
                                    <div className="col-xl-4">
                                        <SimpleStatCard heading="Assigned Judges" statistic={getAnalyticColumnValueFromPrimaryResult("Judges", getResult(3))} icon={SVGType.User} type={CardType.Info}></SimpleStatCard>
                                    </div>
                                    <div className="col-xl-4">
                                        <SimpleStatCard heading="Awards created since 2016" statistic={getAnalyticColumnValueFromPrimaryResult("Result", getResult(4))} icon={SVGType.Award} type={CardType.Info}></SimpleStatCard>
                                    </div>
                                    <div className="col-xl-4">
                                        <SimpleStatCard heading="Entrants since 2016" statistic={getNumberWithCommas(getAnalyticColumnValueFromPrimaryResult("Entrants", getResult(5)))} icon={SVGType.Edit} type={CardType.Info}></SimpleStatCard>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <AwardSubmissionStatCard heading="Submissions by Country" startDate={ moment().startOf("year").toDate()} endDate={moment().endOf("day").toDate()} awardCategories={[5]} mode={AwardSubmissionStatCardMode.Countries}></AwardSubmissionStatCard>
                                    </div>
                                    <div className="col-md-6">
                                        <AwardSubmissionStatCard heading="Submissions by Region" startDate={ moment().startOf("year").toDate()} endDate={moment().endOf("day").toDate()} awardCategories={[5]} mode={AwardSubmissionStatCardMode.Regions}></AwardSubmissionStatCard>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="d-flex flex-column flex-lg-row">
                            <div className="flex-lg-row-fluid mb-lg-12">
                                <h2 className="d-flex text-dark fw-bold fs-2">Performance Campaign Award</h2>
                                <div className="row g-5 g-xl-8">
                                    <div className="col-xl-4">
                                        <SimpleStatCard heading="Assigned Judges" statistic={getAnalyticColumnValueFromPrimaryResult("Judges", getResult(6))} icon={SVGType.User} type={CardType.Primary}></SimpleStatCard>
                                    </div>
                                    <div className="col-xl-4">
                                        <SimpleStatCard heading="Awards created since 2016" statistic={getAnalyticColumnValueFromPrimaryResult("Result", getResult(7))} icon={SVGType.Award} type={CardType.Primary}></SimpleStatCard>
                                    </div>
                                    <div className="col-xl-4">
                                        <SimpleStatCard heading="Entrants since 2016" statistic={getNumberWithCommas(getAnalyticColumnValueFromPrimaryResult("Entrants", getResult(8)))} icon={SVGType.Edit} type={CardType.Primary}></SimpleStatCard>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <AwardSubmissionStatCard heading="Submissions by Country" startDate={ moment().startOf("year").toDate()} endDate={moment().endOf("day").toDate()} awardCategories={[54]} mode={AwardSubmissionStatCardMode.Countries}></AwardSubmissionStatCard>
                                    </div>
                                    <div className="col-md-6">
                                        <AwardSubmissionStatCard heading="Submissions by Region" startDate={ moment().startOf("year").toDate()} endDate={moment().endOf("day").toDate()} awardCategories={[54]} mode={AwardSubmissionStatCardMode.Regions}></AwardSubmissionStatCard>
                                    </div>
                                </div>
                            </div>
                        </div></>)}
            </PageContainer>
        </>
    )
}


export { AnalyticsPage }