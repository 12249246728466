import Tagify from '@yaireo/tagify'
import { Role, UsersApi, CaseStudiesApi } from '@pull/pull-groupm-csp-api'


function tagTemplate(tagData) {
	return `
					<tag title="${(tagData.title || tagData.email)}"
							contenteditable='false'
							spellcheck='false'
							tabIndex="-1"
							class="${this.settings.classNames.tag} ${tagData.class ? tagData.class : ""}"
							${this.getAttributes(tagData)}>
						<x title='' class='tagify__tag__removeBtn' role='button' aria-label='remove tag'></x>
						<div class="d-flex align-items-center">
							<span class='tagify__tag-text'>${tagData.name?.length > 0 ? tagData.name : tagData.email}</span>
						</div>
					</tag>
				`
}

function suggestionItemTemplate(tagData) {
	return `
					<div ${this.getAttributes(tagData)}
						class='tagify__dropdown__item d-flex align-items-center ${tagData.class ? tagData.class : ""}'
						tabindex="0"
						role="option">

						<div class="d-flex flex-column">
							<strong>${tagData.name}</strong>
							<span>${tagData.email}</span>
						</div>
					</div>
				`
}


function onDropdownShow(e) {
	var dropdownContentElm = e.detail.tagify.DOM.dropdown.content;
}



const getJudgeTagify = (inputElm, apiConfig) => {
	// initialize Tagify on the above input node reference
	var tagify = new Tagify(inputElm, {
		tagTextProp: 'email', // very important since a custom template is used with this property as text. allows typing a "value" or a "name" to match input with whitelist
		skipInvalid: true, // do not remporarily add invalid tags
		whitelist: JSON.parse(inputElm.value.trim()),
		enforceWhitelist: true,
		placeholder: "Start typing",
		//readonly:true,
		editTags: false,
		keepInvalidTags: false,
		dropdown: {
			closeOnSelect: true,
			enabled: 0,
			classname: 'users-list',
			searchKeys: ['name', 'email']  // very important to set by which keys to search for suggesttions when typing
		},
		templates: {
			tag: tagTemplate,
			dropdownItem: suggestionItemTemplate
		}
	})
	tagify.on('dropdown:show dropdown:updated', onDropdownShow)

	tagify.on('input', (e) => {
		const value = e.detail.value
		tagify.whitelist = []
		let controller
		// https://developer.mozilla.org/en-US/docs/Web/API/AbortController/abort
		controller && controller.abort()
		controller = new AbortController()

		tagify.loading(true).dropdown.hide()
		setTimeout(() => {
			const usersApi = new UsersApi(apiConfig)
			usersApi.apiUsersSearchPost({ email: value, role: Role.Judge, page: 1, pageSize: 50 })
				.then(function (newList) {
					tagify.whitelist = newList.results ? newList.results.map((j) => { return { value: j.id, email: j.email, name: j.firstName && j.lastName ? `${j.firstName} ${j.lastName}` : "" } }) : []
					tagify.loading(false).dropdown.show(value)
				})
				.catch(function (e) {
					console.log(e)
				})
		}, 300)

	})

	tagify.on('add', (e) => {
		tagify.whitelist = []
	})

	return tagify

}



function submissionSuggestionItemTemplate(tagData) {
	return `
					<div ${this.getAttributes(tagData)}
						class='tagify__dropdown__item d-flex align-items-center ${tagData.class ? tagData.class : ""}'
						tabindex="0"
						role="option">

						<div class="d-flex flex-column">
							<span>${tagData.title}</span>
						</div>
					</div>
				`
}

let currentSearchVal = ""

const getSubmissionTagify = (inputElm, apiConfig, blackList) => {
	// initialize Tagify on the above input node reference
	var tagify = new Tagify(inputElm, {
		tagTextProp: 'title', // very important since a custom template is used with this property as text. allows typing a "value" or a "name" to match input with whitelist
		enforceWhitelist: true,
		skipInvalid: true, // do not remporarily add invalid tags
		mode: "select",
		editTags: false,
		// classNames:"selectMode",
		dropdown: {
			classname: "modal_tagify_dropdown",
			closeOnSelect: true,
			enabled: 0,
			searchKeys: ['title', 'value'],
			// very important to set by which keys to search for suggesttions when typing
		},
		templates: {
			dropdownItem: submissionSuggestionItemTemplate
		}
	})
	tagify.on('dropdown:show dropdown:updated', onDropdownShow)
	tagify.on('dropdown:select')

	tagify.on('input', (e) => {
		currentSearchVal = e.detail.value
		const value = e.detail.value
		tagify.whitelist = []

		tagify.loading(true).dropdown.hide()

		//not consistently searching typing something like dov or pul won't work API issue check against postman.
		setTimeout(() => {
			if (value === currentSearchVal && value.length > 0) {
				const caseStudyApi = new CaseStudiesApi(apiConfig)
				caseStudyApi.apiCaseStudiesSearchPost({ keywords: value, page: 1, pageSize: 100, publishedOnly:true})
					.then(function (newList) {
						tagify.whitelist = newList.results ? newList.results.map((cs) => {
							return {
								value: cs.id, title: cs.title
							}
						}).filter((item) => !blackList.includes(item.value)) : []
						tagify.loading(false).dropdown.show(value)
					})
					.catch(function (e) {
						console.log(e)
					})
			}
			else if(value.length === 0){
				tagify.loading(false).dropdown.show(value)
			}
		}, 300)

		
	})

	tagify.on('add', (e) => {
		currentSearchVal = ""
		tagify.whitelist = []
	})

	return tagify
}


function tagSuggestionItemTemplate(tagData) {
	return `
					<div ${this.getAttributes(tagData)}
						class='tagify__dropdown__item d-flex align-items-center ${tagData.class ? tagData.class : ""}'
						tabindex="0"
						role="option">

						<div class="d-flex flex-column">
							<strong>${tagData.name}</strong>
						</div>
					</div>
				`
}


const getTagsTagify = (inputElm, tags) => {
	// initialize Tagify on the above input node reference
	var tagify = new Tagify(inputElm, {
		tagTextProp: 'email', // very important since a custom template is used with this property as text. allows typing a "value" or a "name" to match input with whitelist
		skipInvalid: true, // do not remporarily add invalid tags
		//whitelist: JSON.parse(''),
		enforceWhitelist: true,
		//readonly:true,
		editTags: false,
		placeholder: "Start typing",
		keepInvalidTags: false,
		originalInputValueFormat: valuesArr => valuesArr.map(item => item.value).join(','),
		dropdown: {
			closeOnSelect: false,
			maxItems: Infinity,
			enabled: 0,
			classname: 'users-list',
			searchKeys: ['name', 'email']  // very important to set by which keys to search for suggesttions when typing
		},
		templates: {
			tag: tagTemplate,
			dropdownItem: tagSuggestionItemTemplate
		}
	})
	tagify.on('dropdown:show dropdown:updated', onDropdownShow)

	tagify.on('input', (e) => {
		const value = e.detail.value
		tagify.whitelist = []
		let controller
		// https://developer.mozilla.org/en-US/docs/Web/API/AbortController/abort
		controller && controller.abort()
		controller = new AbortController()
		tagify.loading(true).dropdown.hide()
		setTimeout(() => {
			tagify.whitelist = tags ? tags.map((a) => { return { value: a.id, name: a.value, } }) : []
			tagify.loading(false).dropdown.show(value)
		}, 300)

	})

	// tagify.on('add', (e) => {
	// 	tagify.whitelist = []
	// })

	return tagify

}

const getCountryRegionTagify = (inputElm, tags) => {
	// initialize Tagify on the above input node reference
	var tagify = new Tagify(inputElm, {
		tagTextProp: 'email', // very important since a custom template is used with this property as text. allows typing a "value" or a "name" to match input with whitelist
		skipInvalid: true, // do not remporarily add invalid tags
		//whitelist: JSON.parse(''),
		enforceWhitelist: true,
		//readonly:true,
		editTags: false,
		placeholder: "Start typing",
		keepInvalidTags: false,
		originalInputValueFormat: valuesArr => valuesArr.map(item => item.value).join(','),
		dropdown: {
			closeOnSelect: true,
			enabled: 0,
			classname: 'users-list',
			searchKeys: ['name', 'email']  // very important to set by which keys to search for suggesttions when typing
		},
		templates: {
			tag: tagTemplate,
			dropdownItem: tagSuggestionItemTemplate
		}
	})
	tagify.on('dropdown:show dropdown:updated', onDropdownShow)

	tagify.on('input', (e) => {
		const value = e.detail.value
		tagify.whitelist = []
		let controller
		// https://developer.mozilla.org/en-US/docs/Web/API/AbortController/abort
		controller && controller.abort()
		controller = new AbortController()
		tagify.loading(true).dropdown.hide()
		setTimeout(() => {
			tagify.whitelist = tags ? tags.map((a) => { return { value: a.id, name: a.name, } }) : []
			tagify.loading(false).dropdown.show(value)
		}, 300)

	})

	tagify.on('add', (e) => {
		tagify.whitelist = []
	})

	return tagify

}

export { getSubmissionTagify, getJudgeTagify, getTagsTagify, getCountryRegionTagify }