import { AwardsApi, useAuthContext } from "@pull/pull-groupm-csp-api"
import clsx from "clsx"
import { FC, useEffect } from "react"
import { useQuery } from "react-query"
import { Link } from "react-router-dom"
import { SwapperComponent } from "../../../_metronic/assets/ts/components"
import { checkIsActive, KTSVG } from "../../../_metronic/helpers"
import { QUERIES } from "../../Constants/Queries"
import { LoadingComponent } from "../../modules/core/LoadingComponent"

type Props={
	pathname:string
}

export const AwardsMenuDropDown:FC<Props> = ({pathname}) => {
    const { getApiConfiguration } = useAuthContext()
    const awardsApi = new AwardsApi(getApiConfiguration())

    const {isLoading, data:awardCategories} = useQuery([QUERIES.AWARDS.GET_ALL_CATEGORIES], () => awardsApi.apiAwardsAwardCategoriesGet())
	
	useEffect(() => {
			SwapperComponent.reinitialization()
		}, [pathname])
    
		if(isLoading)
        	return (
				<div className="menu-sub menu-sub-lg-down-accordion menu-sub-lg-dropdown px-lg-5 py-lg-5 w-lg-450px">
			<div className="separator separator-dashed mx-lg-5 mt-2 mb-4"></div>
			<div className="d-flex flex-stack flex-wrap flex-lg-nowrap gap-2 mb-5 mb-lg-0 mx-5 py-3">
				<div className="d-flex flex-column me-5">
					<div className="fs-6 fw-bold text-gray-800">New</div>
					<div className="fs-7 fw-semibold text-gray-650">Create new internal award category</div>
				</div>
				<Link to="/awards/new/details" className="btn btn-sm btn-info btn-icon-light fw-bold">
					<KTSVG path="/media/icons/duotune/abstract/abs011.svg" className="svg-icon-2"></KTSVG>
					Create
				</Link>
			</div>
		</div>
			)


    return (
		<div className="menu-sub menu-sub-lg-down-accordion menu-sub-lg-dropdown px-lg-5 py-lg-5 w-lg-450px">
			<div>
				<>
					{awardCategories?.map((awardCat) => {
						return (
						<div key={awardCat.id} className="menu-item">
							<Link className={clsx('menu-link py-3', {
										active: pathname == `/awards/${awardCat.id}`,
									})} to={`/awards/${awardCat.id}`}>
								<span className="menu-title">{awardCat.name}</span>
							</Link>
						</div>
						)
					})}

					 <div className="menu-item">
						<Link className={clsx('menu-link py-3', {
										active: pathname == `/awards/judge-activity`,
									})} to="/awards/judge-activity">
							<span className="menu-title">Judge Activity</span>
						</Link>
					</div> 
				</>
			</div>
			<div className="separator separator-dashed mx-lg-5 mt-2 mb-4"></div>
			<div className="d-flex flex-stack flex-wrap flex-lg-nowrap gap-2 mb-5 mb-lg-0 mx-5 py-3">
				<div className="d-flex flex-column me-5">
					<div className="fs-6 fw-bold text-gray-800">New</div>
					<div className="fs-7 fw-semibold text-gray-650">Create new internal award category</div>
				</div>
				<Link to="/awards/new/details" className="btn btn-sm btn-info btn-icon-light fw-bold">
					<KTSVG path="/media/icons/duotune/abstract/abs011.svg" className="svg-icon-2"></KTSVG>
					Create
				</Link>
			</div>
		</div>
    )
}