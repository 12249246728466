import { TagValueInfo, useAuthContext, UsersApi } from '@pull/pull-groupm-csp-api';
import { FC, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import {useQuery} from 'react-query'
import CREditModalForm from './CountryRegionEditModal';

type Props = {
  showModal: boolean
}

const CREditModalFormWrapper: FC<Props> = ({ showModal}) => {
  const modalRoot = document.getElementById("modal-root") as HTMLElement


  if (showModal) {
    return ReactDOM.createPortal(<CREditModalForm />,modalRoot)
  }

  return null
}

export {CREditModalFormWrapper}
