/* eslint-disable jsx-a11y/anchor-is-valid */
import { CaseStudyInfo, Picture, UserInfo } from '@pull/pull-groupm-csp-api'
import clsx from 'clsx'
import { FC } from 'react'

type Props = {
  caseStudy?: CaseStudyInfo
}

const CaseStudyInfoCell: FC<Props> = ({ caseStudy }) => {
  const img = caseStudy?.images?.find(x=>x != null)
  return (
    <div className="d-flex align-items-center">
      {img ?
      <div className="symbol symbol-45px me-5">
        <Picture className='object-fit-cover' url={img.url} width={100} alt="Alt description for case study image"></Picture>             
      </div>
      :
      <div className='symbol symbol-50px overflow-hidden me-3'>
        <div
              className={clsx(
                'symbol-label fs-3',
                `bg-light-info`,
                `text-info`,
                'fw-bold'
              )}
            >
              CS
            </div>
        </div>
}
      <div className="d-flex justify-content-start flex-column">
        <a href= {`${window._env_.CSP_URL}/casestudies/${caseStudy?.id}`} target="_blank" className="text-dark fw-bold fs-6 text-hover-primary">{caseStudy?.title}</a>
        <span className="text-muted fw-semibold text-gray-650 d-block fs-7">{caseStudy?.tags?.brand?.value}</span>
      </div>
    </div>



  )
}

export { CaseStudyInfoCell }
