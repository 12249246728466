import { CaseStudyOrder } from "@pull/pull-groupm-csp-api"
import { createContext, FC, useContext } from "react"
import { WithChildren } from "../../../_metronic/helpers"
import { Filter, FilterOption, FilterType } from "../../helpers/types/Filter"
import { useGlobalContext } from "../core/GlobalContext"

type CaseStudiesContextProps = {
    caseStudyFilters: Array<Filter>
}

const initCaseStudiesContextPropsState = {
    caseStudyFilters: []
}

const CaseStudiesContext = createContext<CaseStudiesContextProps>(initCaseStudiesContextPropsState)

const useCaseStudiesContext = () => {
    return useContext(CaseStudiesContext)
}

const CaseStudiesProvider: FC<WithChildren> = ({children}) => {
    const {countries,regions,tags} = useGlobalContext()
  const countryOptions = countries.map((country) => {return {text: country.name, value: country.id, isSelected:false} as FilterOption})
  const regionOptions = regions.map((region) => {return {text: region.name, value: region.id, isSelected:false} as FilterOption})
  const advertiserOptions = tags?.advertisers?.values?.map((tagVal) => {return {text: tagVal.value, value: tagVal.id, isSelected:false} as FilterOption})??[]
  const clientOptions = tags?.brands?.values?.map((t) => {return {text: t.value, value: t.id, isSelected:false} as FilterOption})??[]
  const channelOptions = tags?.channels?.values?.map((t) => {return {text: t.value, value: t.id, isSelected:false} as FilterOption})??[]
  const objectiveOptions = tags?.objectives?.values?.map((t) => {return {text: t.value, value: t.id, isSelected:false} as FilterOption})??[]
  const currentYear = (new Date()).getFullYear()
  const yearRange = currentYear - 2013 + 1
  const yearOptions = Array.from(Array(yearRange).keys()).map(y => {return { text: (y + 2013).toString(), value: y+2013, isSelected:false} as FilterOption})
  const sortOptions = Object.values(CaseStudyOrder).map(o => {return {text:o, value:o, isSelected:CaseStudyOrder.Recent == o} as FilterOption})
    const caseStudyFilters: Array<Filter> = [
      {
        title: "Order",
        type: FilterType.Dropdown,
        options: sortOptions
      },
        {
            title: "Advertiser",
            type: FilterType.Dropdown,
            options: [{ text: "Any", value: 0, isSelected: true },...advertiserOptions ],
          },
          {
            title: "Client",
            type: FilterType.Dropdown,
            options: [{ text: "Any", value: 0, isSelected: true },...clientOptions ],
          },
          {
            title: "Channel",
            type: FilterType.Dropdown,
            options: [{ text: "Any", value: 0, isSelected: true },...channelOptions ],
          },
          {
            title: "Objectives",
            type: FilterType.Dropdown,
            options: [{ text: "Any", value: 0, isSelected: true },...objectiveOptions ],
          },
        {
            title: "Country",
            type: FilterType.Dropdown,
            options: [{ text: "Any", value: 0, isSelected: true },...countryOptions ],
          },
          {
            title: "Region",
            type: FilterType.Dropdown,
            options: [{ text: "Any", value: 0, isSelected: true }, ...regionOptions],
          },
          {
            title:"Year",
            type: FilterType.Dropdown,
            options: [{ text: "Any", value: 0, isSelected: true }, ...yearOptions]
          },
          {
            title:"Published Only",
            name:"publishedOnly",
            type: FilterType.Checkbox,
            options: [{text:"Show only published", isSelected:true}]
          }
    ]

  return (
    <CaseStudiesContext.Provider value={{caseStudyFilters}}>
        {children}
    </CaseStudiesContext.Provider>
  )
}

export {CaseStudiesProvider, useCaseStudiesContext}