import { TagValueInfo } from '@pull/pull-groupm-csp-api'
import { FC } from 'react'
import { Link } from 'react-router-dom'
import { KTCard, KTCardBody } from '../../../_metronic/helpers'
import { PageLink, PageTitle } from '../../../_metronic/layout/core'
import { ToolbarContainer } from '../../../_metronic/layout/core/ToolbarContainer'
import PageContainer from '../../helpers/components/listing/PageContainer'
import { GlobalProvider, useGlobalContext } from '../../modules/core/GlobalContext'
import { LoadingComponent } from '../../modules/core/LoadingComponent'

const tagsBreadcrumbs: Array<PageLink> = [
    {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
    },
    {
        title: 'Tags',
        path: '/tags',
        isSeparator: false,
        isActive: false,
    },

]

const TagsPage: FC = () => {
    const { tags } = useGlobalContext()

    if(!tags)
        return (<LoadingComponent />)

    return (
        <>
        <ToolbarContainer>
            <PageTitle breadcrumbs={tagsBreadcrumbs} title='Tags'></PageTitle>
            </ToolbarContainer>
            <PageContainer>
            <div className="d-flex flex-column flex-lg-row">
                <KTCard stretch='xl-stretch'>
                    <div className="card-header border-0 pt-8">
                        <h3 className="card-title align-items-start flex-column">
                            <span className="card-label fw-bold fs-2 mb-1">Tag Categories</span>
                            {/* <span className="text-gray-650 mt-1 fw-semibold fs-6">8 in total</span> */}
                        </h3>
                    </div>

                    <KTCardBody>
                        <div className="row gx-9 gy-6">
                            {tags &&
                                Object.values(tags).map((tag) => {
                                    return (
                                            
                                        <>
                                        {tag && 

                                        <div key={tag.id} className="col-md-6 col-xl-4">
                                            <div className="card border h-xl-100 flex-row flex-stack flex-wrap p-6">

                                                <div className="d-flex flex-column py-2">
                                                    <div className="d-flex align-items-center fs-4 fw-bold">
                                                        {tag.name}
                                                    </div>
                                                    <div className="fs-6 fw-semibold text-gray-650">{tag.values.filter((tv:TagValueInfo) => tv.enabled)?.length} active tags</div>
                                                </div>
                                                <div className="d-flex align-items-center py-2">
                                                    <Link to={`/tags/${tag.id}`} className="btn btn-sm btn-bg-light btn-color-gray-700 btn-active-light-info">View Tags</Link>
                                                </div>
                                            </div>
                                        </div>
                                }
                                        </>
                                    )
                                })}
                        </div>
                    </KTCardBody>
                </KTCard>
                </div>
            </PageContainer>
        </>
    )
}


const TagsPageWrapper: FC = () => {
    return (
        <GlobalProvider>
            <TagsPage />
        </GlobalProvider>
    )
}
export { TagsPage, TagsPageWrapper }