import { CaseStudyInfo, AwardsApi, useAuthContext } from '@pull/pull-groupm-csp-api'
import { FC, useEffect } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { Link } from 'react-router-dom'
import { MenuComponent } from '../../../../_metronic/assets/ts/components'
import { QUERIES } from '../../../Constants/Queries'


type Props = {
    submissionId: number
    caseStudyId: number
}
const JudgingActionCell: FC<Props> = ({ caseStudyId, submissionId }) => {

    useEffect(() => {
        MenuComponent.reinitialization()
    }, [])

    return (
        <div className="text-end">
        </div>
    )
}

export { JudgingActionCell }