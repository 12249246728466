import { AwardSubmissionInfo, JudgeInfo } from "@pull/pull-groupm-csp-api";
import { Column } from "react-table";
import { CustomHeader } from "../../../helpers/components/listing/tableCard/column/CustomHeader";
import { CaseStudyInfoCell } from "../../case-studies/table/columns/CaseStudyInfoCell";
import { AverageScoreCell } from "./AverageScoreCell";
import { DeliberationsActionCell } from "./DeliberationsActionCell";
import { JudgingActionCell } from "./JudgingActionCell";
import { SubmissionsActionCell } from "./SubmissionsActionCell";
import SubmittedByCell from "./SubmittedByCell";
import { UserLastLoginCell } from "../../user-management/users-list/table/columns/UserLastLoginCell";
import { JudgeInfoCell } from "./JudgeInfoCell";

const submissionColumns: ReadonlyArray<Column<AwardSubmissionInfo>> = [
    {
        Header: (props) => <CustomHeader tableProps={props} title='Name' className='min-w-125px' />,
        id: 'name',
        Cell: ({ ...props }) => <CaseStudyInfoCell caseStudy={props.data[props.row.index].caseStudy} />
    },
    {
        Header: (props) => <CustomHeader tableProps={props} title='Country' className='min-w-125px' />,
        id: 'country',
        Cell: ({ ...props }) => <span className="text-dark fw-bold d-block fs-6">{props.data[props.row.index].caseStudy?.countries[0]?.name ?? ""}</span>
    },
    {
        Header: (props) => <CustomHeader tableProps={props} title='Region' className='min-w-125px' />,
        id: 'region',
        Cell: ({ ...props }) => <span className="text-dark fw-bold d-block fs-6">{props.data[props.row.index].caseStudy?.regions[0]?.name ?? ""}</span>
    },
    {
        Header: (props) => <CustomHeader tableProps={props} title='Submitted By' className='min-w-125px' />,
        id: 'submittedBy',
        Cell: ({ ...props }) => <SubmittedByCell name={props.data[props.row.index].createdBy} submittedDate={undefined/*props.data[props.row.index].created*/} />
    },
    {
        Header: (props) => <CustomHeader tableProps={props} title='Actions' className='min-w-200px text-end' />,
        id: 'actions',
        Cell: ({ ...props }) => <SubmissionsActionCell submissionId={props.data[props.row.index].id} caseStudyId={props.data[props.row.index].caseStudy.id} awardCatId={props.data[props.row.index].awardCategoryID} />
    }
]

const baseAllColumns: ReadonlyArray<Column<AwardSubmissionInfo>> = [
    {
        Header: (props) => <CustomHeader tableProps={props} title='Name' className='min-w-125px' />,
        id: 'name',
        Cell: ({ ...props }) => <CaseStudyInfoCell caseStudy={props.data[props.row.index].caseStudy} />
    },
    {
        Header: (props) => <CustomHeader tableProps={props} title='Country' className='min-w-125px' />,
        id: 'country',
        Cell: ({ ...props }) => <span className="text-dark fw-bold d-block fs-6">{props.data[props.row.index].caseStudy?.countries[0]?.name ?? ""}</span>
    },
    {
        Header: (props) => <CustomHeader tableProps={props} title='Region' className='min-w-125px' />,
        id: 'region',
        Cell: ({ ...props }) => <span className="text-dark fw-bold d-block fs-6">{props.data[props.row.index].caseStudy?.regions[0]?.name ?? ""}</span>
    },
    {
        Header: (props) => <CustomHeader tableProps={props} title='Submitted By' className='min-w-125px' />,
        id: 'submittedBy',
        Cell: ({ ...props }) => <SubmittedByCell name={props.data[props.row.index].createdBy} submittedDate={undefined/*props.data[props.row.index].created*/} />
    },
    {
        Header: (props) => <CustomHeader tableProps={props} title='Average Score' className='min-w-125px' />,
        id: 'average_score',
        Cell: ({ ...props }) => <AverageScoreCell allScores={props.data[props.row.index].allScores} averageScore={props.data[props.row.index].averageScore} caseStudyName={props.data[props.row.index].caseStudy?.title} />
    }

]

const judgingColumns: ReadonlyArray<Column<AwardSubmissionInfo>> = [
    ...baseAllColumns,
    {
        Header: (props) => <CustomHeader tableProps={props} title='Actions' className='min-w-200px text-end' />,
        id: 'actions',
        Cell: ({ ...props }) => <JudgingActionCell submissionId={props.data[props.row.index].id} caseStudyId={props.data[props.row.index].caseStudy.id} />
    }]

const closedColumns: ReadonlyArray<Column<AwardSubmissionInfo>> = [
    ...baseAllColumns,
    {
        Header: (props) => <CustomHeader tableProps={props} title='Actions' className='min-w-125px text-end' />,
        id: 'actions',
        Cell: ({ ...props }) => <DeliberationsActionCell submission={props.data[props.row.index]} />
    }]

const deliberationsColumns: ReadonlyArray<Column<AwardSubmissionInfo>> = [
    {
        Header: (props) => <CustomHeader tableProps={props} title='Name' />,
        id: 'name',
        Cell: ({ ...props }) => <CaseStudyInfoCell caseStudy={props.data[props.row.index].caseStudy} />
    },
    {
        Header: (props) => <CustomHeader tableProps={props} title='Country' />,
        id: 'country',
        Cell: ({ ...props }) => <span className="text-dark fw-bold d-block fs-6">{props.data[props.row.index].caseStudy?.countries[0]?.name ?? ""}</span>
    },
    {
        Header: (props) => <CustomHeader tableProps={props} title='Average Score' />,
        id: 'avg_score',
        Cell: ({ ...props }) => <AverageScoreCell averageScore={props.data[props.row.index].averageScore} allScores={props.data[props.row.index].allScores} caseStudyName={props.data[props.row.index].caseStudy?.title} />
    },
    {
        Header: (props) => <CustomHeader tableProps={props} title='Actions' className='min-w-200px text-end' />,
        id: 'actions',
        Cell: ({ ...props }) => <DeliberationsActionCell submission={props.data[props.row.index]} />
    }
]

const judgeActivityColumns: ReadonlyArray<Column<JudgeInfo>> = [
    {
        Header: (props) => <CustomHeader tableProps={props} title='Judge Name' className='min-w-125px' />,
        id: 'name',
        Cell: ({ ...props }) => props.data[props.row.index].user ? <JudgeInfoCell user={props.data[props.row.index].user} /> : <></>,
    },
    {
        Header: (props) => <CustomHeader tableProps={props} title='Award Contributions' className='min-w-125px' />,
        id: 'role',
        Cell: ({ ...props }) => <span className='text-dark fw-bold d-block fs-6'>{props.data[props.row.index].awardContributionsInPeriod}</span>
    },
    {
        Header: (props) => (
            <CustomHeader tableProps={props} title='Last Log In' className='text-end min-w-100px' />
        ),
        id: 'actions',
        Cell: ({...props}) => <UserLastLoginCell last_login={props.data[props.row.index].user.lastLogIn} />,
    }
    // {
    //   Header: (props) => (
    //     <CustomHeader tableProps={props} title='Last Active' className='min-w-125px' />
    //   ),
    //   id: 'last_active',
    //   Cell: ({...props}) => <UserLastLoginCell last_login={props.data[props.row.index].lastLogIn} />,
    // },
    // {
    //   Header: (props) => <CustomHeader tableProps={props} title='Role' className='min-w-125px' />,
    //   id: 'role',
    //   Cell: ({ ...props }) => <span className='text-dark fw-bold d-block fs-6'>{props.data[props.row.index].role}</span>
    // },
    // {
    //   Header: (props) => (
    //     <CustomHeader tableProps={props} title='Actions' className='text-end min-w-100px' />
    //   ),
    //   id: 'actions',
    //   Cell: ({ ...props }) => <UserActionsCell id={props.data[props.row.index].id} email={props.data[props.row.index].email} />,
    // },
]


export { submissionColumns, judgingColumns, deliberationsColumns, closedColumns, judgeActivityColumns }


