import { Role, UserInfo } from "@pull/pull-groupm-csp-api";

export type UserSearchFilters = {
  email?: string,
  role?: string,
  country?: number,
  region?: number
}

function getInitials(firstName: string | undefined, lastName: string | undefined) {
  if (firstName && lastName)
    return firstName?.substring(0, 1).toUpperCase() + lastName?.substring(0, 1).toUpperCase();
  else
    return "MS";
}

function getInitialsRegEx(name: string | undefined) {
  if (name) {
    let rgx = new RegExp(/(\p{L}{1})\p{L}+/, 'gu');

    let initials:RegExpMatchArray[]|string = [...name.matchAll(rgx)] || [];

    initials = (
      (initials.shift()?.[1] || '') + (initials.pop()?.[1] || '')
    ).toUpperCase();

    return initials
  }
  else
    return "MS";
}


const initUserInfoObj: UserInfo = {
  id: -1
}

const roleList = ["User", "Judge", "Administrator"]

export {roleList, initUserInfoObj, getInitialsRegEx, getInitials}
