import { CaseStudyInfo, AwardsApi, useAuthContext, AwardSubmissionInfo } from '@pull/pull-groupm-csp-api'
import { FC, useEffect } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { Link } from 'react-router-dom'
import { MenuComponent } from '../../../../_metronic/assets/ts/components'
import { QUERIES } from '../../../Constants/Queries'
import { Confirmation } from '../../../helpers/functions/SwalHelpers'
import { AwardSubmissionWinnerButton } from '../buttons/AwardSubmissionWinnerButton'


type Props = {
    submission: AwardSubmissionInfo
}
const DeliberationsActionCell: FC<Props> = ({ submission }) => {
    const { getApiConfiguration } = useAuthContext();

    const apiConfig = getApiConfiguration();
    const awardsApi = new AwardsApi(apiConfig);
    const queryClient = useQueryClient();
    const submissionPostObj = {
        id: submission.id,
        awardID: submission.awardID,
        caseStudyID: submission.caseStudy?.id,
        isShortListed: submission.shortlisted,
        isWinner: submission.winner
    }

    useEffect(() => {
        MenuComponent.reinitialization()
    }, [])

    const markShortlist = useMutation(() => awardsApi.apiAwardsAwardSubmissionsPost({ ...submissionPostObj, ...{ shortlisted: !submission.shortlisted, shortlistedSpecified: true } }), {
        // 💡 response of the mutation is passed to onSuccess
        onSuccess: () => {
            // ✅ update detail view directly
            queryClient.invalidateQueries([QUERIES.AWARDS.SEARCH+ submission.awardCategoryID])
        },
    })

    return (
        <div className='d-flex ms-auto justify-content-end'>
            <div className="form-check form-switch form-check-custom form-check-solid me-8">
                <input onChange={async () => await markShortlist.mutateAsync()} className="form-check-input h-20px w-30px" type="checkbox" value="" id="activeswitch" checked={submission.shortlisted} />
                <label className="form-check-label" htmlFor="activeswitch">
                    Shortlist
                </label>
            </div>
            <AwardSubmissionWinnerButton awardSubmission={submission}></AwardSubmissionWinnerButton>
        </div>
    )
}

export { DeliberationsActionCell }