/* eslint-disable react-hooks/exhaustive-deps */
import { FC, createContext, useContext, useEffect, useState } from 'react'
import { propTypes } from 'react-bootstrap/esm/Image'
import { Link } from 'react-router-dom'
import { Value } from 'sass'
import { WithChildren } from '../../helpers'

export interface PageLink {
  title: string
  path: string
  isActive: boolean
  isSeparator?: boolean
}

export interface PageDataContextModel {
  pageTitle?: string
  setPageTitle: (_title: string) => void
  pageDescription?: string
  setPageDescription: (_description: string) => void
  pageBreadcrumbs?: Array<PageLink>
  setPageBreadcrumbs: (_breadcrumbs: Array<PageLink>) => void
}

const PageDataContext = createContext<PageDataContextModel>({
  setPageTitle: (_title: string) => { },
  setPageBreadcrumbs: (_breadcrumbs: Array<PageLink>) => { },
  setPageDescription: (_description: string) => { },
})

const PageDataProvider: FC<WithChildren> = ({ children }) => {
  const [pageTitle, setPageTitle] = useState<string>('')
  const [pageDescription, setPageDescription] = useState<string>('')
  const [pageBreadcrumbs, setPageBreadcrumbs] = useState<Array<PageLink>>([])
  const value: PageDataContextModel = {
    pageTitle,
    setPageTitle,
    pageDescription,
    setPageDescription,
    pageBreadcrumbs,
    setPageBreadcrumbs,
  }
  return <PageDataContext.Provider value={value}>{children}</PageDataContext.Provider>
}

function usePageData() {
  return useContext(PageDataContext)
}

type Props = {
  title?: string
  description?: string
  breadcrumbs?: Array<PageLink>,
  hideBreadcrumbs?: boolean
}

const PageTitle: FC<Props & WithChildren> = ({ children, description, breadcrumbs, title, hideBreadcrumbs }) => {
  const { setPageTitle, setPageDescription, setPageBreadcrumbs } = usePageData()
  useEffect(() => {
    if (title) {
      setPageTitle(title)
    }
    return () => {
      setPageTitle('')
    }
  }, [title])

  useEffect(() => {
    if (description) {
      setPageDescription(description)
    }
    return () => {
      setPageDescription('')
    }
  }, [description])

  useEffect(() => {
    if (breadcrumbs) {
      setPageBreadcrumbs(breadcrumbs)
    }
    return () => {
      setPageBreadcrumbs([])
    }
  }, [breadcrumbs])

  return (
    <div className="page-title d-flex flex-column me-3">

      <h1 className="d-flex text-dark fw-bold my-0 fs-2">{title}</h1>
      {!hideBreadcrumbs &&
        <ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-2">
          <>
            <li className="breadcrumb-item text-muted">
              <Link to="/" className="text-gray-650 text-hover-info">Dashboard</Link>
            </li>
            {breadcrumbs?.map((breadcrumb, index) => {
              if (breadcrumb.isSeparator)
                return (<li key={breadcrumb.title + breadcrumb.path + index} className="breadcrumb-item"><span className="bullet bg-gray-650 w-5px h-2px"></span></li>)
              else {
                if (breadcrumb.path)
                  return (<li key={breadcrumb.title + breadcrumb.path} className="breadcrumb-item text-gray-650">
                            <Link to={breadcrumb.path} className='text-gray-650 text-hover-info'>{breadcrumb.title}</Link>
                          </li>)
                else
                  return (<li key={breadcrumb.title + breadcrumb.path} className="breadcrumb-item text-gray-650">{breadcrumb.title}</li>)
              }
            })
            }

          </>
        </ul>}
      {children}
    </div>
  )
}

const PageDescription: FC<WithChildren> = ({ children }) => {
  const { setPageDescription } = usePageData()
  useEffect(() => {
    if (children) {
      setPageDescription(children.toString())
    }
    return () => {
      setPageDescription('')
    }
  }, [children])
  return <></>
}

export { PageDescription, PageTitle, PageDataProvider, usePageData }
