
import { FC, useRef, useState } from "react"
import { PageLink, PageTitle } from "../../../../_metronic/layout/core"
import { ToolbarContainer } from "../../../../_metronic/layout/core/ToolbarContainer"
import PageContainer from "../../../helpers/components/listing/PageContainer"
import { Filter, FilterOption, FilterType } from "../../../helpers/types/Filter"
import SearchCol from "../../../helpers/components/listing/searchCard/SearchCol"
import TableCard from "../../../helpers/components/listing/tableCard/TableCard"
import { QUERIES } from "../../../Constants/Queries"
import { useQuery } from "react-query"
import { AwardsApi, useAuthContext } from "@pull/pull-groupm-csp-api"
import { TableProvider, useTableContext } from "../../../modules/core/functional/TableContext"
import { getDateRange } from "../../../helpers/functions/dateHelper"
import { PagedResultInterface } from "../../../modules/core/_models"
import { judgeActivityColumns } from "../columns/_columns"
import { Link, useNavigate, useParams } from "react-router-dom"
import { LoadingComponent } from "../../../modules/core/LoadingComponent"
import moment from "moment"
import { CardType, SimpleStatCard } from "../../analytics/cards/SimpleStatCard"
import { SVGType } from "../../../helpers/functions/svgHelper"
import { CaseStudyInfoCell } from "../../case-studies/table/columns/CaseStudyInfoCell"

const breadcrumbs: Array<PageLink> = [
    {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
    },
    {
        title: 'Judge Activity',
        path: '/awards/judge-activity',
        isSeparator: false,
        isActive: false,
    },
    {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
    },
    {
        title: 'Judge',
        path: '/awards/judge-activity',
        isSeparator: false,
        isActive: false,
    },
]

const JudgeDetailPage: FC = () => {
    const { id } = useParams()
    const navigate = useNavigate()
    if (!Number(id) || !Number(id)) {
        navigate("/404")
    }
    const [fileUrl, setFileUrl] = useState("#")
    const ref = useRef<HTMLAnchorElement | null>(null)
    const { getApiConfiguration } = useAuthContext()
    const awardsApi = new AwardsApi(getApiConfiguration())

    const { isLoading, data: judge } = useQuery([QUERIES.AWARDS.GET_JUDGE + id], () => {
        return awardsApi.apiAwardsJudgeIdGet(Number(id))
    }, {})

    
    const downloadCSV = async (e: any) => {

        e.preventDefault()

        var data = await awardsApi.apiAwardsJudgeIdCsvGet(judge?.id ?? 0)
                    .then((result) => { return result.blob() })

        if (data.size != 0) {
            const url = URL.createObjectURL(new Blob([data]))
            setFileUrl(url);
            setTimeout(() => {
                ref.current?.click();
            }, 300)
        }
    }

    if (isLoading)
        return <LoadingComponent />

    return (
        <>
            <ToolbarContainer>
                <PageTitle breadcrumbs={breadcrumbs} title="Judge">
                    <div className="mt-6">
                        <Link to="/awards/judge-activity" className="btn btn-sm btn-dark fw-bold">
                            <span className="svg-icon svg-icon-2">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect opacity="0.5" x="6" y="11" width="13" height="2" rx="1" fill="currentColor"></rect>
                                    <path d="M8.56569 11.4343L12.75 7.25C13.1642 6.83579 13.1642 6.16421 12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75L5.70711 11.2929C5.31658 11.6834 5.31658 12.3166 5.70711 12.7071L11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25C13.1642 17.8358 13.1642 17.1642 12.75 16.75L8.56569 12.5657C8.25327 12.2533 8.25327 11.7467 8.56569 11.4343Z" fill="currentColor"></path>
                                </svg>
                            </span>
                            Back to Judge Activity
                        </Link>
                    </div>
                </PageTitle>
            </ToolbarContainer>
            <PageContainer>
                <div className="d-flex flex-column flex-lg-row">
                    <div className="flex-column flex-lg-row-auto w-100 w-lg-300px mb-8 me-xl-10 me-5">
                        <div className="card mb-5 mb-xl-8">
                            <div className="card-body">
                                <div className="d-flex flex-center flex-column pt-5">
                                    <div className="symbol symbol-circle symbol-100px mb-7">
                                        <span className="symbol-label fs-3 bg-light-info text-info fw-bold">ZT</span>
                                    </div>
                                    <span className="fs-3 text-gray-800 fw-bold mb-3">
                                        {judge?.user?.displayName}
                                    </span>
                                    <div className="mb-9">
                                        <div className="badge badge-lg badge-light-info d-inline">Judge</div>
                                    </div>
                                </div>
                                <div className="fs-6">
                                    <div className="fw-bold mt-5">Email</div>
                                    <div className="text-gray-600">{judge?.user?.email}</div>
                                    <div className="fw-bold mt-5">User Created</div>
                                    <div className="text-gray-600">{moment(judge?.user?.created).format("DD/MM/YYYY - HH:mm")}</div>
                                    <div className="fw-bold mt-5">Last login</div>
                                    <div className="text-gray-600">{moment(judge?.user?.lastLogIn).format("DD/MM/YYYY - HH:mm")}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flex-lg-row-fluid mb-lg-8">
                        <div className="row g-5 g-xl-8">
                            <div className="col-lg-4 d-flex">
                                <SimpleStatCard heading="Award contributions" statistic={(judge?.statistics?.awardContributions ?? 0).toString()} icon={SVGType.Award} type={CardType.Success} className="mb-5 w-100"></SimpleStatCard>
                            </div>
                            <div className="col-lg-4 d-flex">
                                <SimpleStatCard heading="Individual case studies scored" statistic={(judge?.statistics?.caseStudiesScored ?? 0).toString()} icon={SVGType.CaseStudy} type={CardType.Primary} className="mb-5 w-100"></SimpleStatCard>
                            </div>
                            <div className="col-lg-4 d-flex">
                                <SimpleStatCard heading="Average review score" statistic={(judge?.statistics?.averageAwardScore ?? 0).toString()} icon={SVGType.Tick} type={CardType.Info} className="mb-5 w-100"></SimpleStatCard>
                            </div>
                        </div>
                        <div className="card card-xl-stretch mb-5 mb-xl-8">
                            <div className="card-header border-0 pt-8">
                                <h3 className="card-title align-items-start flex-column">
                                    <span className="card-label fw-bold fs-2 mb-1">Contributions</span>
                                </h3>
                            </div>
                            {judge?.statistics?.awardScores ?
                                <>
                                    <div className="card-body py-3">
                                        <div className="py-3">
                                            <div className="accordion mb-9" id="kt_accordion_1">
                                                {judge.statistics.awardScores.map((award, index) => {
                                                    return (
                                                        <div className="accordion-item">
                                                            <h2 className="accordion-header" id={"kt_accordion_1_header_" + index}>
                                                                <button className="accordion-button fs-6 fw-semibold collapsed" type="button" data-bs-toggle="collapse" data-bs-target={"#kt_accordion_1_body_" + index} aria-expanded="false" aria-controls={"kt_accordion_1_body_" + index}>
                                                                    {award.awardName} <span className="ms-5 badge badge-light-info">{award.scores?.length} Entries</span>
                                                                </button>
                                                            </h2>
                                                            <div id={"kt_accordion_1_body_" + index} className="accordion-collapse collapse" aria-labelledby={"kt_accordion_1_header_" + index}>
                                                                <div className="accordion-body">
                                                                    <div className="table-responsive">
                                                                        <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
                                                                            <thead>
                                                                                <tr className="fw-bold text-gray-650">
                                                                                    <th className="">Name</th>
                                                                                    <th className="">Country</th>
                                                                                    <th className="">Region</th>
                                                                                    <th className="min-w-100px text-end">Score</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                {award.scores?.map((score, index) => {
                                                                                    return (
                                                                                        <tr>
                                                                                            <td>
                                                                                                <CaseStudyInfoCell caseStudy={score.caseStudy}></CaseStudyInfoCell>
                                                                                            </td>
                                                                                            <td>
                                                                                                <span className="text-dark fw-bold fs-6">{score.caseStudy?.countries ? score.caseStudy?.countries[0]?.name ?? "" : ""}</span>
                                                                                            </td>
                                                                                            <td>
                                                                                                <span className="text-dark fw-bold fs-6">{score.caseStudy?.regions ? score.caseStudy?.regions[0]?.name ?? "" : ""}</span>
                                                                                            </td>
                                                                                            <td>
                                                                                                <div className="d-flex align-items-center justify-content-end">
                                                                                                    <div className="symbol symbol-35px symbol-circle me-4">
                                                                                                        <span className="symbol-label bg-info text-white fw-semibold">{score.score}</span>
                                                                                                    </div>
                                                                                                    <div className="text-muted fw-semibold text-gray-650 d-block fs-7">{moment(score.scoreDate).format("DD/MM/YYYY")}</div>
                                                                                                </div>
                                                                                            </td>
                                                                                        </tr>
                                                                                    )
                                                                                })}
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-footer sticky bottom-0 d-flex justify-content-between gap-5">
                                        <a onClick={downloadCSV} className="btn btn-sm btn-bg-light btn-color-gray-700 btn-active-color-info">
                                            <span className="svg-icon svg-icon-2">
                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M21 22H3C2.4 22 2 21.6 2 21C2 20.4 2.4 20 3 20H21C21.6 20 22 20.4 22 21C22 21.6 21.6 22 21 22ZM13 13.4V3C13 2.4 12.6 2 12 2C11.4 2 11 2.4 11 3V13.4H13Z" fill="currentColor"></path>
                                                    <path opacity="0.3" d="M7 13.4H17L12.7 17.7C12.3 18.1 11.7 18.1 11.3 17.7L7 13.4Z" fill="currentColor"></path>
                                                </svg>
                                            </span>
                                            Download list
                                        </a>
                                    </div>
                                    <a href={fileUrl} download={`scores-${judge.user?.displayName?.toLowerCase()}.csv`} className="hidden" ref={ref} ></a>
                                </>

                                : ""}
                        </div>
                    </div>
                </div>
            </PageContainer>
        </>
    )
}

export default JudgeDetailPage