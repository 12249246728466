import { UserInfo } from '@pull/pull-groupm-csp-api'
import clsx from 'clsx'
import {FC, PropsWithChildren, useMemo} from 'react'
import {HeaderProps} from 'react-table'

type Props = {
  className?: string
  title?: string
  tableProps: PropsWithChildren<HeaderProps<any>>
}
const CustomHeader: FC<Props> = ({className, title, tableProps}) => {
  const id = tableProps.column.id


  return (
    <th
      {...tableProps.column.getHeaderProps()}
      className={clsx(
        className,
        // isSelectedForSorting && order !== undefined && `table-sort-${order}`
      )}
      style={{cursor: 'pointer'}}
      // onClick={sortColumn}
    >
      {title}
    </th>
  )
}

export {CustomHeader}
