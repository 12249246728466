import { createContext, FC, useCallback, useContext, useEffect, useState } from "react"
import { WithChildren } from "../../../_metronic/helpers"
import { Filter, FilterOption, FilterType } from "../../helpers/types/Filter"
import { useGlobalContext } from "../core/GlobalContext"
import { roleList } from "./_models"

type UserContextProps = {
    selectedEmail: string|null
    updateEmail: (email:string|null) => void
    showUserEditModal: boolean
    userFilters : Array<Filter>
}

const initUserContextPropsState = {
    selectedEmail : null,
    updateEmail: (email:string|null) => {},
    showUserEditModal: false,
    userFilters: [],
    children: <></>
}

const UserContext = createContext<UserContextProps>(initUserContextPropsState)

const useUserContext = () => {
    return useContext(UserContext)
}

const UserProvider: FC<WithChildren> = ({children}) => {
    const [selectedEmail, setEditEmail] = useState<string|null>(null)
    const [showUserEditModal, setShowUserEditModal] = useState(false)
    const {countries,regions} = useGlobalContext()
  const countryOptions = countries.map((country) => {return {text: country.name, value: country.id, isSelected:false} as FilterOption})
  const regionOptions = regions.map((region) => {return {text: region.name, value: region.id, isSelected:false} as FilterOption})
  const userFilters: Array<Filter> = [
    {
      title: "Role",
      type: FilterType.Dropdown,
      options: [{ text: "Any", value: "", isSelected: true }, ...roleList.map((role) => {return {text: role, value: role, isSelected:false} as FilterOption})],
    },
    {
      title: "Country",
      type: FilterType.Dropdown,
      options: [{ text: "Any", value: 0, isSelected: true },...countryOptions ],
    },
    {
      title: "Region",
      type: FilterType.Dropdown,
      options: [{ text: "Any", value: 0, isSelected: true }, ...regionOptions],
    }
  ]
    const updateEmail = useCallback((email:string|null) => {
        setEditEmail(email)
    },[])

    useEffect(() =>{
        setShowUserEditModal(selectedEmail !== null)
    },[selectedEmail])

    return (
        <UserContext.Provider value={{selectedEmail, updateEmail, showUserEditModal, userFilters}} >
            {children}
        </UserContext.Provider>
    )
}

export {useUserContext, UserProvider, UserContext}
