import { useAuthContext, UsersApi } from '@pull/pull-groupm-csp-api';
import { FC, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import {useQuery} from 'react-query'
import { useAwardSubmissionWinnerModalContext } from './AwardSubmissionWinnerModalContext';
import { AwardSubmissionWinnerModal } from './AwardSubmissionWinnerModal';


const AwardSubmissionWinnerModalWrapper: FC = () => {
  const modalRoot = document.getElementById("modal-root") as HTMLElement
  const {getApiConfiguration} = useAuthContext()
  const {selectedValue:submission, showAwardSubmissionWinnerModal} = useAwardSubmissionWinnerModalContext()

  if (submission && showAwardSubmissionWinnerModal) {
    return ReactDOM.createPortal(<AwardSubmissionWinnerModal />, modalRoot)
  }


  return null
}

export {AwardSubmissionWinnerModalWrapper}
