import {FC,ReactNode} from 'react'

export type ButtonModel = {
    children?: ReactNode,
    action :(id:any|undefined) => void,
    className: string
}

const AnchorButton: FC<ButtonModel> = ({action, children, className}) =>{
return (
        <a href="#" onClick={action} className={`btn ${className}`}>
                       {children}
                    </a>
    )
}

export default AnchorButton