import { createContext, FC, ReactNode, useContext } from "react"
import { PageLink } from "../../../_metronic/layout/core"
import { Filter, FilterType } from "../../helpers/types/Filter"

enum CRContextType {
    Region = "Regions",
    Country = "Countries"
}

type CRContextProps = {
    type: CRContextType,
    filters: Filter[],
    breadcrumbs: PageLink[]
}


const initCRContextPropsState = {
    filters:[],
    breadcrumbs:[],
    type: CRContextType.Region,
    children:<></>
}

const CountryRegionContext = createContext<CRContextProps>(initCRContextPropsState)

const useCRContext = () =>{
    return useContext(CountryRegionContext)
}

type ProviderProps ={
    children: ReactNode,
    type: CRContextType
}

const CRProvider:FC<ProviderProps> = ({children,type}) =>{

    const filters:Filter[] = [
        {
            title:"Include Disabled",
            name:"includeDisabled",
            type: FilterType.Checkbox,
            options: [{text:"Show disabled", isSelected:false}]
        }
    ]

    const breadcrumbs: Array<PageLink> = [
        {
          title: '',
          path: '',
          isSeparator: true,
          isActive: false,
        },
        {
          title: type,
          path: `/${type.toLowerCase()}`,
          isSeparator: false,
          isActive: false,
        },
      
      ]

    return ( 
        <CountryRegionContext.Provider value={{filters, type, breadcrumbs}} >
            {children}
        </CountryRegionContext.Provider>
    )
}
export {CRProvider,useCRContext, CRContextType}