
import { createContext, FC, ReactNode, useCallback, useContext, useEffect, useState } from "react"

type DownloadSubmissionsModalContextProps = {
    selectedValue: any|null,
    updateSelectedValue: (value:any|null) => void,
    showDownloadSubmissionsModal: boolean
}


const initModalContextPropsState = {
    selectedValue:null,
    updateSelectedValue:(value:any|null) => {},
    showDownloadSubmissionsModal:false,
    children:<></>
}

const DownloadSubmissionsModalContext = createContext<DownloadSubmissionsModalContextProps>(initModalContextPropsState)

const useDownloadSubmissionsModalContext = () =>{
    return useContext(DownloadSubmissionsModalContext)
}

type ProviderProps ={
    children: ReactNode
}

const DownloadSubmissionsModalProvider:FC<ProviderProps> = ({children}) =>{
    const [selectedValue, setValue] = useState<any>(null)
    const [showDownloadSubmissionsModal, setShowDownloadSubmissionsModal] = useState(false)

    const updateSelectedValue = useCallback((value:any) =>{
        setValue(value)
    },[])

    useEffect(()=>{
        setShowDownloadSubmissionsModal(selectedValue !== null)
    },[selectedValue])

    return ( 
        <DownloadSubmissionsModalContext.Provider value={{selectedValue,updateSelectedValue, showDownloadSubmissionsModal}} >
            {children}
        </DownloadSubmissionsModalContext.Provider>
    )
}
export {DownloadSubmissionsModalProvider,useDownloadSubmissionsModalContext}