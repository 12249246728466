import { Suspense } from 'react'
//import { ThemeModeComponent } from '../_metronic/assets/ts/layout'
import { LayoutProvider, LayoutSplashScreen } from '../_metronic/layout/core'
import { ThemeModeProvider } from '../_metronic/partials/layout/theme-mode/ThemeModeProvider'
import { MasterInit } from './layout/MasterInit'
import { MasterLayout } from './layout/MasterLayout'

const App = () => {

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <ThemeModeProvider>
      <LayoutProvider >
          <MasterLayout />
          <MasterInit />
      </LayoutProvider>
      </ThemeModeProvider>
    </Suspense>
  )
}

export { App }
