import { AwardInfo, AwardInfoStatus, useAuthContext } from "@pull/pull-groupm-csp-api"
import { FC, ReactNode } from "react"
import { useEditModalContext } from "../../../modules/core/functional/EditModalContext"
import { AwardDownloadSubmissionsModal, AwardEditModal, AwardModalTypes } from "../model/_models"
import { useDownloadSubmissionsModalContext } from "../modals/DownloadSubmissionsModalContext"

type Props = {
    awardID?: number,
    awardCategoryID: number,
    year?: number,
    fileName: string
    className:string
    children:ReactNode
}

const DownloadSubmissionsButton: FC<Props> = ({ awardCategoryID, awardID, year, fileName, className, children }) => {
    const { updateSelectedValue } = useDownloadSubmissionsModalContext()

    const openDownloadSubmissionsModal = () => {
        const modal: AwardDownloadSubmissionsModal = {
           awardCategoryID,
           awardID,
           year,
           fileName
        }
        updateSelectedValue(modal)
    }

    return (
        <button onClick={ openDownloadSubmissionsModal } type="button" className={className} data-bs-toggle="modal" data-bs-target="#kt_modal_submissions_edit_award_details">
            {children}
        </button>
    )
}

export { DownloadSubmissionsButton }