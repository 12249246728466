import React, { FC, useEffect, useState } from 'react'
import { Filter } from '../../../types/Filter'
import { KTCard, KTCardBody } from "../../../../../_metronic/helpers"
import { setFlagsFromString } from 'v8'
import FilterInput from './Filter'
import { formDataToFilterObject } from '../../../functions/dataTransformationHelper'
import { useTableContext } from '../../../../modules/core/functional/TableContext'
import { MenuComponent } from '../../../../../_metronic/assets/ts/components'

export type SearchForm = {
    text: string,
    filters: [{
        key: string,
        value: string
    }]

}


export type SearchModel = {
    filters: Array<Filter>
    submitSearch: (params: any) => any
    searchName: string
    searchPlaceholder?: string
}

const SearchCol: FC<SearchModel> = ({ filters, submitSearch, searchName, searchPlaceholder }) => {
    const [key, setKey] = useState(0);
    const [searchString, setSearchString] = useState("")
    const { updateFilters } = useTableContext()

    useEffect(() => {
        MenuComponent.reinitialization();
    }, []);

    const submitFormHandler = (e: any) => {
        e.preventDefault();
        let data = new FormData(e.target);
        submitSearch(formDataToFilterObject(data))
    }
    const searchChangeHandler = (e: any) => {
        setSearchString(e.target.value)
    }
    const cancelHandler = () => {
        setKey(prevState => (prevState + 1))
        setSearchString("")
        updateFilters({})
    }

    return (
        <div className="flex-column flex-lg-row-auto w-100 w-lg-300px mb-8 me-xl-10 me-5">
            <form action="#" className="sticky-lg-top" onSubmit={submitFormHandler}>
                <KTCard >
                    <KTCardBody>
                        <>
                            <div className="position-relative">
                                <input onChange={searchChangeHandler} type="text" className="form-control form-control-solid" value={searchString} name={searchName} placeholder={searchPlaceholder ?? "Search"} />
                            </div>
                            <div className="separator separator-dashed my-8"></div>
                            {filters.map((filterObj) => {
                                return (
                                    <FilterInput key={filterObj.title + key} filter={filterObj} index={filters.indexOf(filterObj)} />
                                )
                            })}
                            <div className="d-flex align-items-center justify-content-end">
                                <button onClick={cancelHandler} type="button" className="btn btn-color-gray-650 me-3">Cancel</button>
                                <button type="submit" className="btn btn-info">Search</button>
                            </div>
                        </>
                    </KTCardBody>
                </KTCard>
            </form>
        </div>
    )
}

export default SearchCol