import { SearchTagListRequest, TagInfo, TagsApi, TagValueInfo, useAuthContext } from "@pull/pull-groupm-csp-api"
import { FC, useEffect, useState } from "react"
import { Link, useNavigate, useParams } from "react-router-dom"
import { PageLink, PageTitle } from "../../../_metronic/layout/core"
import { ToolbarContainer } from "../../../_metronic/layout/core/ToolbarContainer"
import { QUERIES } from "../../Constants/Queries"
import AnchorButton from "../../helpers/components/generic/AnchorButton"
import PageContainer from "../../helpers/components/listing/PageContainer"
import SearchCol from "../../helpers/components/listing/searchCard/SearchCol"
import TableCard from "../../helpers/components/listing/tableCard/TableCard"
import { Filter } from "../../helpers/types/Filter"
import { EditModalProvider, useEditModalContext } from "../../modules/core/functional/EditModalContext"
import { TableProvider, useTableContext } from "../../modules/core/functional/TableContext"
import { useGlobalContext } from "../../modules/core/GlobalContext"
import { PagedResultInterface } from "../../modules/core/_models"
import { TagProvider, useTagContext } from "../../modules/tag/TagContext"
import { tagValueColumns } from "./columns/_columns"
import { TagEditModalFormWrapper } from "./edit-tag-modal/TagValueEditModalWrapper"

const TagListPage: FC = () => {
  const { id } = useParams()
  const { updatePage, updateFilters } = useTableContext()
  const { tags } = useGlobalContext()
  const { tagFilters, updateTagParentID } = useTagContext()
  const { updateSelectedValue, showEditModal } = useEditModalContext()
  const navigate = useNavigate()
  if (!Number(id) || !tags) {
    navigate("/404")
  }

  const currentTag = tags && id ? (Object.values(tags) as TagInfo[]).find((tag: TagInfo) => (tag && tag.id === Number(id)))?.name : null
  const { getApiConfiguration } = useAuthContext()
  const tagApi = new TagsApi(getApiConfiguration())

  useEffect(() => {
    updateTagParentID(Number(id))
  }, [id])

  const getTagList = async (pageParam = 1, filters = {}) => {
    const requestParam: SearchTagListRequest = { ...filters, ...{ tagID: Number(id), page: pageParam, pageSize: 10 } }
    let response = await tagApi.apiTagsSearchPost(requestParam)
    return response as PagedResultInterface
  }

  const submitSearchHandler = (searchFields: any) => {
    updateFilters({ name: searchFields.name, onlyActive: searchFields.activeOnly ? true : false })//JSON.parse(searchFields)))
  }

  const openTagModal = () => {
    updateSelectedValue({ id: 0 })
  }

  const tagListBreadcrumbs: Array<PageLink> = [
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
    {
      title: 'Tags',
      path: '/tags',
      isSeparator: false,
      isActive: true,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
    {
      title: currentTag ?? "",
      path: '',
      isSeparator: false,
      isActive: false
    }

  ]

  return (
    <>
    <ToolbarContainer>
      <PageTitle breadcrumbs={tagListBreadcrumbs} title={`${currentTag} `}>
      <div className="mt-6">
                        <Link to="/tags" className="btn btn-sm btn-dark fw-bold">
                            <span className="svg-icon svg-icon-2">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect opacity="0.5" x="6" y="11" width="13" height="2" rx="1" fill="currentColor" />
                                    <path d="M8.56569 11.4343L12.75 7.25C13.1642 6.83579 13.1642 6.16421 12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75L5.70711 11.2929C5.31658 11.6834 5.31658 12.3166 5.70711 12.7071L11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25C13.1642 17.8358 13.1642 17.1642 12.75 16.75L8.56569 12.5657C8.25327 12.2533 8.25327 11.7467 8.56569 11.4343Z" fill="currentColor" />
                                </svg>
                            </span>
                            Back to Tags
                        </Link>
                    </div>
      </PageTitle>
      </ToolbarContainer>
      <TagEditModalFormWrapper showModal={showEditModal} />
      <PageContainer >
      <div className="d-flex flex-column flex-lg-row">
        <SearchCol filters={tagFilters} submitSearch={submitSearchHandler} searchName="name" />
        <TableCard tableCols={tagValueColumns} getData={getTagList} query={QUERIES.TAG.SEARCH + id}>
          <AnchorButton className="btn-info" action={openTagModal}>
            <>
              <span className="svg-icon svg-icon-2">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect opacity="0.5" x="11.364" y="20.364" width="16" height="2" rx="1" transform="rotate(-90 11.364 20.364)" fill="currentColor"></rect>
                  <rect x="4.36396" y="11.364" width="16" height="2" rx="1" fill="currentColor"></rect>
                </svg>
              </span>
              New Tag
            </>
          </AnchorButton>
        </TableCard>
        </div>
      </PageContainer>
    </>)
}

const TagListPageWrapper = () => {
  return (
    <TagProvider>
      <TableProvider>
        <EditModalProvider>
          <TagListPage />
        </EditModalProvider>
      </TableProvider>
    </TagProvider>
  )
}

export { TagListPageWrapper, TagListPage }