import { FC, ReactNode } from "react"

type Props = {
    message: string,
    title?:string
}


const NotificationBanner: FC<Props> = ({ message, title }) => {
    return (
        <div className="notice d-flex bg-light-primary rounded border-primary border mb-9 p-6">
            <span className="svg-icon svg-icon-2tx svg-icon-primary me-4">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="currentColor" />
                    <rect x="11" y="17" width="7" height="2" rx="1" transform="rotate(-90 11 17)" fill="currentColor" />
                    <rect x="11" y="9" width="2" height="2" rx="1" transform="rotate(-90 11 9)" fill="currentColor" />
                </svg>
            </span>
            <div className="d-flex flex-stack flex-grow-1">
                <div className="fw-semibold">
                    {title &&
                    <h4 className="text-gray-900 fw-bold">{title}</h4>
                    }
                    <div className="fs-6 text-gray-800">
                        {message}
                    </div>
                </div>
            </div>
        </div>
    )
}

const ErrorBanner:FC<Props> = ({message}) =>{
    return (
            <div className="notice d-flex bg-light-danger rounded border-danger border mb-9 p-6">
            <span className="svg-icon svg-icon-2tx svg-icon-danger me-4">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="currentColor"></rect>
                <rect x="11" y="14" width="7" height="2" rx="1" transform="rotate(-90 11 14)" fill="currentColor"></rect>
                <rect x="11" y="17" width="2" height="2" rx="1" transform="rotate(-90 11 17)" fill="currentColor"></rect>
              </svg>
            </span>
            <div className="d-flex flex-stack flex-grow-1">
              <div className="fw-semibold">
                <h4 className="text-gray-900 fw-bold">Error</h4>
                <div className="fs-6 text-gray-800">{message}
                </div>
              </div>
            </div>
          </div>
    )
}

const WarningBanner:FC<Props> = ({message}) => {
    return (
        <div className="notice d-flex bg-light-warning rounded border-warning border mb-9 p-6">
								<span className="svg-icon svg-icon-2tx svg-icon-warning me-4">
									<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
										<rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="currentColor"></rect>
										<rect x="11" y="14" width="7" height="2" rx="1" transform="rotate(-90 11 14)" fill="currentColor"></rect>
										<rect x="11" y="17" width="2" height="2" rx="1" transform="rotate(-90 11 17)" fill="currentColor"></rect>
									</svg>
								</span>
								<div className="d-flex flex-stack flex-grow-1">
									<div className="fw-semibold">
										<h4 className="text-gray-900 fw-bold">Warning</h4>
											<div className="fs-6 text-gray-800">
                                                {message}
											</div>
										
									</div>
								</div>
							</div>
    )
}

export {NotificationBanner,ErrorBanner, WarningBanner}