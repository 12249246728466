import { AwardInfo, AwardInfoStatus, AwardsApi, useAuthContext } from "@pull/pull-groupm-csp-api"
import { FC, useEffect, useState } from "react"
import { Column } from "react-table"
import { DownloadAnchor } from "../../../helpers/components/generic/DownloadAnchor"
import { NotificationBanner } from "../../../helpers/components/generic/NotificationBanner"
import TableComponent from "../../../helpers/components/listing/tableCard/TableComponent"
import { useEditModalContext } from "../../../modules/core/functional/EditModalContext"
import { AwardSettingsButton } from "../buttons/AwardSettingsButton"
import { AwardEditModal, AwardModalTypes, Tabs } from "../model/_models"
import { DownloadSubmissionsButton } from "../buttons/DownloadSubmissionsButton"

type Props = {
	title?: string
	award: AwardInfo,
	tableCols: ReadonlyArray<Column<any>>
	awardCat: number
}


const AwardTabTable: FC<Props> = ({ title, award, tableCols, awardCat }) => {
	const { updateSelectedValue } = useEditModalContext()
	const { getApiConfiguration } = useAuthContext()


	const openAddSubmissions = () => {
		const modal: AwardEditModal = {
			type: AwardModalTypes.Submission,
			info: { id: award.id, awardCat: awardCat, submissions: award.submissions?.map((submission) => { return submission.caseStudy?.id }) ?? [] }
		}
		updateSelectedValue(modal)
	}

	const submissionSubtitleText = () => {
		switch (award.status) {
			case AwardInfoStatus.Submissions:
				return `${award.submissions?.length ?? 0} Submissions - open until ${new Date(award.submissionClosingDate ?? "").toLocaleDateString()}`
			case AwardInfoStatus.Judging:
				return `${award.submissions?.length ?? 0} Submissions - open until ${new Date(award.judgingClosingDate ?? "").toLocaleDateString()}`
			case AwardInfoStatus.Deliberations:
				return `${award.submissions?.length ?? 0} Submissions for deliberation`
			default:
				return `${award.submissions?.length ?? 0} Submissions`
		}
	}

	return (
		<div className="tab-pane fade active show">
			<div id={`${award.id}-submission`}>
				<div className="d-flex flex-wrap justify-content-between align-items-center pt-3 pb-5">
					<h3 className="d-flex align-items-start flex-column">
						<span className="card-label fw-bold fs-2 mb-1">{title ?? award.name}</span>
						<span className="text-gray-650 mt-1 fw-semibold fs-6">{submissionSubtitleText()}</span>
					</h3>
					<div className="d-flex flex-row-fluid justify-content-end gap-5">
						{(award.status === AwardInfoStatus.Judging || award.status === AwardInfoStatus.Submissions || award.status == AwardInfoStatus.Deliberations) &&
							<AwardSettingsButton award={award} awardCatId={awardCat} className="btn btn-dark fw-bold">
								<span className="svg-icon svg-icon-2">
									<svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
										<path opacity="0.3" fill-rule="evenodd" clip-rule="evenodd" d="M2 4.63158C2 3.1782 3.1782 2 4.63158 2H13.47C14.0155 2 14.278 2.66919 13.8778 3.04006L12.4556 4.35821C11.9009 4.87228 11.1726 5.15789 10.4163 5.15789H7.1579C6.05333 5.15789 5.15789 6.05333 5.15789 7.1579V16.8421C5.15789 17.9467 6.05333 18.8421 7.1579 18.8421H16.8421C17.9467 18.8421 18.8421 17.9467 18.8421 16.8421V13.7518C18.8421 12.927 19.1817 12.1387 19.7809 11.572L20.9878 10.4308C21.3703 10.0691 22 10.3403 22 10.8668V19.3684C22 20.8218 20.8218 22 19.3684 22H4.63158C3.1782 22 2 20.8218 2 19.3684V4.63158Z" fill="currentColor"></path>
										<path d="M10.9256 11.1882C10.5351 10.7977 10.5351 10.1645 10.9256 9.77397L18.0669 2.6327C18.8479 1.85165 20.1143 1.85165 20.8953 2.6327L21.3665 3.10391C22.1476 3.88496 22.1476 5.15129 21.3665 5.93234L14.2252 13.0736C13.8347 13.4641 13.2016 13.4641 12.811 13.0736L10.9256 11.1882Z" fill="currentColor"></path>
										<path d="M8.82343 12.0064L8.08852 14.3348C7.8655 15.0414 8.46151 15.7366 9.19388 15.6242L11.8974 15.2092C12.4642 15.1222 12.6916 14.4278 12.2861 14.0223L9.98595 11.7221C9.61452 11.3507 8.98154 11.5055 8.82343 12.0064Z" fill="currentColor"></path>
									</svg>
								</span>
								Award settings
							</AwardSettingsButton>
						}
						{award.status == AwardInfoStatus.Submissions &&
							<a onClick={openAddSubmissions} href="#" className="btn btn-info" data-bs-toggle="modal" data-bs-target="#kt_modal_submissions_add_case_study">
								<span className="svg-icon svg-icon-2">
									<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
										<rect opacity="0.5" x="11.364" y="20.364" width="16" height="2" rx="1" transform="rotate(-90 11.364 20.364)" fill="currentColor"></rect>
										<rect x="4.36396" y="11.364" width="16" height="2" rx="1" fill="currentColor"></rect>
									</svg>
								</span>
								Add submission
							</a>
						}
					</div>
				</div>
				{award.status === AwardInfoStatus.Closed &&
					<NotificationBanner title="Status" message="This award is now closed" />
				}
				<div className="table-responsive">
					<TableComponent cols={tableCols} data={award.submissions ?? []} />
				</div>
				<div className="card-footer sticky bottom-0 d-flex justify-content-between mx-n9 gap-5">
					{(award.submissions?.length && award.submissions?.length > 0) ?

						<DownloadSubmissionsButton awardCategoryID={awardCat} awardID={award.id} fileName={`${award.categoryName}-${award.name}_submissions.csv`} className="btn btn-sm btn-bg-light btn-color-gray-700 btn-active-color-info">
							<span className="svg-icon svg-icon-2">
								<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path d="M21 22H3C2.4 22 2 21.6 2 21C2 20.4 2.4 20 3 20H21C21.6 20 22 20.4 22 21C22 21.6 21.6 22 21 22ZM13 13.4V3C13 2.4 12.6 2 12 2C11.4 2 11 2.4 11 3V13.4H13Z" fill="currentColor"></path>
									<path opacity="0.3" d="M7 13.4H17L12.7 17.7C12.3 18.1 11.7 18.1 11.3 17.7L7 13.4Z" fill="currentColor"></path>
								</svg>
							</span>
							Download submissions
						</DownloadSubmissionsButton>
						: null
					}
				</div>
			</div>
		</div>
	)

}

export { AwardTabTable }