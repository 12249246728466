import { CountryInfo, RegionInfo, SettingsApi, useAuthContext } from "@pull/pull-groupm-csp-api"
import { FC, useEffect } from "react"
import { useMutation, useQueryClient } from "react-query"
import { Value } from "sass"
import { MenuComponent } from "../../../../_metronic/assets/ts/components"
import { QUERIES } from "../../../Constants/Queries"
import { Delete } from "../../../helpers/functions/SwalHelpers"
import { useEditModalContext } from "../../../modules/core/functional/EditModalContext"
import { CRContextType, useCRContext } from "../../../modules/country-region/CountryRegionContext"

type Props = {
    row:RegionInfo|CountryInfo
}


const CRActionCell:FC<Props> = ({row}) => {
    const { getApiConfiguration } = useAuthContext();
    const {type} = useCRContext();
    const {updateSelectedValue} = useEditModalContext();

    const apiConfig = getApiConfiguration();
    const settingApi = new SettingsApi(apiConfig);

    const queryClient = useQueryClient()

    useEffect(() => {
        MenuComponent.reinitialization()
      }, [])
    
    const enableItem = useMutation(() => {
        if(type === CRContextType.Country){
            return settingApi.apiSettingsCountriesPost({id:row.id, enabled:!row.enabled})
        }
        else{
            return settingApi.apiSettingsRegionsPost({id:row.id, enabled:!row.enabled})
        }
    },{
        onSuccess:() =>{
            queryClient.invalidateQueries([QUERIES.GLOBAL.INITIAL])
            queryClient.invalidateQueries([QUERIES.GLOBAL.SEARCH + type])
        }
    })

    const editItem = () =>{
        updateSelectedValue(row)
    }

    const deleteOnClick = () =>{
        var typeName = type === CRContextType.Country ? "country" : "region";
        Delete(typeName,  "This will also update all case studies using this " + typeName)
        .then(async (result) =>{
          if(result.isConfirmed){
            await deleteItem.mutateAsync()
          }
        })
      }

      
    const deleteItem = useMutation(() => {
        if(type === CRContextType.Country){
            return settingApi.apiSettingsCountriesIdDelete(row.id??0)
        }
        else{
            return settingApi.apiSettingsRegionsIdDelete(row.id??0)
        }
    },{
        // 💡 response of the mutation is passed to onSuccess
        onSuccess: () => {
          // ✅ update detail view directly
          queryClient.invalidateQueries([QUERIES.GLOBAL.INITIAL])
          queryClient.invalidateQueries([QUERIES.GLOBAL.SEARCH + type])
        },
      })
    return (
        <div className="d-flex ms-auto justify-content-end">
            <div className="form-check form-switch form-check-custom form-check-solid me-8">
                <input onChange={async () => await enableItem.mutateAsync()} className="form-check-input h-20px w-30px" type="checkbox" value="true" name="activeswitch" checked={row.enabled} />
                <label className="form-check-label" htmlFor="activeswitch">
                    Active
                </label>
            </div>
            <button onClick={editItem} type="button" className="btn btn-bg-light btn-color-gray-700 btn-active-color-info btn-sm px-4 me-2">Edit</button>
            <button onClick={deleteOnClick} type="button" className="btn btn-bg-light btn-color-gray-700 btn-active-color-danger btn-sm px-4">Delete</button>
        </div>
    )
}

export default CRActionCell