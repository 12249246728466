import { AwardInfo } from "@pull/pull-groupm-csp-api"
import { FC } from "react"
import { useEditModalContext } from "../../../modules/core/functional/EditModalContext"
import { AwardEditModal, AwardModalTypes } from "../model/_models"
import {NotificationBanner} from "../../../helpers/components/generic/NotificationBanner"
import { AwardSettingsButton} from "../buttons/AwardSettingsButton"

type Props = {
    awards: AwardInfo[]
    awardCat:number
}

const UpcomingAwards: FC<Props> = ({ awards,awardCat }) => {
    const {updateSelectedValue} = useEditModalContext()

    const newAwardHandler = () => {
        const modal:AwardEditModal = {type:AwardModalTypes.AwardSettings, info:{id:0, awardCategoryID:awardCat}}
        updateSelectedValue(modal)
    }

    
    return (
        <div id="upcoming" className="tab-pane fade active show" role="tabpanel" aria-labelledby="upcoming_tab_btn">
            <div className="d-flex flex-wrap justify-content-between align-items-center pt-3 pb-5">

                <h3 className="d-flex align-items-start flex-column">
                    <span className="card-label fw-bold fs-2 mb-1">Scheduled Awards</span>
                    <span className="text-gray-650 mt-1 fw-semibold fs-6">{awards.length} Awards upcoming</span>
                </h3>
            </div>
            <NotificationBanner message="Create all upcoming awards in this tab.  These scheduled awards will automatically move to the full judging process based on their start dates" />
            <div className="row gx-9 gy-6 mb-9">
                {awards.map((award) => {
                    return (
                        <div key={award.id} className="col-md-6 col-xl-4">
                    <div className="card border h-xl-100 flex-row flex-stack flex-wrap p-6">
                        <div className="d-flex flex-column py-2">
                            <div className="d-flex align-items-center fs-4 fw-bold">
                                {award.name}
                            </div>
                            <div className="fs-6 fw-semibold text-gray-650">Submissions start - {new Date(award.submissionOpeningDate??"").toLocaleDateString()}</div>
                        </div>
                        <div className="d-flex align-items-center py-2">
                            <AwardSettingsButton award={award} awardCatId={awardCat} className="btn btn-sm btn-bg-light btn-color-gray-700 btn-active-light-info">Edit</AwardSettingsButton>
                        </div>
                    </div>
                </div>
                    )
                })}
                
                <div className="col-md-6 col-xl-4">
                    <div className="notice d-flex bg-light-info rounded border-info border h-lg-100 p-6">
                        <div className="d-flex flex-stack flex-grow-1 flex-wrap flex-md-nowrap">
                            <div className="mb-3 mb-md-0 fw-semibold">
                                <h4 className="text-gray-900 fw-bold">New</h4>
                                <div className="fs-6 text-gray-800 pe-7">Create and schedule a new award
                                </div>
                            </div>
                            <button onClick={newAwardHandler} type="button" className="btn btn-info btn-sm btn-icon-light align-self-center text-nowrap">
                                <span className="svg-icon svg-icon-2">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <rect opacity="0.5" x="11.364" y="20.364" width="16" height="2" rx="1" transform="rotate(-90 11.364 20.364)" fill="currentColor"></rect>
                                        <rect x="4.36396" y="11.364" width="16" height="2" rx="1" fill="currentColor"></rect>
                                    </svg>
                                </span>
                                Create
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default UpcomingAwards