import {FC, ReactNode, useEffect} from 'react'

type Props =  {
  children: ReactNode
  id: string,
  innerClassName?: string
}

const ModalContainer:FC<Props> = ({children, id, innerClassName}) => {
  // useEffect(() => {
  //   document.body.classList.add('modal-open')
  //   return () => {
  //     document.body.classList.remove('modal-open')
  //   }
  // }, [])

  return (
    <>
      <div
        className='modal modal-open fade show d-block'
        id={id}
        role='dialog'
        tabIndex={-1}
        aria-modal='true'
      >
        {/* begin::Modal dialog */}
        <div className={'modal-dialog ' + (innerClassName ?? "modal-dialog-centered mw-650px")}>
          {/* begin::Modal content */}
          <div className='modal-content'>
            {/* begin::Modal body */}
            
    {children}
            {/* end::Modal body */}
          </div>
          {/* end::Modal content */}
        </div>
        {/* end::Modal dialog */}
      </div>
      {/* begin::Modal Backdrop */}
      <div className='modal-backdrop fade show'></div>
      {/* end::Modal Backdrop */}
    </>
  )
}

export {ModalContainer}
