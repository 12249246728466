import { FC } from "react"
import { WithChildren } from "../../helpers"

export const ToolbarContainer:FC<WithChildren> = ({children}) =>{
return (
    <div className="toolbar py-5 py-lg-15" id="kt_toolbar">
      <div id="kt_toolbar_container" className="container-xxl d-flex flex-stack flex-wrap">
        {children}
      </div>
      </div>
)
}

