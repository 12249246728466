import {FC, useState } from "react"
import { WithChildren } from "../react18MigrationHelpers"

const PageContainer: FC<WithChildren> = ({children}) => {
	return (
		<div id="kt_content_container" className="d-flex flex-column-fluid align-items-start container-xxl">
			<div className="content flex-row-fluid" id="kt_content">
					{children}
			</div>
		</div>
	)
}

export default PageContainer;