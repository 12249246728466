export type Filter = {
  name?: string,
  title: string,
  type: FilterType,
  options: FilterOption[],
  placeholder?: string
}

export type FilterOption = {
  text: string,
  value?: string | number,
  isSelected: boolean
}

export enum FilterType {
  Checkbox,
  Checkboxes,
  Dropdown,
  Date,
  DateRange
}