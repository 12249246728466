import { AnalyticsDataInfo, AnalyticsDataItemColumnInfo, AnalyticsDataItemInfo, AnalyticsResultInfo } from "@pull/pull-groupm-csp-api";

export function getAnalyticColumnValue(name: string, columns?: AnalyticsDataItemColumnInfo[]) {

    if (!columns)
        return "";

    var result = columns.find(c => c.key == name);

    if (result)
        return result.value;

    return "";
}

export function getAnalyticColumnValueFromPrimaryResult(name: string, item?: AnalyticsDataInfo) {

    if (!item || !item.results || item.results.length == 0)
        return "";

    var result = item.primaryResult?.columns?.find(c => c.key == name);

    if (result)
        return result.value;

    return "";
}


export function getNumberWithCommas(x?: string) {
    return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }