import { AwardInfo, AwardInfoStatus, AwardSubmissionInfo, useAuthContext } from "@pull/pull-groupm-csp-api"
import { FC, ReactNode } from "react"
import { useEditModalContext } from "../../../modules/core/functional/EditModalContext"
import { AwardDownloadSubmissionsModal, AwardEditModal, AwardModalTypes, AwardSubmissionWinnerModal } from "../model/_models"
import { useDownloadSubmissionsModalContext } from "../modals/DownloadSubmissionsModalContext"
import { useAwardSubmissionWinnerModalContext } from "../modals/AwardSubmissionWinnerModalContext"
import { now } from "moment"
import moment from "moment"

type Props = {
    awardSubmission?: AwardSubmissionInfo
    // awardID?: number,
    // awardCategoryID: number,
    // year?: number,
    // fileName: string
    // className: string
    // children: ReactNode
}

const AwardSubmissionWinnerButton: FC<Props> = ({ awardSubmission }) => {

    const { updateSelectedValue } = useAwardSubmissionWinnerModalContext()

    const openAwardSubmissionWinnerModal = () => {
        const modal: AwardSubmissionWinnerModal = {
            awardSubmission
        }
        updateSelectedValue(modal)
    }

    return (
        <div className="min-w-200px d-flex flex-column align-items-end">
            {awardSubmission?.winner ?
                <button onClick={openAwardSubmissionWinnerModal} type="button" className="w-150px winner btn btn-info btn-sm px-4 d-flex align-items-center justify-content-center" data-bs-toggle="modal" data-bs-target="#kt_modal_judging_mark_winner">
                    <span className="svg-icon svg-icon-2">
                        <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                            <path d="M14 18V16H10V18L9 20H15L14 18Z" fill="currentColor"></path>
                            <path opacity="0.3" d="M20 4H17V3C17 2.4 16.6 2 16 2H8C7.4 2 7 2.4 7 3V4H4C3.4 4 3 4.4 3 5V9C3 11.2 4.8 13 7 13C8.2 14.2 8.8 14.8 10 16H14C15.2 14.8 15.8 14.2 17 13C19.2 13 21 11.2 21 9V5C21 4.4 20.6 4 20 4ZM5 9V6H7V11C5.9 11 5 10.1 5 9ZM19 9C19 10.1 18.1 11 17 11V6H19V9ZM17 21V22H7V21C7 20.4 7.4 20 8 20H16C16.6 20 17 20.4 17 21ZM10 9C9.4 9 9 8.6 9 8V5C9 4.4 9.4 4 10 4C10.6 4 11 4.4 11 5V8C11 8.6 10.6 9 10 9ZM10 13C9.4 13 9 12.6 9 12V11C9 10.4 9.4 10 10 10C10.6 10 11 10.4 11 11V12C11 12.6 10.6 13 10 13Z" fill="currentColor"></path>
                        </svg>
                    </span>
                    Winner
                </button>
                : 
                <>
                        {!awardSubmission?.winner && !awardSubmission?.winnerDate ?
                        <button onClick={openAwardSubmissionWinnerModal} type="button" className="w-150px btn btn-bg-light btn-color-gray-700 btn-active-color-info btn-sm px-4 d-flex align-items-center justify-content-center" data-bs-toggle="modal" data-bs-target="#kt_modal_judging_mark_winner">
                            <span className="svg-icon svg-icon-2">
                                <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                    <path d="M14 18V16H10V18L9 20H15L14 18Z" fill="currentColor"></path>
                                    <path opacity="0.3" d="M20 4H17V3C17 2.4 16.6 2 16 2H8C7.4 2 7 2.4 7 3V4H4C3.4 4 3 4.4 3 5V9C3 11.2 4.8 13 7 13C8.2 14.2 8.8 14.8 10 16H14C15.2 14.8 15.8 14.2 17 13C19.2 13 21 11.2 21 9V5C21 4.4 20.6 4 20 4ZM5 9V6H7V11C5.9 11 5 10.1 5 9ZM19 9C19 10.1 18.1 11 17 11V6H19V9ZM17 21V22H7V21C7 20.4 7.4 20 8 20H16C16.6 20 17 20.4 17 21ZM10 9C9.4 9 9 8.6 9 8V5C9 4.4 9.4 4 10 4C10.6 4 11 4.4 11 5V8C11 8.6 10.6 9 10 9ZM10 13C9.4 13 9 12.6 9 12V11C9 10.4 9.4 10 10 10C10.6 10 11 10.4 11 11V12C11 12.6 10.6 13 10 13Z" fill="currentColor"></path>
                                </svg>
                            </span>
                            Mark as winner
                        </button>
                        : <>
                        <button onClick={openAwardSubmissionWinnerModal} type="button" className="w-150px btn btn-light-warning  btn-active-light-warning btn-color-warning btn-sm px-4 d-flex align-items-center justify-content-center" data-bs-toggle="modal" data-bs-target="#kt_modal_judging_mark_winner">
                            <span className="svg-icon svg-icon-2">
                                <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                    <path opacity="0.3" d="M20.9 12.9C20.3 12.9 19.9 12.5 19.9 11.9C19.9 11.3 20.3 10.9 20.9 10.9H21.8C21.3 6.2 17.6 2.4 12.9 2V2.9C12.9 3.5 12.5 3.9 11.9 3.9C11.3 3.9 10.9 3.5 10.9 2.9V2C6.19999 2.5 2.4 6.2 2 10.9H2.89999C3.49999 10.9 3.89999 11.3 3.89999 11.9C3.89999 12.5 3.49999 12.9 2.89999 12.9H2C2.5 17.6 6.19999 21.4 10.9 21.8V20.9C10.9 20.3 11.3 19.9 11.9 19.9C12.5 19.9 12.9 20.3 12.9 20.9V21.8C17.6 21.3 21.4 17.6 21.8 12.9H20.9Z" fill="currentColor"></path>
                                    <path d="M16.9 10.9H13.6C13.4 10.6 13.2 10.4 12.9 10.2V5.90002C12.9 5.30002 12.5 4.90002 11.9 4.90002C11.3 4.90002 10.9 5.30002 10.9 5.90002V10.2C10.6 10.4 10.4 10.6 10.2 10.9H9.89999C9.29999 10.9 8.89999 11.3 8.89999 11.9C8.89999 12.5 9.29999 12.9 9.89999 12.9H10.2C10.4 13.2 10.6 13.4 10.9 13.6V13.9C10.9 14.5 11.3 14.9 11.9 14.9C12.5 14.9 12.9 14.5 12.9 13.9V13.6C13.2 13.4 13.4 13.2 13.6 12.9H16.9C17.5 12.9 17.9 12.5 17.9 11.9C17.9 11.3 17.5 10.9 16.9 10.9Z" fill="currentColor"></path>
                                </svg>
                            </span>
                            Marked to win
                        </button>
                        <span className="mt-2 fs-7 text-warning">({moment(awardSubmission?.winnerDate).format("DD/MM/YYYY - HH:mm")})</span>
                    </>}
                </>}
           
        </div>
    )
}

export { AwardSubmissionWinnerButton }