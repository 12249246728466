import { useAuthContext, UsersApi } from '@pull/pull-groupm-csp-api';
import { FC, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import {useQuery} from 'react-query'
import { QUERIES } from '../../../../Constants/Queries';
import { useEditModalContext } from '../../../../modules/core/functional/EditModalContext';
import {UserEditModalForm} from './UserEditModalForm'

type Props = {
  showModal: boolean
}

const UserEditModalFormWrapper: FC<Props> = ({showModal}) => {
  const modalRoot = document.getElementById("modal-root") as HTMLElement
  const {getApiConfiguration} = useAuthContext()
  const {selectedValue:email} = useEditModalContext()
  const usersApi = new UsersApi(getApiConfiguration())
  
  //const [currentEmail, setCurrentEmail] = useState<string|null>(email)

  const {isLoading,data: user,error,} = useQuery(
    [QUERIES.USER.GET,email],
    () => {
      if(email){
        return usersApi.apiUsersEmailGet(email)
      }
      else {
        return undefined
      }
    },
    {
      cacheTime: 0,onError: (err) => {
        console.error(err)
      },
    })

  if (email?.length === 0 && showModal) {
    return ReactDOM.createPortal(<UserEditModalForm isUserLoading={false} user={{id: undefined}}/>, modalRoot)
  }

  if (!isLoading && !error && user && showModal) {
    return ReactDOM.createPortal(<UserEditModalForm isUserLoading={isLoading} user={user} />,modalRoot)
  }

  return null
}

export {UserEditModalFormWrapper}
