import { CaseStudiesApi, CaseStudyInfo, useAuthContext } from "@pull/pull-groupm-csp-api"
import { FC } from "react"
import { useQuery } from "react-query"
import { Link, useNavigate, useParams } from "react-router-dom"
import { KTCard, KTCardBody } from "../../../_metronic/helpers"
import { Toolbar5 } from "../../../_metronic/layout/components/toolbar/Toolbar5"
import { PageLink, PageTitle } from "../../../_metronic/layout/core"
import { ToolbarContainer } from "../../../_metronic/layout/core/ToolbarContainer"
import { QUERIES } from "../../Constants/Queries"
import PageContainer from "../../helpers/components/listing/PageContainer"
import { LoadingComponent } from "../../modules/core/LoadingComponent"
import CaseStudyDetailSettings from './detail/CaseStudyDetailSettings'

const CaseStudyDetailPage: FC = () => {
    const { id } = useParams()
    const navigate = useNavigate()
    if (!Number(id)) {
        navigate("/404")
    }
    const { getApiConfiguration } = useAuthContext()
    const caseStudyApi = new CaseStudiesApi(getApiConfiguration())
    const { isLoading, data: caseStudy } = useQuery([QUERIES.CASE_STUDIES.GET + id], () => caseStudyApi.apiCaseStudiesIdIdGet(Number(id)), {})

    if (isLoading) {
        return (<LoadingComponent></LoadingComponent>)
    }

    const caseStudyBreadcrumbs: Array<PageLink> = [
        {
            title: '',
            path: '',
            isSeparator: true,
            isActive: false,
        },
        {
            title: 'All Case Studies',
            path: '/case-studies',
            isSeparator: false,
            isActive: true,
        },
        {
            title: '',
            path: '',
            isSeparator: true,
            isActive: false,
        },
        {
            title: caseStudy?.title ?? "",
            path: '',
            isSeparator: false,
            isActive: false
        }

    ]

    return (
        <>
        <ToolbarContainer>
            <PageTitle breadcrumbs={caseStudyBreadcrumbs} title={caseStudy?.title}>
                <div className="mt-6">
                    <Link to="/case-studies" className="btn btn-sm btn-dark fw-bold">
                        <span className="svg-icon svg-icon-2">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect opacity="0.5" x="6" y="11" width="13" height="2" rx="1" fill="currentColor"></rect>
                                <path d="M8.56569 11.4343L12.75 7.25C13.1642 6.83579 13.1642 6.16421 12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75L5.70711 11.2929C5.31658 11.6834 5.31658 12.3166 5.70711 12.7071L11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25C13.1642 17.8358 13.1642 17.1642 12.75 16.75L8.56569 12.5657C8.25327 12.2533 8.25327 11.7467 8.56569 11.4343Z" fill="currentColor"></path>
                            </svg>
                        </span>
                        Back to Case Studies
                    </Link>
                </div>
            </PageTitle>
            </ToolbarContainer>
            <PageContainer>
                {caseStudy &&
                    <>
                        <CaseStudyDetailSettings caseStudy={caseStudy} />
                        <KTCard className="mb-5 mb-xl-10">
                            <div className="card-header border-0">
                                <div className="card-title m-0">
                                    <h3 className="fw-bold m-0">CMS Content (copy that appears on the main site)</h3>
                                </div>
                                <div className="card-toolbar">
                                    <a href={`${window._env_.CSP_URL}/casestudies/${id}/edit`} target="_blank" className="btn btn-dark fw-bold">
                                        <span className="svg-icon svg-icon-2">
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path opacity="0.3" d="M4.7 17.3V7.7C4.7 6.59543 5.59543 5.7 6.7 5.7H9.8C10.2694 5.7 10.65 5.31944 10.65 4.85C10.65 4.38056 10.2694 4 9.8 4H5C3.89543 4 3 4.89543 3 6V19C3 20.1046 3.89543 21 5 21H18C19.1046 21 20 20.1046 20 19V14.2C20 13.7306 19.6194 13.35 19.15 13.35C18.6806 13.35 18.3 13.7306 18.3 14.2V17.3C18.3 18.4046 17.4046 19.3 16.3 19.3H6.7C5.59543 19.3 4.7 18.4046 4.7 17.3Z" fill="currentColor" />
                                                <rect x="21.9497" y="3.46448" width="13" height="2" rx="1" transform="rotate(135 21.9497 3.46448)" fill="currentColor" />
                                                <path d="M19.8284 4.97161L19.8284 9.93937C19.8284 10.5252 20.3033 11 20.8891 11C21.4749 11 21.9497 10.5252 21.9497 9.93937L21.9497 3.05029C21.9497 2.498 21.502 2.05028 20.9497 2.05028L14.0607 2.05027C13.4749 2.05027 13 2.52514 13 3.11094C13 3.69673 13.4749 4.17161 14.0607 4.17161L19.0284 4.17161C19.4702 4.17161 19.8284 4.52978 19.8284 4.97161Z" fill="currentColor" />
                                            </svg>
                                        </span>
                                        Edit Content
                                    </a>
                                </div>
                            </div>
                            <div id="case-study-content">
                                <KTCardBody className="border-top p-9">
                                    <div className="row mb-7">
                                        <label className="col-lg-4 fw-semibold text-gray-650">Case Study type</label>
                                        <div className="col-lg-8">
                                            <span className="fs-6 text-gray-800">{caseStudy.fields?.isInternal ? "Internal" : "External"}</span>
                                        </div>
                                    </div>
                                    <div className="row mb-7">
                                        <label className="col-lg-4 fw-semibold text-gray-650">Advertiser</label>
                                        <div className="col-lg-8">
                                            <span className="fs-6 text-gray-800">{caseStudy.tags?.advertiser?.value}</span>
                                        </div>
                                    </div>
                                    <div className="row mb-7">
                                        <label className="col-lg-4 fw-semibold text-gray-650">Brand</label>
                                        <div className="col-lg-8">
                                            <span className="fs-6 text-gray-800">{caseStudy.tags?.brand?.value}</span>
                                        </div>
                                    </div>
                                    <div className="row mb-7">
                                        <label className="col-lg-4 fw-semibold text-gray-650">Country</label>
                                        <div className="col-lg-8">
                                            <span className="fs-6 text-gray-800">{caseStudy.countries?.map((country) => country.name).join(", ")}</span>
                                        </div>
                                    </div>
                                    <div className="row mb-7">
                                        <label className="col-lg-4 fw-semibold text-gray-650">Implemented</label>
                                        <div className="col-lg-8">
                                            <span className="fs-6 text-gray-800">{caseStudy.fields?.implementationDate ? new Date(caseStudy.fields?.implementationDate).toLocaleDateString() : ""}</span>
                                        </div>
                                    </div>
                                    <div className="row mb-7">
                                        <label className="col-lg-4 fw-semibold text-gray-650">Created by</label>
                                        <div className="col-lg-8">
                                            <span className="fs-6 text-gray-800">{caseStudy.metadata?.createdBy} </span>
                                        </div>
                                    </div>
                                    <div className="row mb-7">
                                        <label className="col-lg-4 fw-semibold text-gray-650">Summary</label>
                                        <div className="col-lg-8">
                                            <div className="fs-6 text-gray-800" dangerouslySetInnerHTML={{ __html: caseStudy.fields?.summary ?? "<p></p>" }}>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row mb-7">
                                        <label className="col-lg-4 fw-semibold text-gray-650">Challenge</label>
                                        <div className="col-lg-8">
                                            <div className="fs-6 text-gray-800" dangerouslySetInnerHTML={{ __html: caseStudy.fields?.challenge ?? "<p></p>" }}>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row mb-7">
                                        <label className="col-lg-4 fw-semibold text-gray-650">Insight</label>
                                        <div className="col-lg-8">
                                            <div className="fs-6 text-gray-800" dangerouslySetInnerHTML={{ __html: caseStudy.fields?.insight ?? "<p></p>" }}>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row mb-7">
                                        <label className="col-lg-4 fw-semibold text-gray-650">Idea</label>
                                        <div className="col-lg-8">
                                            <div className="fs-6 text-gray-800" dangerouslySetInnerHTML={{ __html: caseStudy.fields?.idea ?? "<p></p>" }}>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row mb-7">
                                        <label className="col-lg-4 fw-semibold text-gray-650">Delivery</label>
                                        <div className="col-lg-8">
                                            <div className="fs-6 text-gray-800" dangerouslySetInnerHTML={{ __html: caseStudy.fields?.delivery ?? "<p></p>" }}>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row mb-7">
                                        <label className="col-lg-4 fw-semibold text-gray-650">Results</label>
                                        <div className="col-lg-8">
                                            <div className="fs-6 text-gray-800" dangerouslySetInnerHTML={{ __html: caseStudy.fields?.results ?? "<p></p>" }}>
                                            </div>
                                        </div>
                                    </div>
                                </KTCardBody>
                            </div>
                        </KTCard>
                    </>
                }
            </PageContainer>
        </>
    )
}

export default CaseStudyDetailPage