import { createContext, FC, useContext, useState } from "react";
import { WithChildren } from "../../helpers/components/react18MigrationHelpers"

const LoadingContext = createContext({
  loading: true,
  setLoading: (active: boolean) => {}
});

const LoadingProvider: FC<WithChildren> = ({ children }) => {
  const [loading, setLoading] = useState(false)
  return (
    <LoadingContext.Provider value={{loading, setLoading}}>
        {children}
    </LoadingContext.Provider>
  );
}

function useLoading() {
  const context = useContext(LoadingContext);
  if (!context) {
    throw new Error("useLoading must be used within LoadingProvider");
  }
  return context;
}

export {LoadingProvider, useLoading}