import { PageLink, PageTitle } from '../../../../_metronic/layout/core'
import PageContainer from '../../../helpers/components/listing/PageContainer'
import SearchCol from '../../../helpers/components/listing/searchCard/SearchCol'
import TableCard from '../../../helpers/components/listing/tableCard/TableCard'
import { Filter,FilterType } from '../../../helpers/types/Filter'
import { useAuthContext, NewsApi } from '@pull/pull-groupm-csp-api'
import {QUERIES} from '../../../Constants/Queries'
import { PagedResultInterface } from '../../../modules/core/_models'
import {newsColumns} from '../columns/_columns'
import { TableProvider, useTableContext } from '../../../modules/core/functional/TableContext'
import { Link } from 'react-router-dom'
import { ToolbarContainer } from '../../../../_metronic/layout/core/ToolbarContainer'


const newsBreadcrumbs: Array<PageLink> = [
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
  {
    title: 'News',
    path: '/news',
    isSeparator: false,
    isActive: false,
  },
]

const NewsListPage = () => {
    const {updateFilters} = useTableContext()
   const { getApiConfiguration } = useAuthContext()
  const newsApi = new NewsApi(getApiConfiguration())

  const getNews = async (pageParam = 1, filters= {}) => {
    let response =  await newsApi.apiNewsSearchPost({...filters, ...{includeContent: false,page: pageParam, pageSize:10}} );
    return response as PagedResultInterface;
  }
  const submitSearchHandler = (searchFields: any) => {

    const searchRequestObj = {
      title: searchFields.title,
      onlyPublished: searchFields.onlyPublished ? true: false
    }
    updateFilters(searchRequestObj)
  }

  const newsFilters:Filter[] = [
    {
      title:"Published Only",
      name:"onlyPublished",
      type: FilterType.Checkbox,
      options: [{text:"Show only Published", isSelected:true}]
    }
  ]
  
  return (
    <>
    <ToolbarContainer>
      <PageTitle breadcrumbs={newsBreadcrumbs} title="News"></PageTitle>
      </ToolbarContainer>
      <PageContainer >
      <div className="d-flex flex-column flex-lg-row">
        <SearchCol filters={newsFilters} submitSearch={submitSearchHandler} searchName="title" />
        <TableCard tableCols={newsColumns} getData={getNews} query={QUERIES.NEWS.SEARCH} >
        <Link to="/news/new" className="btn btn-info" >
            <>
              <span className="svg-icon svg-icon-2">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect opacity="0.5" x="11.364" y="20.364" width="16" height="2" rx="1" transform="rotate(-90 11.364 20.364)" fill="currentColor"></rect>
                  <rect x="4.36396" y="11.364" width="16" height="2" rx="1" fill="currentColor"></rect>
                </svg>
              </span>
              New
            </>
          </Link>
        </TableCard> 
        </div>
      </PageContainer>
    </>
  )
}

const NewsListPageWrapper = () => {
 return (
    <TableProvider>
      <NewsListPage />
    </TableProvider>
  )
}

export {NewsListPage, NewsListPageWrapper} 
