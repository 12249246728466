import { FC } from "react"
import ReactDOM from "react-dom";
import { useEditModalContext } from "../../../modules/core/functional/EditModalContext";
import { AwardModalTypes } from "../model/_models";
import { AddSubmissionForm } from "./AddSubmissionForm";
import { AwardSettingsForm } from "./AwardSettingsForm";
import { JudgeScores } from "./JudgeScores";

const AwardModalWrapper: FC = () => {
    const { selectedValue, showEditModal } = useEditModalContext()

    if(!showEditModal)
        return <></>

    const modalRoot = document.getElementById("modal-root") as HTMLElement
    if (selectedValue !== null) {
        switch (selectedValue.type) {
            case AwardModalTypes.JudgeScores:
                return ReactDOM.createPortal(<JudgeScores scores={selectedValue.info.scores} averageScore={selectedValue.info.averageScore} caseStudyName={selectedValue.info.caseStudyName} />, modalRoot)
            case AwardModalTypes.Submission:
                return ReactDOM.createPortal(<AddSubmissionForm  />, modalRoot)
            default:
                return ReactDOM.createPortal(<AwardSettingsForm />, modalRoot)
        }
    }

    return null
}

export { AwardModalWrapper }