import { FC } from "react"

type Props = {
    name:string,
    submittedDate?:Date,

}

const SubmittedByCell: FC<Props> = ({name, submittedDate}) => {
    return (
        <div className="d-flex justify-content-start flex-column">
            <a href="#" className="text-dark text-hover-info fw-bold fs-6">{name}</a>
            {submittedDate && 
                <span className="text-muted fw-semibold text-gray-650 d-block fs-7">{submittedDate.toLocaleDateString()}</span>
            }
        </div>
    )
}

export default SubmittedByCell