
import { PageLink, PageTitle } from '../../../../_metronic/layout/core'
import ListingLayout from '../../../helpers/components/listing/PageContainer'
import TableCard from '../../../helpers/components/listing/tableCard/TableCard'
import { QUERIES } from '../../../Constants/Queries'
import { PagedResultInterface } from '../../../modules/core/_models'
import { emailQueueColumns } from '../columns/_columns'
import { TableProvider, useTableContext } from '../../../modules/core/functional/TableContext'
import { ToolbarContainer } from '../../../../_metronic/layout/core/ToolbarContainer'
import { EmailApi, SearchScheduledEmailRequest, useAuthContext } from '@pull/pull-groupm-csp-api'

const EmailQueuePage = () => {
  const { updatePage, updateFilters } = useTableContext()
  const { getApiConfiguration } = useAuthContext()
  const emailApi = new EmailApi(getApiConfiguration())
  
const breadcrumbs: Array<PageLink> = [
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
  {
    title: 'Email queue',
    path: '/email-queue',
    isSeparator: false,
    isActive: false,
  },
]

  const getData = async (pageParam = 1, filters = {}) => {
    let response = await emailApi.apiEmailsQueuePost({ ...filters, ...{ page: pageParam, pageSize: 10 } } as SearchScheduledEmailRequest)
    debugger;
    return response as PagedResultInterface;
  }

  return (
    <>
    <ToolbarContainer>
      <PageTitle breadcrumbs={breadcrumbs} title={"Email queue"}></PageTitle>
      </ToolbarContainer>
      <ListingLayout >
      <div className="d-flex flex-column flex-lg-row">
        <TableCard tableCols={emailQueueColumns} getData={getData}  query={QUERIES.EMAIL_QUEUE.GET} >
        </TableCard>
        </div>
      </ListingLayout>
    </>
  )
}

const EmailQueuePageWrapper = () => {
  return (
      <TableProvider>
          <EmailQueuePage />
      </TableProvider>
  )
}

export { EmailQueuePage, EmailQueuePageWrapper } 
