import { CountryInfo, RegionInfo, SettingInfo, TagValueInfo } from "@pull/pull-groupm-csp-api"
import { createContext, FC, ReactNode, useCallback, useContext, useEffect, useState } from "react"
import { WithChildren } from "../../../../_metronic/helpers"
import { PageLink } from "../../../../_metronic/layout/core"
import { Filter } from "../../../helpers/types/Filter"


type TableContextProps = {
    currentPage:number,
    updatePage: (page:number) => void,
    currentFilters: any,
    updateFilters:(filters: any) => void
}


const initTableContextPropsState = {
    currentPage:1,
    updatePage:(page:number) => {},
    currentFilters:{},
    updateFilters:(filters:any) => {},
    children:<></>
}

const TableContext = createContext<TableContextProps>(initTableContextPropsState)

const useTableContext = (baseFilters = {}) =>{
    return useContext(TableContext)
}

type ProviderProps ={
    children: ReactNode,
    baseFilter?:any
}

const TableProvider:FC<ProviderProps> = ({children, baseFilter = {}}) =>{
    const [currentFilters, setFilters ] = useState<any>(baseFilter)
    const [currentPage, setPage] = useState(1)

    const updatePage = useCallback((page:number) =>{
        setPage(page)
    },[])
    const updateFilters = useCallback((filters:any) =>{
        setFilters(filters)
        setPage(1)
    },[])

    return ( 
        <TableContext.Provider value={{currentPage,updatePage,currentFilters,updateFilters}} >
            {children}
        </TableContext.Provider>
    )
}
export {TableProvider, useTableContext}