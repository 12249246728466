import { PagesApi, useAuthContext } from "@pull/pull-groupm-csp-api"
import { Link } from "react-router-dom"
import { PageLink, PageTitle } from "../../../../_metronic/layout/core"
import { ToolbarContainer } from "../../../../_metronic/layout/core/ToolbarContainer"
import { QUERIES } from "../../../Constants/Queries"
import PageContainer from "../../../helpers/components/listing/PageContainer"
import SearchCol from "../../../helpers/components/listing/searchCard/SearchCol"
import TableCard from "../../../helpers/components/listing/tableCard/TableCard"
import { Filter, FilterType } from "../../../helpers/types/Filter"
import { TableProvider, useTableContext } from "../../../modules/core/functional/TableContext"
import { PagedResultInterface } from "../../../modules/core/_models"
import { pagesColumns } from "../columns/_columns"

const Breadcrumbs: Array<PageLink> = [
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
    {
      title: 'Pages',
      path: '/pages',
      isSeparator: false,
      isActive: false,
    },
  ]

const ContentPageList = () => {

    const {updateFilters} = useTableContext()
    const { getApiConfiguration } = useAuthContext()
    const pagesApi = new PagesApi(getApiConfiguration())

    const getPages = async (pageParam = 1, filters= {}) => {
    
        let response =  await pagesApi.apiPagesSearchPost({...filters, ...{includeContent: false,page: pageParam, pageSize:10}} );
        return response as PagedResultInterface;
      }
      const submitSearchHandler = (searchFields: any) => {
    
        const searchRequestObj = {
          title: searchFields.title,
          onlyPublished: searchFields.onlyPublished ? true: false
        }
        updateFilters(searchRequestObj)//JSON.parse(searchFields)))
      }
    
      const pagesFilters:Filter[] = [
        {
          title:"Published Only",
          name:"onlyPublished",
          type: FilterType.Checkbox,
          options: [{text:"Show only Published", isSelected:true}]
        }
      ]

    return (
        <>
        <ToolbarContainer>
          <PageTitle breadcrumbs={Breadcrumbs} title="Pages"></PageTitle>
          </ToolbarContainer>
          <PageContainer >
          <div className="d-flex flex-column flex-lg-row">
            <SearchCol filters={pagesFilters} submitSearch={submitSearchHandler} searchName="title" />
            <TableCard tableCols={pagesColumns} getData={getPages} query={QUERIES.PAGES.SEARCH} >
            <Link to="/pages/new" className="btn btn-info" >
                <>
                  <span className="svg-icon svg-icon-2">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <rect opacity="0.5" x="11.364" y="20.364" width="16" height="2" rx="1" transform="rotate(-90 11.364 20.364)" fill="currentColor"></rect>
                      <rect x="4.36396" y="11.364" width="16" height="2" rx="1" fill="currentColor"></rect>
                    </svg>
                  </span>
                  New
                </>
              </Link>
            </TableCard> 
            </div>
          </PageContainer>
        </>
    )
}


const ContentPageListPageWrapper = () => {
  return (
     <TableProvider>
       <ContentPageList />
     </TableProvider>
   )
 }

 export {ContentPageListPageWrapper}