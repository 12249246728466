import { AddUpdateTagValueRequest, TagsApi, TagValueInfo, useAuthContext } from "@pull/pull-groupm-csp-api"
import clsx from "clsx"
import { useFormik } from "formik"
import { FC, useState } from "react"
import { useQueryClient } from "react-query"
import * as Yup from 'yup'
import { QUERIES } from "../../../Constants/Queries"
import { ModalContainer } from "../../../helpers/components/generic/ModalContainer"
import { ErrorBanner } from "../../../helpers/components/generic/NotificationBanner"
import { getErrorFromResponse } from "../../../helpers/functions/getErrorFromResponse"
import { Success } from "../../../helpers/functions/SwalHelpers"
import { useEditModalContext } from "../../../modules/core/functional/EditModalContext"
import { LoadingComponent } from "../../../modules/core/LoadingComponent"
import { useTagContext } from "../../../modules/tag/TagContext"



const editTagValueSchema = Yup.object().shape({
    value: Yup.string()
        .required("The value is required")
})

const TagValueEditModalForm: FC = () =>{
    const { getApiConfiguration } = useAuthContext()
    const { tagParentID} = useTagContext()
    const [error,setError] = useState<string|null>(null)
    const {updateSelectedValue,selectedValue:tagValue}= useEditModalContext()
  const queryClient = useQueryClient()

  const [tagForEdit] = useState<AddUpdateTagValueRequest>({
    id: tagValue?.id|| 0,
    value:tagValue?.value||"",
    enabled:tagValue?.enabled ||false
  })

  const cancel = () =>{
    updateSelectedValue(null)
  }

  const formik = useFormik({
    initialValues: tagForEdit,
    validationSchema: editTagValueSchema,
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true)
      const tagApi = new TagsApi(getApiConfiguration())
      try {
        const body:AddUpdateTagValueRequest = {...values,tagID:tagParentID}
        const response = await tagApi.apiTagsValuePost(body)
        setSubmitting(false)
        if(response?.id){
          queryClient.invalidateQueries([QUERIES.TAG.SEARCH+tagParentID])
        queryClient.invalidateQueries([QUERIES.GLOBAL.INITIAL])
          Success(`The tag was successfully ${tagForEdit.id ===0 ? "created":"updated"}!`)
          cancel()
        }
      } catch (ex) {
        if(ex as Response){
          const errorMessage = await getErrorFromResponse(ex as Response)
          setError(errorMessage)
        }
        else{
          console.log(ex)
        }
      }
    },
  })
  return (

    <ModalContainer id="kt_modal_add_tag_value">
      <div className='modal-body scroll-y mx-5 mx-xl-15 my-7'>
        <form id='kt_modal_add_user_form' className='form' onSubmit={formik.handleSubmit} noValidate>
        {/* begin::Scroll */}
        <div
          className='d-flex flex-column scroll-y me-n7 pe-7'
          id='kt_modal_add_user_scroll'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies='#kt_modal_add_user_header'
          data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
          data-kt-scroll-offset='300px'
        >
          {error && 
          <ErrorBanner message={error} />
          }
            {/* begin::Input group  first Name*/}
          <div className='fv-row mb-7'>
            {/* begin::Label */}
            <label className='required fw-semibold fs-6 mb-2'>Name</label>
            {/* end::Label */}

            {/* begin::Input */}
            <input
              placeholder='Name'
              {...formik.getFieldProps('value')}
              type='text'
              name='value'
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0',
                {'is-invalid': formik.touched.value && formik.errors.value},
                {
                  'is-valid': formik.touched.value && !formik.errors.value,
                }
              )}
              autoComplete='off'
              disabled={formik.isSubmitting}
            />
            {formik.touched.value && formik.errors.value && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.value}</span>
                </div>
              </div>
            )}
            {/* end::Input */}
          </div>
          {/* end::Input group */}
            {/*Input group Enabled */}
          <div className='fv-row mb-7'>
          <label className='required fw-semibold fs-6 mb-2'>Active</label>
            <div className="form-check form-switch form-check-custom form-check-solid">
              <input className="form-check-input h-20px w-30px" 
              {...formik.getFieldProps('enabled')}
                type="checkbox" 
                value=""
                name="enabled"
                checked={formik.values.enabled}
                disabled={formik.isSubmitting} />
              {/* <label className="form-check-label" >
                Enabled
              </label> */}

            </div>
              
          </div>
          {/*end::Input group */}

        </div>

        {/* begin::Actions */}
        <div className='text-center'>
          <button
            type='reset'
            onClick={() => cancel()}
            className='btn btn-light me-3'
            data-kt-users-modal-action='cancel'
            disabled={formik.isSubmitting}
          >
            Discard
          </button>

          <button
            type='submit'
            className='btn btn-primary'
            data-kt-users-modal-action='submit'
            disabled={ formik.isSubmitting || !formik.isValid || !formik.touched}
          >
            <span className='indicator-label'>Submit</span>
            {formik.isSubmitting && (
              <span className='indicator-progress'>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
        {/* end::Actions */}
        </form>
        {formik.isSubmitting && <LoadingComponent/>}
        </div>
    </ModalContainer>
  )
}

export default TagValueEditModalForm