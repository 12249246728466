import { FC } from "react"
import CKEditor from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'

type Props = {
    content?:string
    changeHandler: (copy:string) => void
    className?:string
}

const config = {
    toolbar: [
      'heading',
      '|',
      'bold',
      'italic',
      'link',
      'bulletedList',
      'numberedList',
      'blockQuote',
    ],
  }

const RichTextEditor:FC<Props> = ({content,changeHandler, className}) =>{
    return (
        <CKEditor
        className={className?? ""}
        editor={ClassicEditor}
        config={config}
        data={content ?? ""}
        onInit={(editor:any) => {
         
        }}
        onChange={(event:any, editor:any) => {
          const data = editor.getData()
          changeHandler(data)
          // console.log({ event, editor, data })
        }}
        onBlur={(event:any, editor:any) => {
          // console.log('Blur.', editor)
        }}
        onFocus={(event:any, editor:any) => {
          // console.log('Focus.', editor)
        }}
      />
    )

}

export {RichTextEditor}