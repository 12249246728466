import { FC, useRef, useState } from "react"
import Flatpickr from "react-flatpickr"
import { AnalyticsApi, GetCaseStudiesAnalyticsRequestType, Picture, useAuthContext } from "@pull/pull-groupm-csp-api";
import { useQuery } from "react-query";
import { FlatpickrRange } from "../../../helpers/components/form/FlatpickrRange";
import moment from "moment";
import { Link } from "react-router-dom";

type Props = {
    heading: string,
    startDate: Date,
    endDate: Date,
    mode: CaseStudyStatCardMode,
    className?: string
}

enum CaseStudyStatCardMode {
    Views,
    Downloads,
}


const CaseStudyStatCard: FC<Props> = ({ heading, mode, startDate, endDate, className }) => {

    const { getApiConfiguration } = useAuthContext()
    const analyticsApi = new AnalyticsApi(getApiConfiguration())
    const [fromDate, setFromDate] = useState<Date | undefined>(startDate)
    const [toDate, setToDate] = useState<Date | undefined>(endDate)
    const [fileUrl, setFileUrl] = useState("#")
    const ref = useRef<HTMLAnchorElement | null>(null)


    const { data, isLoading } = useQuery(['casestudyanalytics', fromDate, toDate, mode], getData, { refetchOnWindowFocus: false })

    async function getData() {

        if (!fromDate)
            return []

        return await analyticsApi.apiAnalyticsCaseStudiesPost({
            startDate: fromDate,
            endDate: toDate,
            type: mode == CaseStudyStatCardMode.Views ? GetCaseStudiesAnalyticsRequestType.Viewed : GetCaseStudiesAnalyticsRequestType.Downloaded
        })
    }

    async function dateChange(selectedDates: Array<Date>) {
        setFromDate(selectedDates.length > 0 ? selectedDates[0] : undefined);
        setToDate(selectedDates.length > 1 ? selectedDates[1] : undefined);
    }

    const downloadCSV = async (e: any) => {

        e.preventDefault()

        var data = await analyticsApi.apiAnalyticsCaseStudiesCsvPost({
            startDate: fromDate,
            endDate: toDate,
            type: mode == CaseStudyStatCardMode.Views ? GetCaseStudiesAnalyticsRequestType.Viewed : GetCaseStudiesAnalyticsRequestType.Downloaded
        })?.then((result) => { return result.blob() })

        if (data.size != 0) {
            const url = URL.createObjectURL(new Blob([data]))
            setFileUrl(url);
            setTimeout(() => {
                ref.current?.click();
            }, 300)
        }
    }

    return (
        <>
            <div className="card card-xl-stretch mb-5 mb-xl-8">
                <div className="card-header border-0 pt-8">
                    <h3 className="card-title align-items-start flex-column">
                        <span className="card-label fw-bold fs-2 mb-1">{heading}</span>
                    </h3>
                    <div className="card-toolbar w-250px justify-content-end align-items-stretch">
                        <label htmlFor="kt_most-viewed-range" className="sr-only">Search a date range</label>
                        <FlatpickrRange onChange={dateChange} options={{ mode: "range", enableTime: false, dateFormat: "d/m/y", defaultDate: [startDate, endDate] }} 
                                className="form-control form-control-solid w-175px rounded-0 rounded-start flatpickr-input" buttonClassName="menu btn btn-icon btn-bg-light btn-color-gray-700 btn-active-color-info h-100 rounded-0 rounded-end px-4" />
                    </div>
                </div>
                <div className="card-body pt-3 pb-9 pe-6">

                    <div className="scroll-y pe-5 h-500px">
                        <div className="table-responsive">
                            <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
                                <thead>
                                    <tr className="fw-bold text-gray-650">
                                        <th className="">Name</th>
                                        <th className="">Region/Country</th>
                                        <th className="text-end">{mode == CaseStudyStatCardMode.Downloads ? "Downloads" : "Views"}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data?.map(result => {
                                        return (
                                            <tr>
                                                <td>
                                                    <div className="d-flex align-items-center">
                                                        <div className="symbol symbol-45px me-5">
                                                            <Picture className='object-fit-cover' url={result.caseStudyImage} width={100} alt="Alt description for case study image"></Picture>
                                                        </div>
                                                        <div className="d-flex justify-content-start flex-column">
                                                            <a target="_blank" href={`${window._env_.CSP_URL}/casestudies/${result?.caseStudyID}`} className="text-dark fw-bold text-hover-info fs-6">{result.caseStudyTitle}</a>
                                                            <span className="text-gray-650 fw-semibold d-block fs-7"></span>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <span className="text-dark fw-bold d-block fs-6">{result.caseStudyRegions ? result.caseStudyRegions[0] : ""}</span>
                                                    <span className="text-gray-650 fw-semibold d-block fs-7">{result.caseStudyCountries ? result.caseStudyCountries[0] : ""}</span>
                                                </td>
                                                <td className="text-end">
                                                    <span className="text-gray-650 fw-semibold d-block fs-7">{result.count}</span>
                                                </td>
                                            </tr>
                                        )
                                    })}
                                    {data?.length == 0 ? 
                                        <tr>
                                            <td colSpan={3} className="text-dark fw-bold fs-6" style={{textAlign: "center"}}>
                                                No results found
                                            </td>
                                        </tr>
                                    : ""}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    {data && data.length > 0 ?
                        <div className="card-footer sticky bottom-0 d-flex justify-content-between p-0 pt-8 gap-5">
                            <a onClick={downloadCSV} className="btn btn-sm btn-bg-light btn-color-gray-700 btn-active-color-info">
                                <span className="svg-icon svg-icon-2">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M21 22H3C2.4 22 2 21.6 2 21C2 20.4 2.4 20 3 20H21C21.6 20 22 20.4 22 21C22 21.6 21.6 22 21 22ZM13 13.4V3C13 2.4 12.6 2 12 2C11.4 2 11 2.4 11 3V13.4H13Z" fill="currentColor"></path>
                                        <path opacity="0.3" d="M7 13.4H17L12.7 17.7C12.3 18.1 11.7 18.1 11.3 17.7L7 13.4Z" fill="currentColor"></path>
                                    </svg>
                                </span>
                                Download list
                            </a>
                        </div> : ""}
                </div>
                <a href={fileUrl} download={"case-studies.csv"} className="hidden" ref={ref} ></a>
            </div>
        </>
    )
}

export { CaseStudyStatCard, CaseStudyStatCardMode }