

const Footer = () => {
	const year = new Date().getFullYear()
    return (
        <div className="footer py-4 d-flex flex-lg-column" id="kt_footer">
						<div className="container-xxl d-flex flex-column flex-md-row align-items-center justify-content-between">
							<div className="text-gray-650 order-2 order-md-1">
								<span className="text-gray-650 fw-semibold me-1">{`© ${year} `}</span>
								<a href="https://keenthemes.com" target="_blank" className="text-gray-650 text-hover-info">Mindshare</a>
								<br />
                				v{window._env_.VERSION ?? "4.0.0"}
							</div>
						</div>
					</div>
    )
}

export {Footer}