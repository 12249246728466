// import {useIntl} from 'react-intl'
import { AnalyticsApi, AnalyticsDataItemColumnInfo, AnalyticsDataItemInfo, Timeframe, useAuthContext } from '@pull/pull-groupm-csp-api';
import { useQuery } from 'react-query';
import { PageLink, PageTitle } from '../../../_metronic/layout/core'
import { ToolbarContainer } from '../../../_metronic/layout/core/ToolbarContainer'
import Chart from "react-apexcharts";
import PageContainer from '../../helpers/components/listing/PageContainer'
import cotmIcon from '../../assets/images/graphics/COTM-graphic-2.svg'
import pcaIcon from '../../assets/images/graphics/PCA-graphic.svg'
import { KTUtil } from '../../../_metronic/assets/ts';
import { useState } from 'react';
import { ApexOptions } from 'apexcharts';
import { getAnalyticColumnValue, getAnalyticColumnValueFromPrimaryResult, getNumberWithCommas } from '../../helpers/functions/analyticsHelper';
import { Link } from 'react-router-dom';
import { LoadingComponent } from '../../modules/core/LoadingComponent';

const DashboardWrapper = () => {

  const { getApiConfiguration } = useAuthContext()
  const [graphState, setGraphState] = useState<GraphOptionsProp>();
  const [activities, setActivities] = useState<AnalyticsDataItemInfo[]>();
  const analyticsApi = new AnalyticsApi(getApiConfiguration())

  var labelColor = KTUtil.getCSSVariableValue('--kt-gray-500');
  var borderColor = KTUtil.getCSSVariableValue('--kt-gray-200');
  var baseColor = KTUtil.getCSSVariableValue('--kt-primary');
  var secondaryColor = KTUtil.getCSSVariableValue('--kt-gray-300');

  type GraphOptionsProp = {
    options: ApexOptions,
    series: ApexAxisChartSeries
  };

  function setGraphData(results?: AnalyticsDataItemInfo[]) {

    if (!results || results.length == 0)
      return;

    var regions = results.map(r => getAnalyticColumnValue("Region", r.columns));
    var regionResults = results.map(r => Number(getAnalyticColumnValue("Submissions", r.columns)));

    var state = {
      options: {
        chart: {
          id: "basic-bar",
          height: 10,
          width: "100%",
          toolbar: {
            show: false
          }
        },
        stroke: {
          show: true,
          width: 2,
          colors: ['transparent']
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "30",
            borderRadius: 4
          },
        },
        legend: {
          show: false
        },
        dataLabels: {
          enabled: false
        },
        xaxis: {
          categories: regions,
          axisBorder: {
            show: true,
          },
          axisTicks: {
            show: true
          },
          labels: {
            style: {
              colors: labelColor,
              fontSize: '12px'
            }
          }
        },
        yaxis: {
          labels: {
            style: {
              colors: labelColor,
              fontSize: '12px'
            }
          }
        },
        fill: {
          opacity: 1
        },
        colors: [baseColor, secondaryColor],
        grid: {
          borderColor: borderColor,
          strokeDashArray: 4,
          yaxis: {
            lines: {
              show: true
            }
          }
        },
        states: {
          normal: {
            filter: {
              type: 'none',
              value: 0
            }
          },
          hover: {
            filter: {
              type: 'none',
              value: 0
            }
          },
          active: {
            allowMultipleDataPointsSelection: false,
            filter: {
              type: 'none',
              value: 0
            }
          }
        },
      },
      series: [{
        name: 'Submissions',
        data: regionResults
      }]
    }

    setGraphState(state);
  }

  function getResult(index: number) {
    if (analytics && index < analytics.length) {
      return analytics[index].result;
    }

    return undefined;
  }

  const { isLoading, data: analytics } = useQuery("dashboardAnalytics", async () => {

    var results = await analyticsApi.apiAnalyticsGetPost({
      items: [
        { analyticsName: "CaseStudiesMostViewedSingle", timeframe: Timeframe.AllTime },
        { analyticsName: "CaseStudiesCreated", timeframe: Timeframe.AllTime },
        { analyticsName: "UsersCreated", timeframe: Timeframe.AllTime },
        { analyticsName: "AwardsSubmissionsCurrentSubmissions", timeframe: Timeframe.AllTime, parameters: [5] },
        { analyticsName: "CaseStudiesViews", timeframe: Timeframe.AllTime },
        { analyticsName: "AwardsSubmissionsCurrentSubmissions", timeframe: Timeframe.AllTime, parameters: [54] },
        { analyticsName: "AwardsSubmissionsCurrentSubmissions", timeframe: Timeframe.AllTime, parameters: [5] },
        { analyticsName: "AwardsSubmissionsRegion", timeframe: Timeframe.YearCurrent, parameters: [5, 54] },
        { analyticsName: "Activities", timeframe: Timeframe.AllTime },
      ]
    })

    setGraphData(results[7]?.result?.results)
    setActivities(results[8]?.result?.results)

    return results;
  }, { refetchOnWindowFocus: false });

  return (
    <>
      <ToolbarContainer>
        <PageTitle breadcrumbs={[]} title="Dashboard" hideBreadcrumbs={true}></PageTitle>
      </ToolbarContainer>
      <PageContainer>
        {!isLoading ?
          (
            <>
              <div className="row gy-5 g-xl-10">
                <div className="col-xl-6">
                  <div className="row gx-5 gx-xl-10">
                    <div className="col-sm-6 mb-5 mb-xl-10">
                      <div className="card card-flush h-sm-100">
                        <div className="card-header pt-5">
                          <div className="card-title d-flex flex-column">
                            <span className="fs-2hx fw-bold text-dark me-2 lh-1 ls-n2">{getNumberWithCommas(getAnalyticColumnValueFromPrimaryResult("CaseStudiesCreated", getResult(1)))}</span>
                            <span className="text-gray-650 pt-3 fw-semibold fs-6">Active Case Studies</span>
                          </div>
                        </div>
                        <div className="card-body d-flex flex-column justify-content-between">
                          <span className="fs-6 fw-bolder text-gray-800 d-block mb-2">{getNumberWithCommas(getAnalyticColumnValueFromPrimaryResult("Published", getResult(1)))} added this month</span>
                          <Link to="/case-studies" className="text-info opacity-75-hover fs-6 fw-bold">
                            View case studies
                            <span className="svg-icon svg-icon-3 svg-icon-info">
                            </span>
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6 mb-5 mb-xl-10">
                      <div className="card card-flush h-sm-100">
                        <div className="card-header pt-5">
                          <div className="card-title d-flex flex-column">
                            <span className="fs-2hx fw-bold text-dark me-2 lh-1 ls-n2">{getNumberWithCommas(getAnalyticColumnValueFromPrimaryResult("RegisteredUsers", getResult(2)))}</span>
                            <span className="text-gray-650 pt-3 fw-semibold fs-6">Registered users</span>
                          </div>
                        </div>
                        <div className="card-body d-flex flex-column justify-content-between">
                          <span className="fs-6 fw-bolder text-gray-800 d-block mb-2">{getNumberWithCommas(getAnalyticColumnValueFromPrimaryResult("NewUsers", getResult(2)))} added this month</span>
                          <Link to="/users" className="text-info opacity-75-hover fs-6 fw-bold">
                            View users
                            <span className="svg-icon svg-icon-3 svg-icon-info">
                            </span>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row gx-5 gx-xl-10">
                    <div className="col">
                      <div className="card card-flush mb-xl-10 overflow-hidden">
                        <img alt="Logo" src={cotmIcon} className="w-50 h-100 position-absolute top-0 start-0 object-fit-cover object-fit-right-bottom d-none d-xl-block" />
                        <div className="card-body">
                          <div className="row gx-5 gx-xl-10 justify-content-end">
                            <div className="col-md-12 col-xl-6">
                              <div className="d-flex flex-column ps-xl-9 py-md-0">
                                <span className="fs-2hx fw-bold text-dark me-2 lh-1 ls-n2">{getAnalyticColumnValueFromPrimaryResult("Submissions", getResult(3))}</span>
                                <span className="text-gray-650 pt-3 fw-semibold fs-6">Case studies submitted for current Campaign of the month</span>
                                <div className="pt-5">
                                  <Link to="/awards/5" className="btn btn-dark">View Submissions</Link>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-6 mb-5 mb-xl-10">
                  <div className="card card-flush h-xl-100 overflow-hidden img-hover-grow">
                    <picture>
                      <img className="w-100 h-100 position-absolute top-0 start-0 object-fit-cover img-grow" src={getAnalyticColumnValueFromPrimaryResult("Image", getResult(0))} alt="Alt description for case study image" />
                    </picture>
                    <div className="card-body d-flex align-items-end position-relative mindshare-gradient">
                      <a target="_blank" href={`${window._env_.CSP_URL}/casestudies/${getAnalyticColumnValueFromPrimaryResult("ID", getResult(0))}`} className="cover-link">
                        <div className="d-flex">
                          <div className="pe-3">
                            <span className="svg-icon svg-icon-light svg-icon-2hx">
                              <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 14.13">
                                <path d="M12.95,7.07c0,1.63-1.32,2.95-2.95,2.95s-2.95-1.32-2.95-2.95,1.32-2.95,2.95-2.95,2.95,1.32,2.95,2.95h0Z" fill="currentColor"></path>
                                <path opacity="0.3" d="M0 7.07S3.75 0 10 0s10 7.07 10 7.07-3.75 7.07-10 7.07S0 7.07 0 7.07Zm10 4.5c2.45-.03 4.41-2.05 4.38-4.5.03-2.45-1.93-4.46-4.38-4.5-2.45.03-4.41 2.05-4.38 4.5-.03 2.45 1.93 4.46 4.38 4.49Z" fill="currentColor"></path>
                              </svg>
                            </span>
                          </div>
                          <div className="card-title d-flex flex-column">
                            <span className="fs-2hx fw-bold text-light me-2 lh-1 ls-n2">{getAnalyticColumnValueFromPrimaryResult("Number", getResult(0))}</span>
                          </div>
                        </div>
                        <span className="text-light pt-3 fw-semibold fs-6">Most views this month</span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row gx-5 gx-xl-10">
                <div className="col-lg-3 mb-5 mb-xl-10">
                  <div className="card card-flush h-100">
                    <div className="card-body d-flex">
                      <div className="d-flex flex-column py-md-0 mt-auto">
                        <span className="svg-icon svg-icon-dark svg-icon-3x ms-n1 mb-5">
                          <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 14.13">
                            <path d="M12.95,7.07c0,1.63-1.32,2.95-2.95,2.95s-2.95-1.32-2.95-2.95,1.32-2.95,2.95-2.95,2.95,1.32,2.95,2.95h0Z" fill="currentColor"></path>
                            <path opacity="0.3" d="M0 7.07S3.75 0 10 0s10 7.07 10 7.07-3.75 7.07-10 7.07S0 7.07 0 7.07Zm10 4.5c2.45-.03 4.41-2.05 4.38-4.5.03-2.45-1.93-4.46-4.38-4.5-2.45.03-4.41 2.05-4.38 4.5-.03 2.45 1.93 4.46 4.38 4.49Z" fill="currentColor"></path>
                          </svg>
                        </span>
                        <span className="fs-2hx fw-bold text-dark me-2 lh-1 ls-n2">{getNumberWithCommas(getAnalyticColumnValueFromPrimaryResult("TotalViews", getResult(4)))}</span>
                        <span className="text-gray-650 pt-3 fw-semibold fs-6">Total page views since site has been live</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 mb-5 mb-xl-10">
                  <div className="card card-flush h-100 overflow-hidden">
                    <img alt="Logo" src={pcaIcon} className="w-50 h-100 position-absolute top-0 end-0 object-fit-cover object-fit-left-bottom d-none d-xl-block" />
                    <div className="card-body">
                      <div className="row gx-5 gx-xl-10 justify-content-start">
                        <div className="col-md-12 col-xl-6">
                          <div className="d-flex flex-column pe-xl-9 py-md-0">
                            <span className="fs-2hx fw-bold text-dark me-2 lh-1 ls-n2">{getNumberWithCommas(getAnalyticColumnValueFromPrimaryResult("Submissions", getResult(5)))}</span>
                            <span className="text-gray-650 pt-3 fw-semibold fs-6">Case studies submitted for current Performance Campaign Award</span>
                            <div className="pt-5">
                              <Link to="/awards/54" className="btn btn-dark">View Submissions</Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 mb-5 mb-xl-10">
                  <div className="card card-flush h-100">
                    <div className="card-body d-flex">
                      <div className="d-flex flex-column py-md-0 mt-auto">
                        <span className="svg-icon svg-icon-dark svg-icon-3x ms-n1 mb-5">
                          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path opacity="0.3" fill-rule="evenodd" clip-rule="evenodd" d="M2 4.63158C2 3.1782 3.1782 2 4.63158 2H13.47C14.0155 2 14.278 2.66919 13.8778 3.04006L12.4556 4.35821C11.9009 4.87228 11.1726 5.15789 10.4163 5.15789H7.1579C6.05333 5.15789 5.15789 6.05333 5.15789 7.1579V16.8421C5.15789 17.9467 6.05333 18.8421 7.1579 18.8421H16.8421C17.9467 18.8421 18.8421 17.9467 18.8421 16.8421V13.7518C18.8421 12.927 19.1817 12.1387 19.7809 11.572L20.9878 10.4308C21.3703 10.0691 22 10.3403 22 10.8668V19.3684C22 20.8218 20.8218 22 19.3684 22H4.63158C3.1782 22 2 20.8218 2 19.3684V4.63158Z" fill="currentColor"></path>
                            <path d="M10.9256 11.1882C10.5351 10.7977 10.5351 10.1645 10.9256 9.77397L18.0669 2.6327C18.8479 1.85165 20.1143 1.85165 20.8953 2.6327L21.3665 3.10391C22.1476 3.88496 22.1476 5.15129 21.3665 5.93234L14.2252 13.0736C13.8347 13.4641 13.2016 13.4641 12.811 13.0736L10.9256 11.1882Z" fill="currentColor"></path>
                            <path d="M8.82343 12.0064L8.08852 14.3348C7.8655 15.0414 8.46151 15.7366 9.19388 15.6242L11.8974 15.2092C12.4642 15.1222 12.6916 14.4278 12.2861 14.0223L9.98595 11.7221C9.61452 11.3507 8.98154 11.5055 8.82343 12.0064Z" fill="currentColor"></path>
                          </svg>
                        </span>
                        <span className="fs-2hx fw-bold text-dark me-2 lh-1 ls-n2">{getNumberWithCommas(getAnalyticColumnValueFromPrimaryResult("CaseStudiesCreated", getResult(1)))}</span>
                        <span className="text-gray-650 pt-3 fw-semibold fs-6">Case Studies created in total since site has been live</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row gy-5 g-xl-10">
                <div className="col-xl-8">
                  <div className="card card-xl-stretch">
                    <div className="card-header border-0 pt-8">
                      <h3 className="card-title align-items-start flex-column">
                        <span className="card-label fw-bold fs-2 mb-1">Total Case Study Submissions</span>
                        <span className="text-gray-650 fw-semibold fs-7">By region, current year</span>
                      </h3>
                      <div className="card-toolbar">
                        <Link to="/analytics" className="text-info opacity-75-hover fs-6 fw-bold">View Analytics
                          <span className="svg-icon svg-icon-3 svg-icon-info">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <rect opacity="0.5" x="18" y="13" width="13" height="2" rx="1" transform="rotate(-180 18 13)" fill="currentColor"></rect>
                              <path d="M15.4343 12.5657L11.25 16.75C10.8358 17.1642 10.8358 17.8358 11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25L18.2929 12.7071C18.6834 12.3166 18.6834 11.6834 18.2929 11.2929L12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75C10.8358 6.16421 10.8358 6.83579 11.25 7.25L15.4343 11.4343C15.7467 11.7467 15.7467 12.2533 15.4343 12.5657Z" fill="currentColor"></path>
                            </svg>
                          </span>
                        </Link>
                      </div>
                    </div>
                    <div className="card-body">
                      {graphState && graphState.series.length > 0 ?
                        <Chart
                          options={graphState.options}
                          series={graphState.series}
                          type="bar"
                          height={350}
                        /> : ""}
                    </div>
                  </div>
                </div>
                <div className="col-xl-4">
                  <div className="card card-xl-stretch">
                    <div className="card-header align-items-center border-0 pt-8">
                      <h3 className="card-title align-items-start flex-column">
                        <span className="fw-bold fs-2 mb-2 text-dark">Recent Activity</span>
                      </h3>
                    </div>
                    <div className="card-body pt-5">
                      <div className="timeline-label" style={{maxHeight: "350px", overflow:"scroll"}}>
                        {activities?.map(activity => {
                          return (
                            <div className="timeline-item">
                              <div className="timeline-label fw-bold text-gray-800 fs-6 d-flex flex-column align-items-end pe-5">
                                <span className="badge badge-secondary mb-1">{getAnalyticColumnValue("Time", activity.columns)}</span>
                                <span className="fs-7">{getAnalyticColumnValue("Date", activity.columns)}</span>
                              </div>
                              <div className="timeline-badge">
                                <i className="fa fa-genderless text-warning fs-1"></i>
                              </div>
                              <div className="fw-mormal timeline-content text-gray-800 ps-3">{getAnalyticColumnValue("Title", activity.columns)}</div>
                            </div>
                          )
                        })}

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>) : <LoadingComponent></LoadingComponent>}
      </PageContainer>
    </>
  )
}

export { DashboardWrapper }
