import { AwardInfo, AwardsApi, CaseStudiesApi, CaseStudyInfo, CaseStudyInfoPagedResult, useAuthContext } from "@pull/pull-groupm-csp-api"
import React, { FC, useEffect, useState } from "react";
import { AsyncTypeahead, Typeahead } from "react-bootstrap-typeahead";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { QUERIES } from "../../../Constants/Queries";
import { ModalContainer } from "../../../helpers/components/generic/ModalContainer";
import { ErrorBanner } from "../../../helpers/components/generic/NotificationBanner";
import { getErrorFromResponse } from "../../../helpers/functions/getErrorFromResponse";
import { Success } from "../../../helpers/functions/SwalHelpers";
import { useEditModalContext } from "../../../modules/core/functional/EditModalContext";
import * as Yup from 'yup'
import clsx from "clsx";
import { useFormik } from "formik";
import { getSubmissionTagify } from "../../../helpers/functions/TagifyInstance";

const addSubmissionSchema = Yup.object().shape({
    value: Yup.string()
        .required("The value is required")
})

const AddSubmissionForm: FC = () => {
    const { selectedValue, updateSelectedValue } = useEditModalContext()
    const [error, setError] = useState<string | null>(null)
    const { getApiConfiguration } = useAuthContext()
const queryClient = useQueryClient();

    const formik = useFormik({
        initialValues: { value: "" },
        validationSchema: addSubmissionSchema,
        onSubmit: async (values, { setSubmitting }) => {
            setSubmitting(true)
            console.log(JSON.parse(values.value)[0])
            const awardApi = new AwardsApi(getApiConfiguration())
            try {
                const response = await awardApi.apiAwardsAwardSubmissionsPost({ id: 0, awardID: selectedValue.info.id, caseStudyID: Number(JSON.parse(values.value)[0].value) ?? 0 })
                if (response?.id) {
                    Success("You successfully added a submission")
                    queryClient.invalidateQueries([QUERIES.AWARDS.SEARCH +selectedValue.info.awardCat])
                    updateSelectedValue(null)
                }
            }
            catch (ex) {
                if (ex as Response) {
                    const errorMessage = await getErrorFromResponse(ex as Response)
                    setError(errorMessage)
                }
                else {
                    console.log(ex)
                }
            }
        }
    })
    
    useEffect(() =>{
        const submissionInput = document.getElementById("add-submission")
        getSubmissionTagify(submissionInput,getApiConfiguration(),selectedValue.info.submissions?? [])
    },[])

    return (
        <ModalContainer id={"modal_submit_case_study_form"}>
            <form className="form" id="kt_modal_submissions_add_case_study_form" onSubmit={formik.handleSubmit} noValidate>
                <div className="modal-header" id="kt_modal_submissions_add_case_study_header">
                    <h2>Add a Submission</h2>
                    <button className="btn btn-sm btn-icon btn-active-color-primary" onClick={() => updateSelectedValue(null)}>
                        <span className="svg-icon svg-icon-1">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1" transform="rotate(-45 6 17.3137)" fill="currentColor"></rect>
                                <rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)" fill="currentColor"></rect>
                            </svg>
                        </span>
                    </button>
                </div>
                <div className="modal-body py-10">
                    {error &&
                        <ErrorBanner message={error} />
                    }
                    <div className="d-flex flex-column mb-8 fv-row">
                    <label className='required fw-semibold fs-6 mb-2'>Search for case study</label>
                        <input
                            id="add-submission"
                            className={clsx(
                                'form-control form-control-solid mb-3 mb-lg-0',
                                { 'is-invalid': formik.touched.value && formik.errors.value },
                                {
                                    'is-valid': formik.touched.value && !formik.errors.value,
                                }
                            )}
                            {...formik.getFieldProps('value')}
                            />
                        {/* <label className="required fs-5 fw-semibold mb-2" htmlFor="kt_modal_submissions_add_case_study_name">Case Study Name or ID</label>
                    <input className="form-control form-control-solid" 
                    placeholder="Start typing..." 
                    id="kt_modal_submissions_add_case_study_name" 
                    onChange={handleChange}
                    value={csKeyword} /> */}
                        {formik.touched.value && formik.errors.value && (
                            <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>
                                    <span role='alert'>{formik.errors.value}</span>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                <div className="modal-footer justify-content-end">
                    <div>
                        <button onClick={() => updateSelectedValue(null)} type="button" id="kt_modal_submissions_add_case_study-cancel" data-bs-dismiss="modal" className="btn btn-light btn-color-gray-700 btn-active-color-info me-3">Cancel</button>
                        <button disabled={formik.isSubmitting} type="submit" id="kt_modal_submissions_add_case_study_submit" className="btn btn-info">
                            <span className="indicator-label" >Add submission</span>
                        </button>
                    </div>
                </div>
            </form>
        </ModalContainer>
    )
}
export { AddSubmissionForm }

