import DatePicker from "react-flatpickr";
import Flatpickr from "react-flatpickr";
import { Component, FC, ReactElement, useRef, useState } from "react";
import flatpickr from "flatpickr";
import React from "react";
import moment from "moment";
import { FlatpickrRangeItem } from "./FlatpickrRangeItem";


type Props = {
    name?: string,
    placeholder?: string,
    options?: flatpickr.Options.Options | undefined
    className?: string,
    buttonClassName:string,
    onChange?: (event: Array<Date>) => Promise<void>;
}


const FlatpickrRange: FC<Props> = ({ name, placeholder, options, onChange, className, buttonClassName }) => {

    const fp = useRef<Flatpickr | null>(null);
    // const [range, setRange] = useState();

    async function selectedRange(startDate:Date, endDate:Date){
        fp.current?.flatpickr.setDate([startDate, endDate], true)
    }

    async function showPicker(){
        fp.current?.flatpickr.open()
    }

    return (
        <>
            <Flatpickr ref={fp} name={name} placeholder={placeholder} onChange={(e) => { if (onChange) onChange(e) }} options={options} className={"form-control form-control-solid " + className} />
            <button type="button" className={buttonClassName} data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end">
                <span className="svg-icon svg-icon-2">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-list" viewBox="0 0 16 16">
                        <path fill-rule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5" />
                    </svg>
                </span>
                <div className="menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-info fw-semibold w-200px py-3" data-kt-menu="true">
                    <FlatpickrRangeItem name="Today" startDate={moment().startOf("day").toDate()} endDate={moment().endOf("day").toDate()} onChange={selectedRange}></FlatpickrRangeItem>
                    <FlatpickrRangeItem name="Yesterday" startDate={moment().subtract(1, "day").startOf("day").toDate()} endDate={moment().subtract(1, "day").endOf("day").toDate()} onChange={selectedRange}></FlatpickrRangeItem>
                    <FlatpickrRangeItem name="Last 7 days" startDate={moment().startOf("day").subtract(7, "day").toDate()} endDate={moment().endOf("day").toDate()} onChange={selectedRange}></FlatpickrRangeItem>
                    <FlatpickrRangeItem name="Last 30 days" startDate={moment().startOf("day").subtract(30, "day").toDate()} endDate={moment().endOf("day").toDate()} onChange={selectedRange}></FlatpickrRangeItem>
                    <FlatpickrRangeItem name="This month" startDate={moment().startOf("month").toDate()} endDate={moment().endOf("month").toDate()} onChange={selectedRange}></FlatpickrRangeItem>
                    <FlatpickrRangeItem name="Last month" startDate={moment().subtract(1, "month").startOf("month").toDate()} endDate={moment().subtract(1, "month").endOf("month").toDate()} onChange={selectedRange}></FlatpickrRangeItem>
                    <div className="menu-item px-3 my-1">
                        <a onClick={showPicker} className="menu-link px-3">Custom</a>
                    </div>
                </div>
            </button>

        </>
    )

}



export { FlatpickrRange }