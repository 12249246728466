
import { createContext, FC, ReactNode, useCallback, useContext, useEffect, useState } from "react"

type AwardSubmissionWinnerModalContextProps = {
    selectedValue: any|null,
    updateSelectedValue: (value:any|null) => void,
    showAwardSubmissionWinnerModal: boolean
}


const initModalContextPropsState = {
    selectedValue:null,
    updateSelectedValue:(value:any|null) => {},
    showAwardSubmissionWinnerModal:false,
    children:<></>
}

const AwardSubmissionWinnerModalContext = createContext<AwardSubmissionWinnerModalContextProps>(initModalContextPropsState)

const useAwardSubmissionWinnerModalContext = () =>{
    return useContext(AwardSubmissionWinnerModalContext)
}

type ProviderProps ={
    children: ReactNode
}

const AwardSubmissionWinnerModalProvider:FC<ProviderProps> = ({children}) =>{
    const [selectedValue, setValue] = useState<any>(null)
    const [showAwardSubmissionWinnerModal, setShowAwardSubmissionWinnerModal] = useState(false)

    const updateSelectedValue = useCallback((value:any) =>{
        setValue(value)
    },[])

    useEffect(()=>{
        setShowAwardSubmissionWinnerModal(selectedValue !== null)
    },[selectedValue])

    return ( 
        <AwardSubmissionWinnerModalContext.Provider value={{selectedValue,updateSelectedValue, showAwardSubmissionWinnerModal}} >
            {children}
        </AwardSubmissionWinnerModalContext.Provider>
    )
}
export {AwardSubmissionWinnerModalProvider,useAwardSubmissionWinnerModalContext}