
import { FC } from "react"
import { PageLink, PageTitle } from "../../../../_metronic/layout/core"
import { ToolbarContainer } from "../../../../_metronic/layout/core/ToolbarContainer"
import PageContainer from "../../../helpers/components/listing/PageContainer"
import { Filter, FilterOption, FilterType } from "../../../helpers/types/Filter"
import SearchCol from "../../../helpers/components/listing/searchCard/SearchCol"
import TableCard from "../../../helpers/components/listing/tableCard/TableCard"
import { QUERIES } from "../../../Constants/Queries"
import { useQuery } from "react-query"
import { AwardsApi, useAuthContext } from "@pull/pull-groupm-csp-api"
import { TableProvider, useTableContext } from "../../../modules/core/functional/TableContext"
import { getDateRange } from "../../../helpers/functions/dateHelper"
import { PagedResultInterface } from "../../../modules/core/_models"
import { judgeActivityColumns } from "../columns/_columns"

const breadcrumbs: Array<PageLink> = [
    {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
    },
    {
        title: 'Judge activity',
        path: '/awards/judge-activity',
        isSeparator: false,
        isActive: false,
    },
]

const JudgeActivityListPage: FC = () => {

    const { updateFilters } = useTableContext()
    const { getApiConfiguration } = useAuthContext()
    const awardsApi = new AwardsApi(getApiConfiguration())
    const { isLoading, data: awardCategories } = useQuery([QUERIES.AWARDS.GET_ALL_CATEGORIES], () => awardsApi.apiAwardsAwardCategoriesGet())

    const searchHandler = (searchFields: any) => {

        var dateRange = getDateRange(searchFields.date);

        const searchRequestObj = {
            nameOrEmail: searchFields.name,
            selectedAwardCategories: !searchFields?.award || Array.isArray(searchFields?.award) ? searchFields.award : [searchFields.award],
            enabledOnly: searchFields.onlyEnabled ? true : false,
            dateFrom: dateRange ? dateRange.startDate : null,
            dateTo: dateRange ? dateRange.endDate : null,
            order: searchFields.sort
        }
        updateFilters(searchRequestObj)
    }


    const getJudgeActivity = async (pageParam = 1, filters = {}) => {
        let response = await awardsApi.apiAwardsJudgesPost({ ...filters, ...{ page: pageParam, pageSize: 10 } });
        return response as PagedResultInterface;
    } 
    
    const downloadJudgeActivity = async (filters = {}) => {
        return await awardsApi.apiAwardsJudgesCsvPost(filters)?.then((result) => { return result.blob() })
    }

    const judgeActivityFilters: Filter[] = [
        {
            title: "Sort",
            name: "sort",
            type: FilterType.Dropdown,
            options: [{ text: "Contributions (desc)", value: "ContributionsDesc", isSelected: true },
                      { text: "Contributions (asc)", value: "ContributionsAsc", isSelected: true },
                      { text: "Judge Name", value: "Name", isSelected: true }],
        },
        {
            title: "Date",
            name: "date",
            type: FilterType.DateRange,
            options: [],
            placeholder: ""
        },
        {
            title: "Award",
            name: "award",
            type: FilterType.Checkboxes,
            options: awardCategories?.map(ac => { return { text: ac.shortName, value: ac.id, isSelected: false } as FilterOption }) ?? []
        },
        {
            title: "Enabled Only",
            name: "onlyEnabled",
            type: FilterType.Checkbox,
            options: [{ text: "Show only enabled", isSelected: true }]
        },
    ]


    return (
        <>
            <ToolbarContainer>
                <PageTitle breadcrumbs={breadcrumbs} title="Judge activity"></PageTitle>
            </ToolbarContainer>
            <PageContainer>
                <div className="d-flex flex-column flex-lg-row">
                    <SearchCol filters={judgeActivityFilters} searchPlaceholder="Search name or email" submitSearch={searchHandler} searchName="name" />
                    <TableCard tableCols={judgeActivityColumns} getData={getJudgeActivity} query={QUERIES.AWARDS.JUDGES_SEARCH} downloadTitle="Download list" downloadData={downloadJudgeActivity} downloadName="judge-activity.csv">
                    </TableCard>
                </div>
            </PageContainer>
        </>
    )
}


const JudgeActivityListPageWrapper = () => {
    return (
        <TableProvider>
            <JudgeActivityListPage />
        </TableProvider>
    )
}

export default JudgeActivityListPageWrapper