import { FC, useState } from 'react'
import { Filter, FilterType } from '../../../types/Filter';
import Checkbox from './FilterCheckbox';
import { FlatpickrRange } from '../../form/FlatpickrRange';
import Flatpickr from "react-flatpickr";

export type FilterModel = {
    filter: Filter,
    index: number
}

const FilterInput: FC<FilterModel> = ({ filter, index }) => {
    const [filterValues, setFilterValues] = useState(filter.options);

    const handleFilterChange = (event: { target: { value: string; }; }) => {
        let tmpOptions = filterValues;
        tmpOptions.forEach((opt) => {
            opt.isSelected = event.target.value === opt.value;
        })
        setFilterValues(tmpOptions);
    }

    return (
        <>
            {filter.type == FilterType.Checkbox ?
                <Checkbox name={filter.name} checkbox={filterValues[0]} /> : ""}
            {filter.type == FilterType.DateRange || filter.type == FilterType.Date ?
                <div className={"mb-10"} >
                    <label className="fs-6 form-label fw-bold text-dark" htmlFor={filter.title.toLowerCase()}>{filter.title}</label>
                    {filter.type == FilterType.DateRange ?
                    <div className="d-flex justify-content-end align-items-center">
                        <FlatpickrRange name={filter.title.toLowerCase()} placeholder={filter.placeholder} options={{ mode: "range", enableTime: false }} className='form-control form-control-solid' 
                                buttonClassName="btn btn-icon btn-bg-light btn-color-gray-700 btn-active-color-info rounded-0 rounded-end px-4" ></FlatpickrRange>
                    </div> : ""
                    }
                    {filter.type == FilterType.Date ?
                        <Flatpickr name={filter.title.toLowerCase()} placeholder={filter.placeholder} options={{ mode: "single", enableTime: false}} className='form-control form-control-solid' ></Flatpickr> : ""
                    }
                </div> : ""}
            {filter.type == FilterType.Dropdown ?
                <div className={"mb-10"} >
                    <label className="fs-6 form-label fw-bold text-dark">{filter.title}</label>
                    <select onChange={handleFilterChange} name={filter.title.toLowerCase()} defaultValue={filterValues.find(o => o.isSelected)?.value} className="form-select form-select-solid " data-placeholder="Any" data-hide-search="true" tabIndex={-1} aria-hidden="true" data-kt-initialized="1">
                        {filterValues.map((option) => {
                            return (
                                <option key={`${filter.title}-${option.value}`} value={option.value}>{option.text}</option>
                            )
                        })}
                    </select>
                </div> : ""}
            {filter.type == FilterType.Checkboxes ?
                <div className={"mb-10"} >
                    <label className="fs-6 form-label fw-bold text-dark">{filter.title}</label>
                    <div>
                        {filterValues.map((option, index) => {
                            return (
                                <div className="form-check form-check-custom form-check-solid mb-5 me-5">
                                    <input onChange={handleFilterChange} className="form-check-input" name={filter.name} type="checkbox" value={option.value} id={"f_" + index} />
                                    <label className="form-check-label" htmlFor={"f_" + index}>
                                        {option.text}
                                    </label>
                                </div>
                            )
                        })}
                    </div>
                </div> : ""}
        </>

    )
}

export default FilterInput