import { FC } from "react";
import { Column } from "react-table";
import { useTableContext } from "../../../../modules/core/functional/TableContext";
import { LoadingComponent } from "../../../../modules/core/LoadingComponent";
import { PagedResultInterface } from "../../../../modules/core/_models";
import TableComponent from "./TableComponent";

type Prop = {
    data: any[]
    tableCols: ReadonlyArray<Column<any>>
    pageSize: number
    showLoading: boolean
    totalCount: number
}

const TableBody: FC<Prop> = ({ data, tableCols, showLoading, pageSize, totalCount }) => {
    const { updatePage, currentPage } = useTableContext()

    let amountShowing = (currentPage ?? 1) * (pageSize );
    const noMoreData = amountShowing >= (totalCount ?? Infinity);
    if (noMoreData)
        amountShowing = totalCount ?? amountShowing;
    return (
        <>
            <div className="table-responsive">
                <TableComponent cols={tableCols} data={data} />
            </div>
            {
                (showLoading) &&
                <LoadingComponent />
            }
            <div className="d-flex flex-stack flex-wrap py-5">
                {totalCount ?
                    <div className="fs-6 fw-semibold text-gray-650">Showing 1 to {amountShowing} of {totalCount}</div>
                    : null
                }
                {!noMoreData &&
                    <a onClick={() => { updatePage(currentPage + 1) }} className="btn btn-light btn-sm">Load more results</a>
                }
            </div>
        </>
    )
}
export default TableBody