import { useAuthContext, NewsApi, NewsInfo } from '@pull/pull-groupm-csp-api'
import { FC, useEffect } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { Link } from 'react-router-dom'
import { MenuComponent } from '../../../../_metronic/assets/ts/components'
import { QUERIES } from '../../../Constants/Queries'
import { Delete } from '../../../helpers/functions/SwalHelpers'

type Props = {
  item: NewsInfo
}

const NewsActionCell: FC<Props> = ({ item }) => {
  const { getApiConfiguration } = useAuthContext();

  const apiConfig = getApiConfiguration();
  const newsApi = new NewsApi(apiConfig);

  const queryClient = useQueryClient();

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  const deleteOnClick = () => {
    Delete("news article")
      .then(async (result) => {
        if (result.isConfirmed) {
          await deleteItem.mutateAsync()
        }
      })
  }


  const deleteItem = useMutation(() => newsApi.apiNewsIdDelete(item.id ?? 0), {
    // 💡 response of the mutation is passed to onSuccess
    onSuccess: () => {
      // ✅ update detail view directly
      queryClient.invalidateQueries([QUERIES.NEWS.SEARCH])
    },
  })

  return (
    <div className="text-end">
      <Link to={`/news/${item.id}`} className="btn btn-bg-light btn-color-gray-700 btn-active-color-info btn-sm px-4 me-2">Details</Link>
      <button type="button"
        className="btn btn-bg-light btn-color-gray-700 btn-active-color-danger btn-sm px-4"
        data-delete-news-item=""
        onClick={deleteOnClick}>Delete</button>
    </div>
  )
}

export default NewsActionCell